import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-remark-view",
  templateUrl: "./remark-view.component.html",
  styleUrls: ["./remark-view.component.css"],
})
export class RemarkViewComponent implements OnInit {
  text: string = "";

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.text = this.data.text;
  }
}
