import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { GuideService } from "../guide.service";

@Component({
  selector: "app-guide-page",
  templateUrl: "./guide-page.component.html",
  styleUrls: ["./guide-page.component.css"],
})
export class GuidePageComponent implements OnInit {
  @ViewChild("newLink", { static: false }) link: any;
  @ViewChild("newDesc", { static: false }) desc: any;

  links: any;

  constructor(
    private guideService: GuideService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.getLinks();
  }

  insertNewLink() {
    let url = this.link.nativeElement.value;
    let desc = this.desc.nativeElement.value;

    this.guideService.insertLink(url, desc);
  }

  getLinks() {
    this.guideService.getLinks().subscribe((list) => {
      this.links = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  delete(key) {
    if (confirm("Da li ste sigurni da želite obrisati link?"))
      this.guideService.deleteLink(key);
  }
}
