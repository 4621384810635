import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalPubInputService } from "src/app/musical-notes/musical-notes-inputs/input-services/musical-pub-input.service";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";
import { PrintViewConfigurationComponent } from "src/app/musical-notes/print/print-view-configuration/print-view-configuration.component";
import { PrintBookConfigurationComponent } from "src/app/musical-notes/views/print-book-configuration/print-book-configuration.component";
import { SharedClass } from "src/app/shared/shared-functions";
import { MusicalPubReportComponent } from "../../reports/musical-pub-report/musical-pub-report.component";

@Component({
  selector: "app-musical-pub-view",
  templateUrl: "./musical-pub-view.component.html",
  styleUrls: ["./musical-pub-view.component.css"],
})
export class MusicalPubViewComponent implements OnInit {
  user: firebase.User;
  userObject: User;
  schoolName: string = "";
  allRentsFromDB: any[] = [];

  musicalPubRents: any[] = [];
  filteredMusicalPubRents: any = [];
  musicalPubs: any[] = [];

  constructor(
    private auth: AuthService,
    public musicalNotesService: MusicalNotesService,
    public musicalInputService: MusicalPubInputService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.allRentsFromDB = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.getUsers();
  }

  loadAllRents() {
    if (this.userObject) {
      return this.musicalNotesService
        .getMusicalPubRents(this.schoolName)
        .subscribe((list) => {
          this.allRentsFromDB = list.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val(),
            };
          });
          this.loadMusicalPubs();
          this.getMusicalPubs();
          this.loadTable();
        });
    }
  }
  /* TABLE */
  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = [
    "Korisnik",
    "Email",
    "Naziv",
    "Date",
    "Time",
    "Status",
    "Options",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  loadTable() {
    this.listData = new MatTableDataSource(this.filteredMusicalPubRents);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;
  }

  /* GETS DATA FOR SPECIFIC MUSICAL NOTE */

  rentKeys: any = [];
  getMusicalPubs() {
    let index = 0;

    this.rentKeys = [];
    this.musicalPubRents = [];
    // console.log(this.allRentsFromDB);

    //Iteration over every object on the same level of data structure
    for (const key in this.allRentsFromDB) {
      let element: {
        mPElement: any;
        index: number;
        returnedCounter: number;
      } = {
        mPElement: this.allRentsFromDB[key],
        index: index++,
        returnedCounter: 0,
      };

      //COUNTER FOR RETURNED RENTS
      for (const invkey in element.mPElement.MusicalPub.invStatusi) {
        if (
          element.mPElement.MusicalPub.invStatusi[invkey].statusi === "Vraćeno"
        )
          element.returnedCounter++;
      }

      this.musicalPubRents.push(element);
      this.rentKeys.push(this.allRentsFromDB[key].$key);
    }

    //FILTERING DATA IF COMPONENT ACCESSED FROM PROFILE
    if (this.musicalNotesService.fromProfile) {
      this.musicalPubRents = this.musicalPubRents.filter(
        (el) => el.mPElement.User.email === this.userObject.email
      );

      //ADDING NEW INDEX FOR PERSONAL RENTS
      for (let i = 0; i < this.musicalPubRents.length; i++)
        this.musicalPubRents[i].index = i;
    }

    this.filteredMusicalPubRents = this.musicalPubRents;
    this.setTotalSerialPubs();
  }

  /* USERS FUNCTIONS */
  getUsers() {
    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          if (!this.schoolName) {
            this.schoolName = this.userObject.School.Naziv;
            this.musicalNotesService.fromProfile = true;
          }
          this.loadAllRents();
        });
      }
    });
  }

  onOpenMusicalPubReport(index) {
    this.dialog.open(MusicalPubReportComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        selectedMusicalPub: this.musicalPubRents[index].mPElement,
        musicalPubKey: this.musicalPubRents[index].mPElement.musicalPubKey,
        rentKey: this.rentKeys[index],
        fromProfile: this.musicalNotesService.fromProfile,
      },
    });
  }

  /* SEARCHING ALGORITHM */

  applyFilter() {
    this.filteredMusicalPubRents = this.musicalPubsFiltered();
    this.listData.data = this.filteredMusicalPubRents;
    this.setTotalSerialPubs();
  }

  musicalPubsFiltered() {
    return this.musicalPubRents.filter(
      (sP) =>
        sP.mPElement.TimeOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.DateOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.email
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.firstname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.lastname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.MusicalPub.Naslov.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1
    );
  }

  refresh() {
    this.searchKey = "";
    this.filteredMusicalPubRents = this.musicalPubRents;
    this.listData.data = this.filteredMusicalPubRents;
  }

  /* STATISTICS */

  totalSerialPubs: number = 0;
  rentedSerialPubs: number = 0;

  setTotalSerialPubs() {
    this.totalSerialPubs = 0;
    this.rentedSerialPubs = 0;

    this.filteredMusicalPubRents.forEach((element) => {
      this.totalSerialPubs += element.mPElement.ListaRentovanja.length;

      if (Array.isArray(element.mPElement.MusicalPub.invStatusi))
        this.rentedSerialPubs += element.mPElement.MusicalPub.invStatusi.filter(
          (lele) => lele.statusi === "Vraćeno"
        ).length;
      else {
        if (element.mPElement.MusicalPub.invStatusi.statusi === "Vraćeno") {
          this.rentedSerialPubs++;
        }
      }
    });
  }

  onOpenSerialPubReportRouter() {
    this.router.navigate(["/izvestaji", this.schoolName]);
  }
  onOpenMonoPubReport() {
    this.router.navigate(["/monografskePublikacijeIzvestaji", this.schoolName]);
  }
  onOpenMusicalPubReportView() {
    this.router.navigate(["/muzikalijeIzvestaji", this.schoolName]);
  }

  onOpenSoundPubReport() {
    this.router.navigate(["/zvucnaGradjaIzvestaji", this.schoolName]);
  }

  onOpenPrintConfiguration() {
    this.dialog.open(PrintViewConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        category: "muzikalije",
        reports: this.allRentsFromDB,
      },
    });
  }
  onOpenPrintForBook() {
    this.dialog.open(PrintBookConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        columns: this.columns,
      },
    });
  }

  loadMusicalPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.musicalInputService.getMusicalPubs(schoolName).subscribe((list) => {
      this.musicalPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  checkedCounter() {
    return this.columns.filter((item) => item.checked);
  }

  //HARD-CODED DATA

  columns: any = [
    { name: "Datum", checked: true },
    {
      name: "Autor, Naslov, Mesto izdavanja, Izdavač, Godina izdavanja",
      checked: true,
    },
    { name: "Povez", checked: true },
    { name: "Dimenzije", checked: true },
    { name: "Način nabavke", checked: true },
    { name: "Cena", checked: true },
    { name: "Signatura", checked: true },
    { name: "Napomena", checked: true },
    { name: "Podnaslov", checked: false },
    { name: "Vrsta dokumenta", checked: false },
    { name: "Sadržaj", checked: false },
    { name: "Godište sveske", checked: false },
    { name: "Prateći dokument", checked: false },
    { name: "UDK", checked: false },
    { name: "ISBN", checked: false },
    { name: "ISMN", checked: false },
    { name: "Link publikacije Cobiss", checked: false },
  ];
}
