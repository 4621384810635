import { Component, OnInit, ViewChild } from "@angular/core";
import { MusicalNotesService } from "../musical-notes.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PrintConfigurationComponent } from "../print/print-configuration/print-configuration.component";
import { MatDialog } from "@angular/material";
import { MonoPubInputService } from "../musical-notes-inputs/input-services/mono-pub-input.service";
import { MusicalPubInputService } from "../musical-notes-inputs/input-services/musical-pub-input.service";
import { SerialPubInputService } from "../musical-notes-inputs/input-services/serial-pub-input.service";
import { SharedClass } from "src/app/shared/shared-functions";
import { ThrowStmt } from "@angular/compiler";
import { ElementRef } from "@angular/core";
@Component({
  selector: "app-musical-notes-list",
  templateUrl: "./musical-notes-list.component.html",
  styleUrls: ["./musical-notes-list.component.css"],
})
export class MusicalNotesListComponent implements OnInit {
  serialPubs: any[] = [];
  monoPubs: any[] = [];
  musicalPubs: any[] = [];
  soundPubs: any[] = [];

  allPubs: any[] = [];
  authors: any = [];
  titles: any = [];
  publishers: any = [];
  participants: any = [];
  acessedFrom: string = "Sve";

  constructor(
    private musicalNotesService: MusicalNotesService,
    private serialPubService: SerialPubInputService,
    private monoPubService: MonoPubInputService,
    private musicalInputService: MusicalPubInputService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.loadPubs();
  }

  loadPubs() {
    this.loadSerialPubs();
    this.loadMonoPubs();
    this.loadMusicalPubs();
    this.loadSoundPubs();
  }

  loadDataForAdvancedSearch() {
    this.loadAuthors();
    this.loadTitles();
    this.loadPublishers();
    this.loadParticipants();
  }

  /* LOADING DATA FROM FIREBASE */

  loadAuthors() {
    this.musicalNotesService.getNotesAuthors().subscribe((list) => {
      this.authors = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.authors = this.authors.map((item) => item.Naziv);
    });
  }
  loadTitles() {
    this.musicalNotesService.getTitles().subscribe((list) => {
      this.titles = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.titles = this.titles.map((item) => item.Naziv);
    });
  }
  loadPublishers() {
    this.musicalNotesService.getPublishers().subscribe((list) => {
      this.publishers = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.publishers = this.publishers.map((item) => item.Naziv);
    });
  }

  loadParticipants() {
    this.musicalNotesService.getParticipants().subscribe((list) => {
      this.participants = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.participants = this.participants.map((item) => item.Naziv);
    });
  }

  loadSerialPubs(school?: string) {
    let schoolName = "";
    if (!school) schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    else schoolName = school;

    this.serialPubService.getSerialPubs(schoolName).subscribe((list) => {
      this.serialPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });

      this.filteredSerialPubs = this.serialPubs;
    });
  }

  loadMonoPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.monoPubService.getMonoPubs(schoolName).subscribe((list) => {
      this.monoPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.filteredMonoPubs = this.monoPubs;
    });
  }

  loadMusicalPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.musicalInputService.getMusicalPubs(schoolName).subscribe((list) => {
      this.musicalPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.filteredMusicalPubs = this.musicalPubs;
    });
  }

  loadSoundPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.musicalNotesService.getSoundPubs(schoolName).subscribe((list) => {
      this.soundPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.filteredSoundPubs = this.soundPubs;
    });
  }

  /* SEARCHING FUNCTIONS */
  @ViewChild("searchInputMusical", { static: true }) searchMusicalPub: any;
  @ViewChild("searchSoundPub", { static: true }) searchSoundPub: any;
  @ViewChild("allSearchInput", { static: true }) searchAll: any;

  filteredSerialPubs: any[] = [];
  filteredMonoPubs: any[] = [];
  filteredMusicalPubs: any[] = [];
  filteredSoundPubs: any[] = [];

  //SEARCH BY INPUTED PROPERTIES
  @ViewChild("Autor", { static: false }) inputAutor: any;
  @ViewChild("Naslov", { static: false }) inputNaslov: any;
  @ViewChild("SubTitle", { static: false }) inputPodnaslov: any;
  @ViewChild("Izdavac", { static: false }) inputIzdavac: any;
  @ViewChild("godinaIzdavanja", { static: false }) inputGodinaIzdavanja: any;
  @ViewChild("AuthorTranscription", { static: false }) inputAuthorTrans: any;
  @ViewChild("TitleTranscription", { static: false }) inputNaslovTrans: any;
  @ViewChild("Ucesnici", { static: false }) inputUcesnik: any;
  @ViewChild("UcesniciTranscription", { static: false }) inputUcesnikTrans: any;
  @ViewChild("SubTitleTrans", { static: false }) inputPodnaslovTrans: any;
  @ViewChild("Sadrzaj", { static: false }) inputSadrzaj: any;

  searchByProperties() {
    let searchProps = {
      inputAuthor: this.inputAutor.input.nativeElement.value.toLowerCase(),
      inputNaslov: this.inputNaslov.input.nativeElement.value.toLowerCase(),
      inputIzdavac: this.inputIzdavac.input.nativeElement.value.toLowerCase(),
      inputGodinaIzdavanja: this.inputGodinaIzdavanja.input.nativeElement.value.toLowerCase(),
      inputPodnaslov: this.inputPodnaslov.nativeElement.value.toLowerCase(),
      inputAuthorTrans: this.inputAuthorTrans.nativeElement.value.toLowerCase(),
      inputNaslovTrans: this.inputNaslovTrans.nativeElement.value.toLowerCase(),
      inputUcesnik: this.inputUcesnik.input.nativeElement.value.toLowerCase(),
      inputUcesnikTrans: this.inputUcesnikTrans.nativeElement.value.toLowerCase(),
      inputPodnaslovTrans: this.inputPodnaslovTrans.nativeElement.value.toLowerCase(),
      inputSadrzaj: this.inputSadrzaj.nativeElement.value.toLowerCase(),
    };
    this.serialPubListView.searchByProp(searchProps);

    this.monoPubListView.searchByProp(searchProps);

    this.musicalListView.searchByProp(searchProps);

    this.soundListView.searchByProp(searchProps);
  }

  resetSearch() {
    this.inputAutor.input.nativeElement.value = "";
    this.inputNaslov.input.nativeElement.value = "";
    this.inputIzdavac.input.nativeElement.value = "";
    this.inputGodinaIzdavanja.input.nativeElement.value = "";
    this.inputPodnaslov.nativeElement.value = "";
    this.loadPubs();
  }
  //CALLING SEARCH FUNCTIONS TO CHILD COMPONENTS
  serialPubListView: any;
  monoPubListView: any;
  musicalListView: any;
  soundListView: any;
  //prettier-ignore
  @ViewChild("SerialPubList", { static: false }) set contentSerial(content: ElementRef) {
    if(content)
    this.serialPubListView = content;
  }
  //prettier-ignore
  @ViewChild("MonoPubList", { static: false }) set contentMono(content: ElementRef) {
    if(content)
    this.monoPubListView = content;
  }
  //prettier-ignore
  @ViewChild("MusicalPubList", { static: false }) set contentMusical(content: ElementRef) {
    if(content)
    this.musicalListView = content;
  }
  //prettier-ignore
  @ViewChild("SoundPubList", { static: false }) set soundMusical(content: ElementRef) {
    if(content)
    this.soundListView = content;
  }

  searchSerialPubs() {
    let searchInput = this.searchAll.nativeElement.value.toLowerCase();
    this.serialPubListView.searchSerialPubs(searchInput);
  }

  searchMonoPubs() {
    let searchInput = this.searchAll.nativeElement.value.toLowerCase();
    this.monoPubListView.searchMonoPubs(searchInput);
  }

  searchMusicalPubs() {
    let searchInput = this.searchAll.nativeElement.value.toLowerCase();
    this.musicalListView.searchMusicalPubs(searchInput);
  }

  searchSoundPubs() {
    let searchInput = this.searchAll.nativeElement.value.toLowerCase();
    this.soundListView.searchSoundPubs(searchInput);
  }

  dataForPrint: any = [];
  onOpenPrintConfiguration() {
    this.dataForPrint = [];
    this.dataForPrint = this.dataForPrint.concat(
      this.serialPubListView.filteredSerialPubs
    );
    this.dataForPrint = this.dataForPrint.concat(
      this.monoPubListView.filteredMonoPubs
    );
    this.dataForPrint = this.dataForPrint.concat(
      this.musicalListView.filteredMusicalPubs
    );
    this.dataForPrint = this.dataForPrint.concat(
      this.soundListView.filteredSoundPubs
    );
    this.dialog.open(PrintConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "300px",
      maxHeight: "100vh",
      data: {
        filteredPubs: this.dataForPrint,
      },
    });
  }

  tableViewShow = false;

  //prettier-ignore
  onChangeView() {
    this.tableViewShow = !this.tableViewShow;
  }
  checkedChanged(event, name, array) {
    //the state of 4 categories changing
    for (let i = 0; i < array.length; i++) {
      if (array[i].name == name) {
        array[i].selected = !array[i].selected;
      }
    }

    if (name == "Sve") {
      for (let i = 0; i < array.length; i++) {
        if (!event.checked) array[i].selected = false;
        else array[i].selected = true;
      }
      if (event.checked) {
        this.loadPubs();
      } else {
        this.filteredSerialPubs = [];
        this.filteredMonoPubs = [];
        this.filteredMusicalPubs = [];
        this.filteredSoundPubs = [];
      }
    }

    //Checking if all elements in the array are true or false to change the condition on "Sve" checbkox
    let fourElement = array.slice(0, -1);
    array[4].selected = !fourElement.some((x) => !x.selected);

    //RENDERING THE DATA BY THE SELECTED CATEGORY
    if (!event.checked) {
      if (name == "Serijske publikacije") {
        this.filteredSerialPubs = [];
      }
      if (name == "Monografske publikacije") {
        this.filteredMonoPubs = [];
      }
      if (name == "Muzikalije") {
        this.filteredMusicalPubs = [];
      }
      if (name == "Zvučna građa") {
        this.filteredSoundPubs = [];
      }
    } else {
      if (name == "Serijske publikacije") this.loadSerialPubs();
      if (name == "Monografske publikacije") this.loadMonoPubs();
      if (name == "Muzikalije") this.loadMusicalPubs();
      if (name == "Zvučna građa") this.loadSoundPubs();
    }
  }
  selectedIndex: number = 0;
  changeAcessFrom(event) {
    //SHOWING THE TABLE COMPONENT WHICH TAB IS SELECTED IN ORDER TO SHOW THAT DATA IN TABLE
    this.acessedFrom = event.tab.textLabel;
    this.selectedIndex = event.index;
  }
  changeTheTab(tab) {
    this.selectedIndex = tab;
  }
}
