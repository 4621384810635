import { ThrowStmt } from "@angular/compiler";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { MusicalNotesService } from "../../musical-notes.service";

@Component({
  selector: "app-print-view-configuration",
  templateUrl: "./print-view-configuration.component.html",
  styleUrls: ["./print-view-configuration.component.css"],
})
export class PrintViewConfigurationComponent implements OnInit {
  selectedCategory: string = "";

  printProperties: { name: string; selected: boolean }[] = [
    { name: "Korisnik", selected: true },
    { name: "Email", selected: true },
    { name: "Naslov", selected: true },
    { name: "Datum izdavanja", selected: true },
    { name: "Vreme izdavanja", selected: true },
  ];

  constructor(
    private musicalNotesService: MusicalNotesService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedCategory = this.data.category;
  }

  checkedChanged(event, name) {
    for (let i = 0; i < this.printProperties.length; i++) {
      if (event.checked) {
        if (this.printProperties[i].name === name) {
          this.printProperties[i].selected = true;
        }
      } else {
        if (this.printProperties[i].name === name) {
          this.printProperties[i].selected = false;
        }
      }
    }
  }

  onOpenPrintConfiguration() {
    this.musicalNotesService.dataForPubViewPrint = this.data.reports;
    this.musicalNotesService.selectedPropsForPubView = this.printProperties;
    this.musicalNotesService.categoryForPrint = this.selectedCategory;

    this.router.navigate(["/stampaIzvestaja"]);
    this.dialog.closeAll();
  }
}
