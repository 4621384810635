import { NgModule } from "@angular/core";
import { environment } from "./../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AngularFireStorage } from "@angular/fire/storage";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxPrintModule } from "ngx-print";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { LoginComponent } from "./auth/login/login.component";
import { RegistrationComponent } from "./auth/registration/registration.component";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { UserProfileComponent } from "./auth/user-profile/user-profile.component";
import { UserListComponent } from "./user/user-list/user-list.component";
import { UserDialogComponent } from "./user/user-dialog/user-dialog.component";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { MusicalNotesInputsComponent } from "./musical-notes/musical-notes-inputs/musical-notes-inputs.component";
import { SerialPubInputComponent } from "./musical-notes/musical-notes-inputs/serial-pub-input/serial-pub-input.component";
import { MonoPubInputComponent } from "./musical-notes/musical-notes-inputs/mono-pub-input/mono-pub-input.component";
import { MusicalsInputComponent } from "./musical-notes/musical-notes-inputs/musicals-input/musicals-input.component";
import { SoundMaterialInputComponent } from "./musical-notes/musical-notes-inputs/sound-material-input/sound-material-input.component";
import { MusicalNotesListComponent } from "./musical-notes/musical-notes-list/musical-notes-list.component";
import { SerialPubItemComponent } from "./musical-notes/musical-notes-list/pub-items/serial-pub-item/serial-pub-item.component";
import { SerialPubRentComponent } from "./musical-notes/musical-rents/serial-pub-rent/serial-pub-rent.component";
import { SerialPubViewComponent } from "./musical-notes/musical-rents/views/serial-pub-view/serial-pub-view.component";
import { SerialPubReportComponent } from "./musical-notes/musical-rents/reports/serial-pub-report/serial-pub-report.component";
import { MonoPubItemComponent } from "./musical-notes/musical-notes-list/pub-items/mono-pub-item/mono-pub-item.component";
import { MonoPubRentComponent } from "./musical-notes/musical-rents/mono-pub-rent/mono-pub-rent.component";
import { MonoPubViewComponent } from "./musical-notes/musical-rents/views/mono-pub-view/mono-pub-view.component";
import { MonoPubReportComponent } from "./musical-notes/musical-rents/reports/mono-pub-report/mono-pub-report.component";
import { MusicalPubItemComponent } from "./musical-notes/musical-notes-list/pub-items/musical-pub-item/musical-pub-item.component";
import { MusicalPubRentComponent } from "./musical-notes/musical-rents/musical-pub-rent/musical-pub-rent.component";
import { MusicalPubReportComponent } from "./musical-notes/musical-rents/reports/musical-pub-report/musical-pub-report.component";
import { MusicalPubViewComponent } from "./musical-notes/musical-rents/views/musical-pub-view/musical-pub-view.component";
import { SoundPubItemComponent } from "./musical-notes/musical-notes-list/pub-items/sound-pub-item/sound-pub-item.component";
import { SoundPubRentComponent } from "./musical-notes/musical-rents/sound-pub-rent/sound-pub-rent.component";
import { SoundPubViewComponent } from "./musical-notes/musical-rents/views/sound-pub-view/sound-pub-view.component";
import { SoundPubReportComponent } from "./musical-notes/musical-rents/reports/sound-pub-report/sound-pub-report.component";
import { PrintConfigurationComponent } from "./musical-notes/print/print-configuration/print-configuration.component";
import { MatTreeModule } from "@angular/material/tree";
import { PrintPubsComponent } from "./musical-notes/print/print-pubs/print-pubs.component";
import { PrintViewConfigurationComponent } from "./musical-notes/print/print-view-configuration/print-view-configuration.component";
import { PrintViewsComponent } from "./musical-notes/print/print-views/print-views.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { GuidePageComponent } from "./guide-page/guide-page.component";
import { RemarkViewComponent } from "./musical-notes/musical-notes-list/remark-view/remark-view.component";
import { AutocompleteComponent } from "./shared/autocomplete/autocomplete.component";
import { PrintBookConfigurationComponent } from "./musical-notes/views/print-book-configuration/print-book-configuration.component";
import { TabDirective } from "./shared/autocomplete/tab-directive";
import { RoutesModule } from "./routes/routes.module";
import { QRCodeModule } from "angularx-qrcode";
import { AutocompleteWithoutTabComponent } from "./shared/autocomplete-without-tab/autocomplete-without-tab.component";
import { SerialPubListComponent } from './musical-notes/musical-notes-list/pub-list/serial-pub-list/serial-pub-list.component';
import { MonoPubListComponent } from './musical-notes/musical-notes-list/pub-list/mono-pub-list/mono-pub-list.component';
import { MusicalPubListComponent } from './musical-notes/musical-notes-list/pub-list/musical-pub-list/musical-pub-list.component';
import { SoundPubListComponent } from './musical-notes/musical-notes-list/pub-list/sound-pub-list/sound-pub-list.component';
import { TableViewComponent } from './musical-notes/musical-notes-list/table-view/table-view.component';
// import { ReservationComponent } from './reservation/reservation.component';
// import { MessageComponent } from './reservation/message/message.component';
// import { PersonalMessagesComponent } from './reservation/personal-messages/personal-messages.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    LoginComponent,
    RegistrationComponent,
    UserProfileComponent,
    UserListComponent,
    UserDialogComponent,
    MusicalNotesInputsComponent,
    SerialPubInputComponent,
    TabDirective,
    MonoPubInputComponent,
    MusicalsInputComponent,
    SoundMaterialInputComponent,
    MusicalNotesListComponent,
    SerialPubItemComponent,
    SerialPubRentComponent,
    SerialPubViewComponent,
    SerialPubReportComponent,
    MonoPubItemComponent,
    MonoPubRentComponent,
    MonoPubViewComponent,
    MonoPubReportComponent,
    MusicalPubItemComponent,
    MusicalPubRentComponent,
    MusicalPubReportComponent,
    MusicalPubViewComponent,
    SoundPubItemComponent,
    SoundPubRentComponent,
    SoundPubViewComponent,
    SoundPubReportComponent,
    PrintConfigurationComponent,
    PrintPubsComponent,
    PrintViewConfigurationComponent,
    PrintViewsComponent,
    HomePageComponent,
    GuidePageComponent,
    RemarkViewComponent,
    AutocompleteComponent,
    PrintBookConfigurationComponent,
    AutocompleteWithoutTabComponent,
    SerialPubListComponent,
    MonoPubListComponent,
    MusicalPubListComponent,
    SoundPubListComponent,
    TableViewComponent,
    // ReservationComponent,
    // MessageComponent,
    // PersonalMessagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    RoutesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDropzoneModule,
    NgxPrintModule,
    ScrollToModule.forRoot(),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatTreeModule,
    QRCodeModule,
  ],
  providers: [AngularFireStorage],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginComponent,
    RegistrationComponent,
    UserDialogComponent,
    SerialPubInputComponent,
    SerialPubRentComponent,
    SerialPubReportComponent,
    MonoPubInputComponent,
    MonoPubRentComponent,
    MonoPubReportComponent,
    MusicalsInputComponent,
    MusicalPubRentComponent,
    MusicalPubReportComponent,
    SoundMaterialInputComponent,
    SoundPubRentComponent,
    SoundPubReportComponent,
    PrintConfigurationComponent,
    PrintViewConfigurationComponent,
    RemarkViewComponent,
    PrintBookConfigurationComponent,
    // MessageComponent,
  ],
})
export class AppModule {}
