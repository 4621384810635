<form class="example-form">
  <mat-form-field class="example-full-width" style="width: 100% !important">
    <input
      type="text"
      placeholder="{{ placeHolder }}"
      aria-label="Number"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
      value="{{ editValue }}"
      #Value
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
