<mat-card class="mat-elevation-z6" class="box" fxFlexAlign="stretch">
  <mat-card-header>
    <mat-card-title title="{{ musicalPubElement?.Naslov }}"
      >{{ musicalPubElement?.Naslov }}
    </mat-card-title>
  </mat-card-header>
  <ul class="image-qr">
    <li class="crop" (click)="onOpenEditDialog(musicalPubElement.$key)">
      <div style="text-align: center; display: grid">
        <div>
          <img
            *ngIf="musicalPubElement.imagesURLs"
            src="{{ (musicalPubElement?.imagesURLs)[0] }}"
            alt="Nota"
          />
        </div>
      </div>
    </li>
    <li class="cropQR">
      <div>
        <!-- prettier-ignore -->
        <qrcode
          [qrdata]="'Muzikalije \nNaslov - ' + musicalPubElement?.Naslov + '\n'
          +'Autor - ' + musicalPubElement?.Autor + '\n'
          +'Podnaslov - ' + musicalPubElement?.Podnaslov + '\n'
          +'Izdavač - ' + musicalPubElement?.Izdavac + '\n'
          +'Godina Izdavanja - ' + musicalPubElement?.GodinaIzdavanja + '\n'
          +'Polica - ' + musicalPubElement?.Polica + '\n'
          || 'Prazno'"
          [width]="120"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </li>
  </ul>
  <mat-card-content>
    <p>
      Datum inventarisanja:
      <b>
        {{ musicalPubElement?.Datum.toString().split(" ")[2] }}.
        {{ musicalPubElement?.Datum.split(" ")[1] }}.
        {{ musicalPubElement?.Datum.split(" ")[3] }}.
      </b>
    </p>
    <p>
      Autor: <b>{{ musicalPubElement?.Autor }}</b>
    </p>
    <p>
      Podnaslov: <b>{{ musicalPubElement?.Podnaslov }}</b>
    </p>
    <p>
      Mesto izdavanja: <b> {{ musicalPubElement?.Mesto }}</b>
    </p>

    <p>
      Izdavač: <b> {{ musicalPubElement?.Izdavac }}</b>
    </p>
    <p>
      Godište sveske: <b>{{ musicalPubElement?.GodisteSveske }}</b>
    </p>
    <p>
      Godina izdavanja: <b> {{ musicalPubElement?.GodinaIzdavanja }}</b>
    </p>
    <p>
      Godište sveske: <b>{{ musicalPubElement?.GodisteSveske }}</b>
    </p>
    <p>
      Dimenzije: <b>{{ musicalPubElement?.Dimenzije }}</b>
    </p>
    <p>
      Povez: <b>{{ musicalPubElement?.Povez }}</b>
    </p>
    <p>
      Način nabavke: <b>{{ musicalPubElement?.NacinNabavke }}</b>
    </p>
    <p>
      Vrsta dokumenta: <b>{{ musicalPubElement?.VrstaDokumenta }}</b>
    </p>
    <p>
      Prateći dokument: <b>{{ musicalPubElement?.PrateciDokument }}</b>
    </p>
    <p>
      Cena: <b>{{ musicalPubElement?.Cena }}</b> RSD
    </p>

    <p>
      UDK: <b> {{ musicalPubElement?.UDK }}</b>
    </p>
    <p>
      ISBN: <b>{{ musicalPubElement?.ISBN }}</b>
    </p>
    <p>
      ISMN: <b>{{ musicalPubElement?.ISMN }}</b>
    </p>
    <p>
      Signatura: <b>{{ musicalPubElement?.Signatura }}</b>
    </p>
    <p>
      Polica: <b>{{ musicalPubElement?.Polica }}</b>
    </p>
    <p>
      Link Cobiss:
      <a href="{{ musicalPubElement?.LinkPublikacije }}" target="_blank"
        >Otvori link</a
      >
    </p>
    <p>
      Sadržaj: {{ musicalPubElement?.Sadrzaj.toString().substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(musicalPubElement?.Sadrzaj)"
        *ngIf="musicalPubElement.Sadrzaj.toString().length > 20"
        >... [više]
      </b>
    </p>
    <p>
      {{ musicalPubElement?.Napomena.substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(musicalPubElement?.Napomena)"
        *ngIf="musicalPubElement.Napomena.length > 20"
        >... [više]
      </b>
    </p>
    <p style="font-size: larger">
      Broj svezaka: <b>{{ musicalPubElement?.invStatusi.length }}</b>
    </p>
  </mat-card-content>
  <mat-card-actions style="text-align: center">
    <button
      *ngIf="
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
      mat-button
      (click)="onOpenEditDialog(musicalPubElement.$key)"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-button (click)="onOpenMusicalPubbRent(musicalPubElement.$key)">
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
