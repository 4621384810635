import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { MusicalNotesService } from "../../musical-notes.service";
import { SharedClass } from "src/app/shared/shared-functions";
import { FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SerialPubInputService } from "../input-services/serial-pub-input.service";
import { MonoPubInputService } from "../input-services/mono-pub-input.service";
import { MusicalPubInputService } from "../input-services/musical-pub-input.service";
import { SoundPubInputService } from "../input-services/sound-pub-input.service";

@Component({
  selector: "app-serial-pub-input",
  templateUrl: "./serial-pub-input.component.html",
  styleUrls: ["./serial-pub-input.component.css"],
})
export class SerialPubInputComponent implements OnInit {
  authors: any = [];
  places: any = [];
  purchaseTypes: any = [];
  udks: any = [];
  titles: any = [];
  publishers: any = [];
  bindings: any = [];
  participants: any = [];

  serialPubElement: any = {};

  selectedStatus = "Izdato";
  schoolName: string = "";
  myForm: FormGroup;
  files: any;

  formStructure() {
    this.myForm = new FormGroup({
      Datum: new FormControl(new Date()),
      Naslov: new FormControl(""),
      NaslovTranskripcija: new FormControl(""),
      Podnaslov: new FormControl(""),
      PodnaslovTranskripcija: new FormControl(""),
      Autor: new FormControl(""),
      AutorTranskripcija: new FormControl(""),
      Ucesnici: new FormControl(""),
      UcesniciTranskripcija: new FormControl(""),
      Mesto: new FormControl(""),
      Polica: new FormControl(""),
      UDK: new FormControl(""),
      GodinaIzdavanja: new FormControl(""),
      GodisteSveske: new FormControl(""),
      Dimenzije: new FormControl(""),
      Izdavac: new FormControl(""),
      Povez: new FormControl("Povezano"),
      NacinNabavke: new FormControl("Kupovina"),
      PrateciDokument: new FormControl(""),
      Cena: new FormControl(""),
      ISBN: new FormControl(""),
      ISMN: new FormControl(""),
      Signatura: new FormControl(""),
      Sadrzaj: new FormControl(""),
      Napomena: new FormControl(""),
      LinkPublikacije: new FormControl(""),
      invStatusi: this.fb.array([]),
      imagesURLs: new FormControl(""),
    });
  }

  constructor(
    private musicalNotesService: MusicalNotesService,
    private serialPubService: SerialPubInputService,
    private monoPubService: MonoPubInputService,
    private musicalPubService: MusicalPubInputService,
    private soundPubService: SoundPubInputService,
    public sharedClass: SharedClass,
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.sharedClass.oneImagedownloadUrl = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.formStructure();
    this.loadSerialPubForEdit();
    this.loadSerialPubs();
    this.loadAuthors();
    this.loadTitles();
    this.loadPlaces();
    this.loadPurchaseTypes();
    this.loadPublishers();
    this.loadUdks();
    this.loadBindings();
    this.loadParticipants();
    this.invStatusi.clear();
  }

  patchValues() {
    this.myForm.patchValue({
      Datum: new Date(this.serialPubElement.Datum),
      Naslov: this.serialPubElement.Naslov,
      NaslovTranskripcija: this.serialPubElement.NaslovTranskripcija,
      Podnaslov: this.serialPubElement.Podnaslov,
      PodnaslovTranskripcija: this.serialPubElement.PodnaslovTranskripcija,
      Autor: this.serialPubElement.Autor,
      AutorTranskripcija: this.serialPubElement.AutorTranskripcija,
      Ucesnici: this.serialPubElement.Ucesnici,
      UcesniciTranskripcija: this.serialPubElement.UcesniciTranskripcija,
      Mesto: this.serialPubElement.Mesto,
      Polica: this.serialPubElement.Polica,
      UDK: this.serialPubElement.UDK,
      GodinaIzdavanja: this.serialPubElement.GodinaIzdavanja,
      GodisteSveske: this.serialPubElement.GodisteSveske,
      Dimenzije: this.serialPubElement.Dimenzije,
      Izdavac: this.serialPubElement.Izdavac,
      NacinNabavke: this.serialPubElement.NacinNabavke,
      PrateciDokument: this.serialPubElement.PrateciDokument,
      Cena: this.serialPubElement.Cena,
      ISBN: this.serialPubElement.ISBN,
      ISMN: this.serialPubElement.ISMN,
      Signatura: this.serialPubElement.Signatura,
      Sadrzaj: this.serialPubElement.Sadrzaj,
      Napomena: this.serialPubElement.Napomena,
      LinkPublikacije: this.serialPubElement.LinkPublikacije,
      imagesURLs: this.serialPubElement.imagesURLs,
      invStatusi: this.serialPubElement.invStatusi,
      Povez: this.serialPubElement.Povez,
    });

    this.serialPubElement.invStatusi.forEach((element) => {
      const invStatusi = this.fb.group({
        statusi: element.statusi,
        invBrojevi: element.invBrojevi,
      });
      this.invStatusi.push(invStatusi);
    });

    if (this.serialPubElement.imagesURLs)
      this.sharedClass.oneImagedownloadUrl = this.serialPubElement.imagesURLs;
  }

  onSubmit(form: FormGroup) {
    try {
      if (form.valid) {
        form = this.makeAModel(form);
        form.value.imagesURLs = this.sharedClass.oneImagedownloadUrl;
        //functions which checks if autocomplete values already exists, and if don't it will insert it
        /* CHECKING IF THE DATA IS FROM EDIT FORM OR CREATE FORM */
        if (!this.data)
          this.serialPubService.insertSerialPub(this.schoolName, form.value);
        else {
          this.serialPubService.updateSerialPub(
            this.schoolName,
            this.data.key,
            form.value
          );
          this.closeDialog();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      //RESETING VALUES FROM INPUT
      this.resetForm(this.myForm);
      this.resetAutoCompletes();
      this.once = true;
      this.counter = 0;
      this.sharedClass.progressValue = 0;
      this.sharedClass.imageFiles = [];
      this.sharedClass.downloadURLs = [];
      this.sharedClass.fileCounter = 0;
      this.sharedClass.oneImagedownloadUrl = [];
      this.ngOnInit();
    }
  }

  /* LOADING DATA FROM FIREBASE */
  loadAuthors() {
    this.musicalNotesService.getNotesAuthors().subscribe((list) => {
      this.authors = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.authors = this.authors.map((item) => item.Naziv);
    });
  }

  loadPlaces() {
    this.musicalNotesService.getPlaces().subscribe((list) => {
      this.places = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.places = this.places.map((item) => item.Naziv);
    });
  }

  loadPurchaseTypes() {
    this.musicalNotesService.getPurchaseTypes().subscribe((list) => {
      this.purchaseTypes = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.purchaseTypes = this.purchaseTypes.map((item) => item.Naziv);
    });
  }

  loadUdks() {
    this.musicalNotesService.getUdks().subscribe((list) => {
      this.udks = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.udks = this.udks.map((item) => item.Naziv);
    });
  }
  loadTitles() {
    this.musicalNotesService.getTitles().subscribe((list) => {
      this.titles = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.titles = this.titles.map((item) => item.Naziv);
    });
  }

  loadPublishers() {
    this.musicalNotesService.getPublishers().subscribe((list) => {
      this.publishers = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.publishers = this.publishers.map((item) => item.Naziv);
    });
  }

  loadBindings() {
    this.musicalNotesService.getBindings().subscribe((list) => {
      this.bindings = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.bindings = this.bindings.map((item) => item.Naziv);
    });
  }

  loadParticipants() {
    this.musicalNotesService.getParticipants().subscribe((list) => {
      this.participants = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.participants = this.participants.map((item) => item.Naziv);
    });
  }

  loadSerialPubForEdit() {
    //CHECKING IF THE KEY FOR EDITING WAS PROVIDED OR NOT
    if (this.data) {
      this.serialPubService
        .getSerialPubElement(this.data.SchoolName, this.data.key)
        .subscribe((item) => {
          this.serialPubElement = item;
          this.patchValues();
        });

      this.authService.userObject
        .valueChanges()
        .subscribe((item) => (this.schoolName = item.School.Naziv));
    }
  }
  /* UPLOADING IMAGE TO FIREBASE STORAGE */

  uploadImage(event) {
    this.sharedClass.uploadImage(event, "serialPub");
  }
  onDeletePhoto(url) {
    this.sharedClass.deletePhotoFromFirebaseStorage(url);
  }

  /* OTHER FUNCTIONS */

  mergeWithFollowingDoc() {
    this.inputPrateci.nativeElement.value =
      this.inputNacinNabavke.input.nativeElement.value + ", ";
  }

  serialPubs: any[] = [];

  once = true;

  /* LOADING DATA FROM FIREBASE */
  loadSerialPubs() {
    if (!this.schoolName) this.schoolName = this.data.SchoolName;

    this.serialPubService.getSerialPubs(this.schoolName).subscribe((list) => {
      this.serialPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.invPubsCounter();
      if (this.once && !this.data) {
        this.addNewInvStatusi();
        this.once = false;
      }
    });
  }
  get invStatusi() {
    return this.myForm.get("invStatusi") as FormArray;
  }
  counter: number = 0;
  invPubsCounter() {
    this.serialPubs.forEach((element) => {
      this.counter += element.invStatusi.length;
    });
  }

  //MAKING THE NEW ID OF EVERY NEW INVENTAR NUMBER UNIQUE
  addNewInvStatusi() {
    const invStatusi = this.fb.group({
      statusi: ["Slobodno"],
      invBrojevi: [++this.counter],
    });

    this.invStatusi.push(invStatusi);
  }

  deleteInvStatusi(i) {
    this.invStatusi.removeAt(i);
    --this.counter;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  /* GETTING VALUES FROM AUTOCOMPLETE INPUTS */

  //NEW INPUT WAY FOR AUTOCOMPLETES
  @ViewChild("godisteSveskeAuto", { static: false }) godisteSveskeAuto: any;
  @ViewChild("godinaIzdavanja", { static: false }) godinaIzdavanja: any;
  @ViewChild("Autor", { static: false }) inputAutor: any;
  @ViewChild("MestoIzdavanja", { static: false }) inputMesto: any;
  @ViewChild("NacinNabavke", { static: false }) inputNacinNabavke: any;
  @ViewChild("UDK", { static: false }) inputUdk: any;
  @ViewChild("Naslov", { static: true }) inputNaslov: any;
  @ViewChild("Izdavac", { static: true }) inputIzdavac: any;
  @ViewChild("Povez", { static: true }) inputPovez: any;
  @ViewChild("Signatura", { static: false }) inputSignatura: any;
  @ViewChild("ISBN", { static: false }) inputISBN: any;
  @ViewChild("ISMN", { static: false }) inputISMN: any;
  @ViewChild("Prateci", { static: false }) inputPrateci: any;
  @ViewChild("Ucesnici", { static: false }) inputUcesnici: any;
  makeAModel(form: FormGroup) {
    form.value.GodinaIzdavanja = this.godinaIzdavanja.input.nativeElement.value;
    form.value.GodisteSveske = this.godisteSveskeAuto.input.nativeElement.value;
    form.value.Autor = this.inputAutor.input.nativeElement.value;
    form.value.Mesto = this.inputMesto.input.nativeElement.value;
    form.value.NacinNabavke = this.inputNacinNabavke.input.nativeElement.value;
    form.value.UDK = this.inputUdk.input.nativeElement.value;
    form.value.Naslov = this.inputNaslov.input.nativeElement.value;
    form.value.Izdavac = this.inputIzdavac.input.nativeElement.value;
    form.value.Povez = this.inputPovez.input.nativeElement.value;
    form.value.Signatura = this.inputSignatura.nativeElement.value;
    form.value.Ucesnici = this.inputUcesnici.input.nativeElement.value;

    this.authorAutoCompleteCheck(form.value.Autor);
    this.placeAutoCompleteCheck(form.value.Mesto);
    this.purchaseTypeCompleteCheck(form.value.NacinNabavke);
    this.UDKAutoCompleteCheck(form.value.UDK);
    this.titleCompleteCheck(form.value.Naslov);
    this.publisherCompleteCheck(form.value.Izdavac);
    this.bindingCompleteCheck(form.value.Povez);
    this.participantCompleteCheck(form.value.Ucesnici);

    return form;
  }

  resetAutoCompletes() {
    this.godinaIzdavanja.input.nativeElement.value = "";
    this.godisteSveskeAuto.input.nativeElement.value = "";
    this.inputAutor.input.nativeElement.value = "";
    this.inputMesto.input.nativeElement.value = "";
    this.inputNacinNabavke.input.nativeElement.value = "";
    this.inputUdk.input.nativeElement.value = "";
    this.inputNaslov.input.nativeElement.value = "";
    this.inputPovez.input.nativeElement.value = "";
    this.inputIzdavac.input.nativeElement.value = "";
    this.inputUcesnici.input.nativeElement.value = "";
  }

  /* CHECKING IF AUTOCOMPLETE VALUES ALREADY EXISTS */
  authorAutoCompleteCheck(author) {
    if (
      !(this.authors.some((item) => item === author) ? true : false) &&
      author.trim() !== ""
    )
      this.musicalNotesService.insertAuthor(author);
  }
  placeAutoCompleteCheck(place) {
    if (
      !(this.places.some((item) => item === place) ? true : false) &&
      place.trim() !== ""
    )
      this.musicalNotesService.insertPlace(place);
  }
  purchaseTypeCompleteCheck(type) {
    if (
      !(this.purchaseTypes.some((item) => item === type) ? true : false) &&
      type.trim() !== ""
    )
      this.musicalNotesService.insertPurchaseType(type);
  }

  UDKAutoCompleteCheck(UDK) {
    if (
      !(this.udks.some((item) => item === UDK) ? true : false) &&
      UDK.trim() !== ""
    )
      this.musicalNotesService.insertUDK(UDK);
  }

  titleCompleteCheck(titleName) {
    if (
      !(this.titles.some((item) => item === titleName) ? true : false) &&
      titleName.trim() !== ""
    )
      this.musicalNotesService.insertTitle(titleName);
  }

  publisherCompleteCheck(name) {
    if (
      !(this.publishers.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertPublisher(name);
  }

  bindingCompleteCheck(name) {
    if (
      !(this.bindings.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertBinding(name);
  }

  participantCompleteCheck(name) {
    if (
      !(this.participants.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertParticipant(name);
  }

  //CHANGING THE CATEGORY OF THE CURRENT PUBLICATION
  pubs: any = [
    { name: "Serijska publikacija" },
    { name: "Monografska publikacija" },
    { name: "Muzikalije" },
    { name: "Zvučna građa" },
  ];
  selectedPub = "Serijska publikacija";
  changePublicationCategory(selectedPub) {
    try {
      if (selectedPub === "Monografska publikacija") {
        //FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.monoPubService.insertNewMonoPub(
          this.schoolName,
          this.myForm.value
        );
      }
      if (selectedPub === "Muzikalije") {
        //FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.musicalPubService.insertMusicalElement(
          this.schoolName,
          this.myForm.value
        );
      }
      if (selectedPub === "Zvučna građa") {
        //FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.soundPubService.insertSoundMaterial(
          this.schoolName,
          this.myForm.value
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      // DELETING THIS NODE FROM CURRENT PUB NODE
      this.serialPubService.deleteSerialPub(this.schoolName, this.data.key);
      this.closeDialog();
    }
  }

  resetForm(form: FormGroup) {
    form.reset(this.formStructure());

    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }
}
