import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalsInputComponent } from "src/app/musical-notes/musical-notes-inputs/musicals-input/musicals-input.component";
import { MusicalPubRentComponent } from "src/app/musical-notes/musical-rents/musical-pub-rent/musical-pub-rent.component";
import { RemarkViewComponent } from "../../remark-view/remark-view.component";

@Component({
  selector: "app-musical-pub-item",
  templateUrl: "./musical-pub-item.component.html",
  styleUrls: ["./musical-pub-item.component.css"],
})
export class MusicalPubItemComponent implements OnInit {
  @Input() musicalPubElement: any;
  currentSchoolKey: any = "";
  loggedUser: any;
  constructor(private dialog: MatDialog, private authService: AuthService) {}

  ngOnInit() {
    this.loggedUser = this.authService.logedUser;
  }
  onOpenEditDialog(key) {
    this.dialog.open(MusicalsInputComponent, {
      disableClose: false,
      autoFocus: true,
      width: "700px",
      height: "700px",

      data: {
        key: key,
        SchoolName: this.loggedUser.School.Naziv,
      },
    });
  }

  onOpenMusicalPubbRent(key) {
    this.musicalPubElement.key = this.musicalPubElement.$key;

    this.dialog.open(MusicalPubRentComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "90%",

      data: {
        key: key,
        SchoolName: this.loggedUser.School.Naziv,
        MusicalPub: this.musicalPubElement,
      },
    });
  }

  onOpenFullRemark(text) {
    this.dialog.open(RemarkViewComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      data: {
        text: text,
      },
    });
  }
}
