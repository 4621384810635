<h4 style="text-align: center" *ngIf="userObject?.role === 'SuperAdmin'">
  Kreiranje korisnika
</h4>
<h4 style="text-align: center" *ngIf="userObject?.role === 'AdminSkole'">
  Kreiranje korisnika za {{ userObject?.School.Naziv }} školu
</h4>
<div>
  <form #userRegForm="ngForm" (ngSubmit)="createUser(userRegForm)">
    <div fxLayout="column">
      <mat-form-field class="example-full-width">
        <input
          matInput
          ngModel
          #firstname="ngModel"
          name="firstName"
          type="text"
          class="form-control"
          id="firstName"
          placeholder="Ime"
        />
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <input
          matInput
          ngModel
          #lastname="ngModel"
          name="lastName"
          type="text"
          class="form-control"
          id="lastName"
          placeholder="Prezime"
          value=""
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          ngModel
          #email="ngModel"
          name="email"
          type="email"
          class="form-control"
          placeholder="Email"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          ngModel
          #email="ngModel"
          name="phoneNumber1"
          type="text"
          class="form-control"
          placeholder="Broj telefona 1"
        />
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          ngModel
          #password="ngModel"
          name="password"
          type="password"
          class="form-control"
          placeholder="Lozinka"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="
          userObject?.role === 'SuperAdmin' || userObject?.role === 'AdminSkole'
        "
      >
        <mat-label>Odaberi privilegiju</mat-label>
        <mat-select
          (selectionChange)="ngOnInit()"
          name="selectedRola"
          [(ngModel)]="selectedRola"
        >
          <mat-option *ngFor="let rola of roles" [value]="rola.Naziv">
            {{ rola.Naziv }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container
        *ngIf="
          selectedRola === 'AdminSkole' && userObject?.role !== 'AdminSkole'
        "
      >
        <mat-form-field>
          <mat-label>Naziv škole</mat-label>
          <mat-select name="selectedSkola" [(ngModel)]="selectedSkola">
            <mat-option
              *ngFor="let schoolItem of schools"
              [value]="schoolItem.Naziv"
            >
              {{ schoolItem.Naziv }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- Odabir skole -->
      <form class="example-form" *ngIf="userObject?.role === 'SuperAdmin'">
        <mat-form-field
          style="width: 100% !important"
          class="example-full-width"
        >
          <input
            type="text"
            placeholder="Pretraži školu"
            aria-label="Number"
            matInput
            #Skola
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <div *ngIf="authError" style="text-align: center">
        <h5 style="color: red">
          {{ authError?.message }}
        </h5>
      </div>
    </div>
    <div style="margin: 5%">
      <button mat-stroked-button type="submit">Kreiraj</button>
    </div>
  </form>
</div>
