import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "angularfire2/database";

@Injectable({
  providedIn: "root",
})
export class MusicalPubInputService {
  musicals: AngularFireList<any>;
  docCategories: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.musicals = firebase.list("muzikalije");
    this.docCategories = firebase.list("vrsteDokumenata");
  }

  getMusicalPubs(schoolName) {
    this.musicals = this.firebase.list(schoolName + "/muzikalije");
    return this.musicals.snapshotChanges();
  }

  getDocCategories() {
    this.docCategories = this.firebase.list("vrsteDokumenata");
    return this.docCategories.snapshotChanges();
  }

  insertDocCategory(naziv) {
    this.docCategories.push({
      Naziv: naziv,
    });
  }

  insertMusicalElement(schoolName, formData) {
    this.musicals = this.firebase.list(schoolName + "/muzikalije");

    if (!formData.GodinaIzdavanja) formData.GodinaIzdavanja = "";
    if (!formData.GodisteSveske) formData.GodisteSveske = "";

    if (!formData.VrstaDokumenta) formData.VrstaDokumenta = "";
    this.musicals.push({
      Tip: "Muzikalije",
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      Datum: formData.Datum.toString(),
      UDK: formData.UDK,
      GodinaIzdavanja: formData.GodinaIzdavanja,
      GodisteSveske: formData.GodisteSveske,
      Dimenzije: formData.Dimenzije,
      NacinNabavke: formData.NacinNabavke,
      PrateciDokument: formData.PrateciDokument,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Sadrzaj: formData.Sadrzaj,
      Signatura: formData.Signatura,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Izdavac: formData.Izdavac,
      VrstaDokumenta: formData.VrstaDokumenta,
      Povez: formData.Povez,
    });
  }

  updateMusicalPub(schoolName, key, formData) {
    this.firebase.object(schoolName + "/muzikalije/" + key).update({
      Tip: "Muzikalije",
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      UDK: formData.UDK,
      Datum: formData.Datum.toString(),
      GodinaIzdavanja: formData.GodinaIzdavanja,
      GodisteSveske: formData.GodisteSveske,
      Dimenzije: formData.Dimenzije,
      NacinNabavke: formData.NacinNabavke,
      PrateciDokument: formData.PrateciDokument,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Signatura: formData.Signatura,
      Sadrzaj: formData.Sadrzaj,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Izdavac: formData.Izdavac,
      VrstaDokumenta: formData.VrstaDokumenta,
      Povez: formData.Povez,
    });
  }

  deleteMusicalPub(schoolName, key) {
    this.firebase.database.ref(schoolName + "/muzikalije/" + key).remove();
  }
}
