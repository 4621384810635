<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <a routerLink="/" style="margin: 0 auto">
        <img
          src="assets/images/galis_250x196.jpg"
          alt="logo"
          style="max-height: 100px; margin-top: 30%"
        />
      </a>
    </mat-toolbar>
    <br /><br />

    <mat-nav-list>
      <a mat-list-item *ngIf="!user" (click)="onOpenLoginDialog()">
        <mat-icon>input</mat-icon> Prijava
      </a>
      <div mat-list-item>
        <a
          *ngIf="authService.logedUser?.role === 'SuperAdmin'"
          routerLink="/korisnici"
          mat-list-item
          href="#"
        >
          <mat-icon>supervised_user_circle</mat-icon> Korisnici
        </a>
        <a
          *ngIf="
            authService.logedUser?.role === 'AdminSkole' &&
            user &&
            authService.logedUser?.AccountEnabled
          "
          routerLink="/korisnici"
          mat-list-item
          href="#"
        >
          <mat-icon>supervised_user_circle</mat-icon> Korisnici
        </a>
      </div>

      <mat-accordion
        mat-list-item
        *ngIf="user && authService.logedUser?.AccountEnabled"
      >
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>library_music</mat-icon>
            </mat-panel-title>
            <mat-panel-description> Publikacije </mat-panel-description>
          </mat-expansion-panel-header>
          <button
            (click)="onOpenSchoolInsert()"
            mat-stroked-button
            *ngIf="
              authService.logedUser?.role === 'AdminSkole' ||
              authService.logedUser?.role === 'SuperAdmin'
            "
            style="width: 100% !important; margin: 3%"
          >
            <mat-icon>library_add</mat-icon> Unos &nbsp; &nbsp;
          </button>

          <button
            (click)="onOpenSchoolViews()"
            mat-stroked-button
            style="width: 100% !important; margin: 3%"
          >
            <mat-icon>library_music</mat-icon> Pregled
          </button>

          <button
            *ngIf="
              authService.logedUser?.role === 'AdminSkole' ||
              authService.logedUser?.role === 'SuperAdmin'
            "
            (click)="onOpenSchoolRents()"
            mat-stroked-button
            style="width: 100% !important; margin: 3%"
          >
            <mat-icon>assignment</mat-icon> Izveštaji
          </button>

          <button
            *ngIf="user"
            mat-stroked-button
            style="width: 100% !important; margin: 3%"
            routerLink="/upustva"
          >
            <mat-icon>details</mat-icon> Upustvo
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style>
    <div id="destination"></div>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div style="display: flex; flex-flow: row wrap">
        <span>Medijateka </span>

        <button *ngIf="user" mat-button [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <p style="text-align: center; color: green; font-size: smaller">
            {{ authService.logedUser?.firstname }}
            {{ authService.logedUser?.lastname }}
          </p>
          <mat-divider></mat-divider>
          <button
            *ngIf="user"
            mat-menu-item
            routerLink="/userProfile"
            (click)="onOpenUserProfile()"
          >
            <mat-icon>dashboard</mat-icon> Korisnički profil
          </button>
          <button
            *ngIf="authService.logedUser?.role"
            mat-menu-item
            style="color: red"
            (click)="logout()"
          >
            <mat-icon>call_received</mat-icon>Odjava
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <mat-toolbar style="display: inline">
      <div class="footer" style="background-color: #673ab7">
        <p style="font-size: small">
          <a
            href="https://galilej.com"
            target="_blank"
            style="text-decoration: none; color: white"
          >
            © {{ currentYear }} Galilej Informacione Tehnologije
          </a>
        </p>
      </div>
    </mat-toolbar>
  </mat-sidenav-content>
</mat-sidenav-container>
