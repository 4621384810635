import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";
import { SharedClass } from "src/app/shared/shared-functions";
import { UserService } from "src/app/user/user.service";

@Component({
  selector: "app-musical-pub-report",
  templateUrl: "./musical-pub-report.component.html",
  styleUrls: ["./musical-pub-report.component.css"],
})
export class MusicalPubReportComponent implements OnInit {
  userObject: User;
  users: User[] = [];
  user: firebase.User;
  showReverse: boolean = false;
  fromProfile: boolean = false;

  selectedMusicalPub: any = {};

  listOfRentedMusicalPubs: any = [];

  currentDate: any = this.sharedClass.currentDateFormatddmmyyyy();
  date = new FormControl(new Date());
  minDate = new Date();
  @ViewChild("cenaIznajmljivanja", { static: false }) inputCena: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private authService: AuthService,
    public sharedClass: SharedClass,
    private musicalNotesService: MusicalNotesService
  ) {}

  ngOnInit() {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService
          .getUserObject(user.uid)
          .subscribe((item) => (this.userObject = item));
      }
      this.selectedMusicalPub = this.data.selectedMusicalPub;
      if (this.data.fromProfile) this.fromProfile = this.data.fromProfile;
      for (let key in this.selectedMusicalPub.MusicalPub.invStatusi) {
        this.listOfRentedMusicalPubs.push({
          key: key,
          data: this.selectedMusicalPub.MusicalPub.invStatusi[key],
        });
      }
    });
  }

  onReturnTheSelectedRent(index: number) {
    if (confirm("Da li ste sigurni da je korisnik vratio muzikaliju?")) {
      // UPDATING THE STATUS ON ORIGINAL MONO PUB ELEMENT

      this.authService.userObject.valueChanges().subscribe((item) => {
        this.musicalNotesService.updateTheStatusOfReturnedMusicalPub(
          item.School.Naziv,
          this.data.musicalPubKey,
          index
        );

        //UPDATING THE STATUS ON RENTING MONO PUB ELEMENT
        this.musicalNotesService.updateTheStatusOnRentMusicalPub(
          item.School.Naziv,
          this.data.rentKey,
          index
        );
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
