import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class MonoPubInputService {
    constructor(firebase) {
        this.firebase = firebase;
        this.monoPub = firebase.list("monografskePublikacije");
    }
    getMonoPubs(schoolName) {
        this.monoPub = this.firebase.list(schoolName + "/monografskePublikacije");
        return this.monoPub.snapshotChanges();
    }
    insertNewMonoPub(schoolName, formData) {
        this.monoPub = this.firebase.list(schoolName + "/monografskePublikacije");
        if (!formData.BrojSveske)
            formData.BrojSveske = "";
        this.monoPub.push({
            Tip: "Monografska",
            Naslov: formData.Naslov,
            NaslovTranskripcija: formData.NaslovTranskripcija,
            Podnaslov: formData.Podnaslov,
            PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
            Datum: formData.Datum.toString(),
            Autor: formData.Autor,
            AutorTranskripcija: formData.AutorTranskripcija,
            Ucesnici: formData.Ucesnici,
            UcesniciTranskripcija: formData.UcesniciTranskripcija,
            Mesto: formData.Mesto,
            Polica: formData.Polica,
            Izdavac: formData.Izdavac,
            UDK: formData.UDK,
            BrojSveske: formData.BrojSveske,
            Dimenzije: formData.Dimenzije,
            GodinaIzdavanja: formData.GodinaIzdavanja,
            GodisteSveske: formData.GodisteSveske,
            NacinNabavke: formData.NacinNabavke,
            PrateciDokument: formData.PrateciDokument,
            Cena: formData.Cena,
            ISBN: formData.ISBN,
            ISMN: formData.ISMN,
            Signatura: formData.Signatura,
            Sadrzaj: formData.Sadrzaj,
            Napomena: formData.Napomena,
            LinkPublikacije: formData.LinkPublikacije,
            imagesURLs: formData.imagesURLs,
            invStatusi: formData.invStatusi,
            Povez: formData.Povez,
        });
    }
    updateMonoPub(schoolName, key, formData) {
        this.firebase.object(schoolName + "/monografskePublikacije/" + key).update({
            Tip: "Monografska",
            Naslov: formData.Naslov,
            NaslovTranskripcija: formData.NaslovTranskripcija,
            Podnaslov: formData.Podnaslov,
            PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
            Autor: formData.Autor,
            AutorTranskripcija: formData.AutorTranskripcija,
            Ucesnici: formData.Ucesnici,
            UcesniciTranskripcija: formData.UcesniciTranskripcija,
            Datum: formData.Datum.toString(),
            Mesto: formData.Mesto,
            Polica: formData.Polica,
            Izdavac: formData.Izdavac,
            UDK: formData.UDK,
            BrojSveske: formData.BrojSveske,
            GodinaIzdavanja: formData.GodinaIzdavanja,
            GodisteSveske: formData.GodisteSveske,
            Dimenzije: formData.Dimenzije,
            NacinNabavke: formData.NacinNabavke,
            PrateciDokument: formData.PrateciDokument,
            Cena: formData.Cena,
            ISBN: formData.ISBN,
            ISMN: formData.ISMN,
            Signatura: formData.Signatura,
            Sadrzaj: formData.Sadrzaj,
            Napomena: formData.Napomena,
            LinkPublikacije: formData.LinkPublikacije,
            imagesURLs: formData.imagesURLs,
            invStatusi: formData.invStatusi,
            Povez: formData.Povez,
        });
    }
    deleteMonoPub(schoolName, key) {
        this.firebase.database
            .ref(schoolName + "/monografskePublikacije/" + key)
            .remove();
    }
}
MonoPubInputService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MonoPubInputService_Factory() { return new MonoPubInputService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: MonoPubInputService, providedIn: "root" });
