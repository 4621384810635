/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-book-configuration.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./print-book-configuration.component";
import * as i9 from "@angular/material/dialog";
var styles_PrintBookConfigurationComponent = [i0.styles];
var RenderType_PrintBookConfigurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintBookConfigurationComponent, data: {} });
export { RenderType_PrintBookConfigurationComponent as RenderType_PrintBookConfigurationComponent };
function View_PrintBookConfigurationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkedChanged($event, _v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_7 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.checked); _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 4, 0, currVal_8); }); }
export function View_PrintBookConfigurationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintBookConfigurationComponent_1)), i1.ɵdid(1, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PrintBookConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-book-configuration", [], null, null, null, View_PrintBookConfigurationComponent_0, RenderType_PrintBookConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i8.PrintBookConfigurationComponent, [i9.MatDialog, [2, i9.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintBookConfigurationComponentNgFactory = i1.ɵccf("app-print-book-configuration", i8.PrintBookConfigurationComponent, View_PrintBookConfigurationComponent_Host_0, {}, {}, []);
export { PrintBookConfigurationComponentNgFactory as PrintBookConfigurationComponentNgFactory };
