import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AuthService } from "../auth.service";

export class Role {
  Naziv: string;
}

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {
  authError: any;
  user: User = {};
  roles: Role[] = [];
  schools: any[] = [];
  selectedRola: string = "";
  @ViewChild("selectedSkola", { static: true }) inputNazivSkole: any;
  selectedSkola: string = "";
  userObject: User = {};

  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  constructor(private auth: AuthService, private dialog: MatDialog) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.getMusicalSchools();
    this.auth.getRoles().subscribe((list) => {
      this.roles = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });

    this.auth.eventAuthError$.subscribe((data) => {
      this.authError = data;
    });

    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth
          .getUserObject(user.uid)
          .subscribe((item) => (this.userObject = item));
      }
    });
  }

  getMusicalSchools() {
    this.auth.getMusicalSchools().subscribe((list) => {
      this.schools = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.schools.forEach((element) => {
        this.options.push(element.Naziv);
      });
    });
  }

  @ViewChild("Skola", { static: false }) selectedSchool: any;

  createUser(frm) {
    let typedSchool = this.inputNazivSkole ? this.inputNazivSkole.nativeElement.value : "";

    if (this.userObject.role === "SuperAdmin") {
      let selectedSchool = this.schools.filter(
        (item) => item.Naziv === typedSchool
      );
      this.auth.createUser(
        frm.value,
        this.selectedRola,
        this.auth.logedUserUID(),
        selectedSchool[0]
      );
    } else if (this.userObject.role === "AdminSkole") {
      let currentSchool: { Naziv: string; $key: string } = {
        Naziv: this.userObject.School.Naziv,
        $key: this.userObject.School.key,
      };
      this.auth.createUser(
        frm.value,
        this.selectedRola,
        this.auth.logedUserUID(),
        currentSchool
      );
    }
  }
}
