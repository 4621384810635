<div style="max-width: 100%; margin: 0 auto; overflow: auto;">
  <div style="text-align: center;">
    <h4 class="mt-4">Korisnik</h4>
  </div>
  <div>
    <mat-divider></mat-divider>
  </div>
  <br />
  <div style="display: flex; flex-flow: row wrap; justify-content: center;">
    <div class="divImg" style="margin: 5%;">
      <img src="{{ userObject?.imageUrl }}" />
    </div>
    <div style="display: flex; flex-flow: column wrap; width: 100% !important;">
      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.firstname }}"
            matInput
            #Ime
            type="text"
            placeholder="Ime"
          />
        </mat-form-field>
      </form>
      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.lastname }}"
            matInput
            #Prezime
            type="text"
            placeholder="Prezime"
          />
        </mat-form-field>
      </form>
      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.brojLicneKarte }}"
            matInput
            #brojLicneKarte
            type="text"
            placeholder="Broj lične karte"
          />
        </mat-form-field>
      </form>
      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.adresa }}"
            matInput
            #Adresa
            type="text"
            placeholder="Adresa"
          />
        </mat-form-field>
      </form>

      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.brojTelefona1 }}"
            matInput
            #BrojTelefona1
            type="text"
            placeholder="Broj telefona 1"
          />
        </mat-form-field>
      </form>

      <form class="example-form" style="width: 100% !important;">
        <mat-form-field
          class="example-full-width"
          style="width: 100% !important;"
        >
          <input
            value="{{ userObject?.brojTelefona2 }}"
            matInput
            #BrojTelefona2
            type="text"
            placeholder="Broj telefona 2"
          />
        </mat-form-field>
      </form>

      <mat-form-field>
        <mat-label>Privilegija</mat-label>
        <mat-select name="selectedRola" [(ngModel)]="selectedRola">
          <mat-option [value]="selectedRola">
            {{ userObject?.role }}</mat-option
          >
          <ng-container *ngFor="let rola of roles">
            <mat-option
              *ngIf="rola?.Naziv !== userObject?.role"
              [value]="rola?.Naziv"
            >
              {{ rola?.Naziv }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="data?.logedUserRole === 'SuperAdmin'">
        <mat-label>Škola</mat-label>
        <mat-select name="selectedSchool" [(ngModel)]="selectedSchool">
          <mat-option [value]="selectedSchool">
            {{ userObject?.School?.Naziv }}</mat-option
          >
          <ng-container *ngFor="let schoolItem of schools">
            <mat-option
              *ngIf="schoolItem?.Naziv !== userObject?.School?.Naziv"
              [value]="schoolItem.Naziv"
            >
              {{ schoolItem?.Naziv }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <p>
        Email: <b> {{ userObject?.email }} </b>
      </p>
      <button mat-stroked-button (click)="onUpdateUser(data.uid)">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-stroked-button
        (click)="resetPassword()"
        style="margin-top: 3%;"
      >
        <mat-icon>screen_lock_landscape</mat-icon> Resetuj lozinku
      </button>
    </div>
  </div>
</div>
