<h4 style="text-align: center" *ngIf="selectedCategory === 'serijska'">
  Serijske publikacije
</h4>

<h4 style="text-align: center" *ngIf="selectedCategory === 'monografska'">
  Monografska publikacija
</h4>
<ng-container *ngFor="let prop of printProperties">
  <mat-checkbox
    [checked]="prop.selected"
    (change)="checkedChanged($event, prop.name)"
  >
    {{ prop.name }}
  </mat-checkbox>
  <br />
</ng-container>
<br />
<button
  mat-stroked-button
  style="margin-left: 30%"
  (click)="onOpenPrintConfiguration()"
>
  <mat-icon>print</mat-icon>
</button>
