<h4 style="text-align: center">Izdavanje publikacija</h4>

<p>Slobodnih: {{ leftRentingCounter }}</p>
<table id="rentsSerial">
  <tr>
    <th>R. br.</th>
    <th>Inventarski brojevi</th>
    <th>Status</th>
    <th>Opcije</th>
  </tr>
  <tr *ngFor="let item of serialPubElement?.invStatusi; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ item?.invBrojevi }}</td>
    <td>{{ item?.statusi }}</td>
    <td>
      <section class="example-section" *ngIf="item.statusi === 'Slobodno'">
        <mat-checkbox
          class="example-margin"
          (change)="checkBoxSerialPubsChanged($event, i)"
        ></mat-checkbox>
      </section>
    </td>
  </tr>
</table>
<div style="margin: 5%">
  <div style="display: flex">
    <mat-form-field class="search-form-field" style="width: 100%">
      <input
        matInput
        [(ngModel)]="searchKey"
        placeholder="Pretraži"
        autocomplete="off"
        (keyup)="applyFilter()"
      />
      <button
        mat-button
        *ngIf="searchKey"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchKey = ''; applyFilter()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-table class="mat-elevation-z14 table" [dataSource]="listData" matSort>
    <ng-container matColumnDef="Odaberi">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Odaberi
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-radio-button
          value="{{ element.$key }}"
          (change)="onSelectTheUser($event)"
        >
        </mat-radio-button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ImePrezime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Ime i prezime
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        >{{ element.firstname }} {{ element.lastname }}</mat-cell
      >
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="5"
    showFirstLastButtons
  ></mat-paginator>
</div>

<h5 *ngIf="showMessage" style="color: red; text-align: center">
  Morate odabrati korisnika i publikaciju
</h5>

<button mat-stroked-button style="margin-left: 45%" (click)="onRentSerialPub()">
  Izdaj
</button>
