/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./musical-pub-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pub-items/musical-pub-item/musical-pub-item.component.ngfactory";
import * as i3 from "../../pub-items/musical-pub-item/musical-pub-item.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../auth/auth.service";
import * as i6 from "@angular/common";
import * as i7 from "./musical-pub-list.component";
var styles_MusicalPubListComponent = [i0.styles];
var RenderType_MusicalPubListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MusicalPubListComponent, data: {} });
export { RenderType_MusicalPubListComponent as RenderType_MusicalPubListComponent };
function View_MusicalPubListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "padding: 1%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-musical-pub-item", [], null, null, null, i2.View_MusicalPubItemComponent_0, i2.RenderType_MusicalPubItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.MusicalPubItemComponent, [i4.MatDialog, i5.AuthService], { musicalPubElement: [0, "musicalPubElement"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MusicalPubListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: flex; flex-flow: row wrap; justify-content: center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MusicalPubListComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredMusicalPubs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MusicalPubListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-musical-pub-list", [], null, null, null, View_MusicalPubListComponent_0, RenderType_MusicalPubListComponent)), i1.ɵdid(1, 114688, null, 0, i7.MusicalPubListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MusicalPubListComponentNgFactory = i1.ɵccf("app-musical-pub-list", i7.MusicalPubListComponent, View_MusicalPubListComponent_Host_0, { musicalPubs: "musicalPubs", filteredMusicalPubs: "filteredMusicalPubs" }, {}, []);
export { MusicalPubListComponentNgFactory as MusicalPubListComponentNgFactory };
