<div
  [hidden]="
    !(
      (user && userObject?.role === 'SuperAdmin') ||
      userObject?.role === 'AdminSkole'
    )
  "
  style="margin: 2%"
>
  <mat-accordion mat-list-item *ngIf="!musicalNotesService.fromProfile">
    <mat-expansion-panel [expanded]="musicalNotesService.expandedBookAccording">
      <mat-expansion-panel-header
        (click)="musicalNotesService.changeExpandState()"
        style="background-color: #673ab7"
      >
        <mat-panel-title style="color: white">
          <p>Knjiga inventara</p>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <mat-card>
          <ng-container *ngIf="!musicalNotesService.fromProfile">
            <button
              mat-stroked-button
              style="margin: 1%"
              (click)="onOpenSerialPubReportRouter()"
            >
              Serijske publikacije
            </button>
            <button
              mat-stroked-button
              style="margin: 1%"
              (click)="onOpenMonoPubReportView()"
            >
              Monografske publikacije
            </button>

            <button
              mat-stroked-button
              style="margin: 1%"
              (click)="onOpenMusicalPubReport()"
            >
              Muzikalije
            </button>

            <button
              mat-stroked-button
              style="margin: 1%"
              style="background-color: #d8c2ff"
            >
              Zvučna građa
            </button>
            <button
              style="margin: 1%"
              mat-raised-button
              (click)="onOpenPrintForBook()"
            >
              Odaberi kolone
            </button>
            <button
              style="margin: 1%"
              [useExistingCss]="true"
              printTitle="Knjiga inventara - muzikalije"
              printSectionId="print-section"
              ngxPrint
              mat-raised-button
            >
              <mat-icon>print</mat-icon>
            </button>
          </ng-container>
        </mat-card>
      </div>
      <div style="text-align: center" id="print-section">
        <h3>{{ userObject?.School?.Naziv.toUpperCase() }}</h3>
        <h2>KNJIGA INVENTARA</h2>
        <h3>Zvučna građa</h3>
        <div>
          <table>
            <thead>
              <tr style="font-size: small">
                <th>REDNI BROJ</th>
                <ng-container *ngFor="let prop of columns">
                  <th *ngIf="prop.checked">
                    {{ prop?.name.toUpperCase() }}
                  </th>
                </ng-container>
              </tr>
              <tr>
                <th>1</th>
                <ng-container
                  *ngFor="let prop of checkedCounter(); let i = index"
                >
                  <th>
                    {{ i + 2 }}
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tr class="row" *ngFor="let item of soundPubs; let i = index">
              <td>{{ i + 1 }}</td>

              <ng-container *ngFor="let column of columns">
                <td *ngIf="column.name === 'Datum' && column.checked">
                  {{ item?.Datum.toString().split(" ")[2] }}.{{
                    sharedClass.monthInNumber(item?.Datum.split(" ")[1])
                  }}.{{ item?.Datum.split(" ")[3] }}.
                </td>
                <td
                  style="text-align: left"
                  *ngIf="
                    column.name ===
                      'Autor, Naslov, Mesto izdavanja, Izdavač, Godina izdavanja' &&
                    column.checked
                  "
                >
                  <ng-container *ngIf="item?.Autor?.split(' ').length >= 2">
                    <b>{{ item?.Autor.split(" ")[1].toUpperCase() }}, </b>
                  </ng-container>
                  {{ item?.Autor.split(" ")[0] }}
                  <br />
                  &nbsp;&nbsp; {{ item?.Naslov }} :
                  <ng-container *ngIf="item.Podnaslov !== ''">
                    {{ item?.Podnaslov }}
                  </ng-container>
                  / {{ item?.Autor }}. - {{ item?.Mesto }} :
                  {{ item?.Izdavac }},
                  {{ item?.GodinaIzdavanja }}
                </td>
                <td *ngIf="column.name === 'Povez' && column.checked">
                  {{ item?.Povez }}
                </td>
                <td *ngIf="column.name === 'Dimenzije' && column.checked">
                  {{ item?.Dimenzije }} cm
                </td>
                <td *ngIf="column.name === 'Način nabavke' && column.checked">
                  {{ item?.NacinNabavke }}
                </td>
                <td *ngIf="column.name === 'Cena' && column.checked">
                  {{ item?.Cena }}
                </td>
                <td *ngIf="column.name === 'Signatura' && column.checked">
                  {{ item?.Signatura }}
                </td>
                <td *ngIf="column.name === 'Napomena' && column.checked">
                  {{ item?.Napomena }}
                </td>
                <td *ngIf="column.name === 'Podnaslov' && column.checked">
                  {{ item?.Podnaslov }}
                </td>
                <td *ngIf="column.name === 'Vrsta dokumenta' && column.checked">
                  {{ item?.VrstaDokumenta }}
                </td>
                <td *ngIf="column.name === 'Broj dokumenta' && column.checked">
                  {{ item?.BrojDokumenta }}
                </td>
                <td *ngIf="column.name === 'Propratna građa' && column.checked">
                  {{ item?.PropratnaGradja }}
                </td>
                <td
                  *ngIf="
                    column.name === 'Tehnika zvučnog snimka' && column.checked
                  "
                >
                  {{ item?.TehnikaZvSnimka }}
                </td>
                <td *ngIf="column.name === 'Sadržaj' && column.checked">
                  {{ item?.Sadrzaj }}
                </td>
                <td *ngIf="column.name === 'Godište sveske' && column.checked">
                  {{ item?.GodisteSveske }}
                </td>
                <td
                  *ngIf="column.name === 'Prateći dokument' && column.checked"
                >
                  {{ item?.PrateciDokument }}
                </td>
                <td *ngIf="column.name === 'UDK' && column.checked">
                  {{ item?.UDK }}
                </td>
                <td *ngIf="column.name === 'ISBN' && column.checked">
                  {{ item?.ISBN }}
                </td>
                <td *ngIf="column.name === 'ISMN' && column.checked">
                  {{ item?.ISMN }}
                </td>
                <td
                  *ngIf="column.name === 'Link publikacije Cobiss' && column.checked"
                >
                  {{ item?.LinkPublikacije }}
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div
  [hidden]="
    !(
      (user && userObject?.role === 'SuperAdmin') ||
      userObject?.role === 'AdminSkole'
    )
  "
  style="margin: 2%"
>
  <mat-accordion mat-list-item>
    <mat-expansion-panel
      [expanded]="musicalNotesService.expandedReportAccording"
    >
      <mat-expansion-panel-header
        style="background-color: #673ab7"
        (click)="musicalNotesService.changeReportExpandState()"
      >
        <mat-panel-title style="color: white">
          <p>Pregled izdavanja</p>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        style="
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          margin: 1%;
          background-color: #673ab7;
          border-radius: 10px;
        "
      >
        <div class="statistic" style="padding: 1%; font-size: xx-large">
          Ukupno: <b> {{ totalSerialPubs }}</b>
          <mat-icon>queue_music</mat-icon>
        </div>
        <div class="statistic" style="padding: 1%; font-size: xx-large">
          Razduženih: <b> {{ rentedSerialPubs }}</b>
          <mat-icon style="color: green">done_outline</mat-icon>
        </div>
        <div class="statistic" style="padding: 1%; font-size: xx-large">
          Zauzeto:
          <b>
            {{ totalSerialPubs - rentedSerialPubs }}
          </b>
          <mat-icon style="color: red">weekend</mat-icon>
        </div>
      </div>

      <div class="mat-elevation-z14 table" style="margin: 2%">
        <h2 style="text-align: center">Stanje nototeke</h2>
        <div fxLayout="row">
          <div style="width: 100% !important; margin: 1%">
            <mat-form-field
              class="search-form-field"
              style="width: 100% !important"
            >
              <input
                matInput
                [(ngModel)]="searchKey"
                placeholder="Pretraga"
                autocomplete="off"
                (keyup)="applyFilter()"
              />
              <button
                mat-button
                *ngIf="searchKey"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchKey = ''; applyFilter()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div>
              <mat-card>
                <ng-container *ngIf="!musicalNotesService.fromProfile">
                  <button
                    mat-stroked-button
                    style="margin: 1%"
                    (click)="onOpenSerialPubReportRouter()"
                  >
                    Serijske publikacije
                  </button>
                  <button
                    mat-stroked-button
                    style="margin: 1%"
                    (click)="onOpenMonoPubReportView()"
                  >
                    Monografske publikacije
                  </button>

                  <button
                    mat-stroked-button
                    style="margin: 1%"
                    (click)="onOpenMusicalPubReport()"
                  >
                    Muzikalije
                  </button>

                  <button
                    mat-stroked-button
                    style="margin: 1%"
                    style="background-color: #d8c2ff"
                  >
                    Zvučna građa
                  </button>
                </ng-container>

                <button
                  mat-stroked-button
                  style="margin: 1%"
                  (click)="refresh()"
                >
                  <mat-icon>undo</mat-icon>
                </button>
                <button
                  mat-stroked-button
                  style="margin: 1%"
                  (click)="onOpenPrintConfiguration()"
                >
                  <mat-icon>print</mat-icon>
                </button>
              </mat-card>
            </div>
          </div>
        </div>
        <mat-table [dataSource]="listData" matSort>
          <ng-container matColumnDef="Naziv" style="margin-left: 5%">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              style="margin-left: 5%"
            >
              Naslov
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element = $implicit"
              style="margin-left: 5%"
            >
              {{ element?.sPElement?.SoundPub?.Naslov }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="Korisnik">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Korisnik
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <b>
                {{
                  this.paginator.pageIndex == 0
                    ? i + 1
                    : 1 +
                      i +
                      this.paginator.pageIndex * this.paginator.pageSize
                }}.</b
              >&nbsp;
              {{
                element?.sPElement.User?.firstname +
                  " " +
                  element?.sPElement?.User?.lastname
              }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Date">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              style="margin-left: 5%"
            >
              Datum izdavanja
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="margin-left: 5%">{{
              element?.sPElement?.DateOfRenting
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Time">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Vreme izdavanja
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element?.sPElement?.TimeOfRenting
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Email" style="margin-left: 5%">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              style="margin-left: 5%"
            >
              Email
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="margin-left: 5%">{{
              element?.sPElement?.User?.email
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Status" style="margin-left: 10%">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Broj izdavanja
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <b
                >{{ element.returnedCounter }} /
                {{ element?.sPElement?.ListaRentovanja.length }}
              </b>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Options">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Ugovor
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <div fxLayout="row" style="margin-left: 10%">
                <button
                  mat-stroked-button
                  (click)="onOpenSoundPubReport(element.index)"
                >
                  <mat-icon>date_range</mat-icon>
                </button>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageSize]="5"
          showFirstLastButtons
        ></mat-paginator>
        <mat-card
          *ngIf="isLoading"
          style="display: flex; justify-content: center; align-items: center"
        >
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
