import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { UserService } from "../user.service";
import { Role } from "src/app/auth/registration/registration.component";
import { SharedClass } from "src/app/shared/shared-functions";

@Component({
  selector: "app-user-dialog",
  templateUrl: "./user-dialog.component.html",
  styleUrls: ["./user-dialog.component.css"],
})
export class UserDialogComponent implements OnInit {
  userObject: User = {};
  roles: Role[] = [];
  schools: any[] = [];
  issuedInstruments: any[] = [];
  filteredDataForUser: any[] = [];

  selectedRola;
  selectedSchool;
  @ViewChild("Ime", { static: true }) inputFirstName: any;
  @ViewChild("Prezime", { static: true }) inputLastName: any;
  @ViewChild("Adresa", { static: true }) inputAdresa: any;
  @ViewChild("brojLicneKarte", { static: true }) inputBrLicneKarte: any;
  @ViewChild("ownIssueSearchInput", { static: false }) inputOwnIssue: any;
  @ViewChild("BrojTelefona1", { static: false }) inputBrojTelefona1: any;
  @ViewChild("BrojTelefona2", { static: false }) inputBrojTelefona2: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private userService: UserService,
    private sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.authService.getUserObject(this.data.uid).subscribe((item) => {
      this.userObject = item;
      this.selectedRola = item.role;
      this.selectedSchool = item.School.Naziv;
    });

    this.authService.getRoles().subscribe((list) => {
      this.roles = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });

    this.authService.getMusicalSchools().subscribe((list) => {
      this.schools = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  onUpdateUser(uid) {
    let selectedSchool = this.schools.filter(
      (item) => item.Naziv === this.selectedSchool
    );
    let user: User = {
      uid: uid,
      firstname: this.inputFirstName.nativeElement.value,
      lastname: this.inputLastName.nativeElement.value,
      adresa: this.inputAdresa.nativeElement.value,
      brojLicneKarte: this.inputBrLicneKarte.nativeElement.value,
      brojTelefona1: this.inputBrojTelefona1.nativeElement.value,
      brojTelefona2: this.inputBrojTelefona2.nativeElement.value,
      role: this.selectedRola,
      School: {
        key: selectedSchool[0].$key,
        Naziv: selectedSchool[0].Naziv,
      },
    };
    this.userService.updateUserProfile(user);
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  resetPassword() {
    if (this.authService.resetPassword(this.userObject.email)) {
      this.sharedClass.openSnackBar(
        "Poruka",
        "Link za reset lozinke vam je poslat na email adresu: " +
          this.userObject.email
      );
      this.closeDialog();
    }
  }

  searchOwnIssues() {
    let searchInput = this.inputOwnIssue.nativeElement.value
      .toString()
      .toLowerCase();

    this.filteredDataForUser = this.issuedInstruments.filter((item: any) => {
      return (
        item.Instrument.Naziv.toLowerCase().includes(searchInput) ||
        item.Instrument.Cena.toLowerCase().includes(searchInput) ||
        item.Instrument.GodinaProizvodnje.toLowerCase().includes(searchInput) ||
        item.Instrument.IssuingTime.toLowerCase().includes(searchInput) ||
        item.Instrument.School.Naziv.toLowerCase().includes(searchInput)
      );
    });
  }

  filterIssuesForLoggedSchool() {
    this.filteredDataForUser = this.issuedInstruments;
  }
}
