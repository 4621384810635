import { NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatSidenavModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatCardModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatMenuModule,
  MatProgressBarModule,
  MatTabsModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatBadgeModule,
} from "@angular/material";

const material = [
  MatButtonModule,
  MatSidenavModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatCardModule,
  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSnackBarModule,
  MatMenuModule,
  MatProgressBarModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatNativeDateModule,
  MatBadgeModule,
];

@NgModule({
  declarations: [],
  imports: [material],
  exports: [material],
})
export class MaterialModule {}
