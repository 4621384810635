/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remark-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./remark-view.component";
import * as i3 from "@angular/material/dialog";
var styles_RemarkViewComponent = [i0.styles];
var RenderType_RemarkViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemarkViewComponent, data: {} });
export { RenderType_RemarkViewComponent as RenderType_RemarkViewComponent };
export function View_RemarkViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_RemarkViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remark-view", [], null, null, null, View_RemarkViewComponent_0, RenderType_RemarkViewComponent)), i1.ɵdid(1, 114688, null, 0, i2.RemarkViewComponent, [i3.MatDialog, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemarkViewComponentNgFactory = i1.ɵccf("app-remark-view", i2.RemarkViewComponent, View_RemarkViewComponent_Host_0, {}, {}, []);
export { RemarkViewComponentNgFactory as RemarkViewComponentNgFactory };
