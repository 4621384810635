import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "../auth/login/login.component";
import { AuthService } from "../auth/auth.service";
import { ThrowStmt } from "@angular/compiler";
import { Router } from "@angular/router";
import { MusicalNotesService } from "../musical-notes/musical-notes.service";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.css"],
})
export class MainNavComponent implements OnInit {
  user: firebase.User;
  userObject: User = {};
  currentYear = new Date().getFullYear();

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    private musicalService: MusicalNotesService
  ) {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService.getUserObject(user.uid).subscribe((item) => {
          this.authService.logedUser = item;
          this.authService.logedUser.key = this.user.uid;
        });
      }
    });
  }

  logout() {
    this.authService.logedUser = {};
    this.authService.logout();
  }
  onOpenSchoolViews() {
    this.authService.userObject.valueChanges().subscribe((item) => {
      this.router.navigate(["/pregled", item.School.Naziv]);
    });
  }
  onOpenSchoolInsert() {
    this.authService.userObject.valueChanges().subscribe((item) => {
      this.router.navigate(["/unos", item.School.Naziv]);
    });
  }

  onOpenSchoolRents() {
    this.musicalService.fromProfile = false;

    this.authService.getUserObject(this.user.uid).subscribe((item) => {
      this.authService.logedUser = item;
      this.authService.logedUser.key = this.user.uid;
      this.authService.userObject.valueChanges().subscribe((item) => {
        this.router.navigate(["/izvestaji", item.School.Naziv]);
      });
    });
  }

  onOpenLoginDialog() {
    this.dialog.open(LoginComponent, {
      disableClose: false,
      autoFocus: true,
      width: "300px",
      height: "",

      data: {},
    });
  }

  onOpenUserProfile() {
    this.musicalService.fromProfile = true;
    this.router.navigate(["/userProfile"]);
  }
}
