<mat-card class="mat-elevation-z6" class="box" fxFlexAlign="stretch">
  <mat-card-header>
    <mat-card-title title="{{ serialPubElement?.Naslov }}"
      >{{ serialPubElement?.Naslov }}
    </mat-card-title>
  </mat-card-header>
  <div class="image-qr-div">
    <ul class="image-qr">
      <li class="crop" (click)="onOpenEditDialog(serialPubElement.$key)">
        <div style="text-align: center; display: grid">
          <div>
            <img
              *ngIf="serialPubElement.imagesURLs"
              src="{{ (serialPubElement?.imagesURLs)[0] }}"
              alt="Nota"
            />
          </div>
        </div>
      </li>
      <li class="cropQR">
        <div>
          <!-- prettier-ignore -->
          <qrcode
            [qrdata]="'Serijska \nNaslov - ' + serialPubElement?.Naslov + '\n'
            +'Autor - ' + serialPubElement?.Autor + '\n'
            +'Podnaslov - ' + serialPubElement?.Podnaslov + '\n'
            +'Izdavač - ' + serialPubElement?.Izdavac + '\n'
            +'Godina Izdavanja - ' + serialPubElement?.GodinaIzdavanja + '\n'
            +'Polica - ' + serialPubElement?.Polica + '\n'
            || 'Prazno'"
            [width]="120"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
        </div>
      </li>
    </ul>
  </div>

  <mat-card-content>
    <p>
      Datum inventarisanja:
      <b>
        {{ serialPubElement?.Datum.toString().split(" ")[2] }}.
        {{ serialPubElement?.Datum.split(" ")[1] }}.
        {{ serialPubElement?.Datum.split(" ")[3] }}.
      </b>
    </p>
    <p>
      Autor: <b>{{ serialPubElement?.Autor }}</b>
    </p>
    <p>
      Podnaslov: <b>{{ serialPubElement?.Podnaslov }}</b>
    </p>
    <p>
      Mesto izdavanja: <b> {{ serialPubElement?.Mesto }}</b>
    </p>

    <p>
      Izdavač: <b> {{ serialPubElement?.Izdavac }}</b>
    </p>
    <p>
      Godište sveske: <b>{{ serialPubElement?.GodisteSveske }}</b>
    </p>
    <p>
      Godina izdavanja: <b> {{ serialPubElement?.GodinaIzdavanja }}</b>
    </p>
    <p>
      Dimenzije: <b>{{ serialPubElement?.Dimenzije }}</b>
    </p>
    <p>
      Povez: <b>{{ serialPubElement?.Povez }}</b>
    </p>
    <p>
      Način nabavke: <b>{{ serialPubElement?.NacinNabavke }}</b>
    </p>
    <p>
      Prateći dokument: <b>{{ serialPubElement?.PrateciDokument }}</b>
    </p>
    <p>
      Cena: <b>{{ serialPubElement?.Cena }}</b> RSD
    </p>
    <p>
      UDK: <b> {{ serialPubElement?.UDK }}</b>
    </p>
    <p>
      ISBN: <b>{{ serialPubElement?.ISBN }}</b>
    </p>
    <p>
      ISMN: <b>{{ serialPubElement?.ISMN }}</b>
    </p>
    <p>
      Signatura: <b>{{ serialPubElement?.Signatura }}</b>
    </p>
    <p>
      Polica: <b>{{ serialPubElement?.Polica }}</b>
    </p>
    <p>
      Link Cobiss:
      <a href="{{ serialPubElement?.LinkPublikacije }}" target="_blank"
        >Otvori link</a
      >
    </p>
    <p>
      Sadržaj: {{ serialPubElement?.Sadrzaj.toString().substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(serialPubElement?.Sadrzaj)"
        *ngIf="serialPubElement.Sadrzaj.toString().length > 20"
        >... [više]
      </b>
    </p>
    <p>
      {{ serialPubElement?.Napomena.substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(serialPubElement?.Napomena)"
        *ngIf="serialPubElement.Napomena.length > 20"
        >... [više]
      </b>
    </p>

    <p style="font-size: larger">
      Broj svezaka: <b>{{ serialPubElement?.invStatusi.length }}</b>
    </p>
  </mat-card-content>
  <mat-card-actions style="text-align: center">
    <button
      *ngIf="
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
      mat-button
      (click)="onOpenEditDialog(serialPubElement.$key)"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-button (click)="onOpenSerialPubRent(serialPubElement.$key)">
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
