<div style="display: flex; flex-wrap: wrap">
  <div>
    <h2 style="text-align: center">Korisnička kartica</h2>
    <div class="card" *ngIf="user">
      <img
        *ngIf="userObject?.imageUrl"
        src="{{ userObject?.imageUrl }}"
        alt="John"
        style="width: 100%"
      />

      <div
        *ngIf="
          this.sharedClass.oneImagedownloadUrl.length == 0 &&
          !userObject?.imageUrl
        "
        style="margin-right: 2%"
      >
        <ngx-dropzone (change)="uploadImage($event)">
          <ngx-dropzone-label
            >Dodajte fotografiju ili izmenite postojeću</ngx-dropzone-label
          >
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
      <h1>{{ userObject?.firstname }} {{ userObject?.lastname }}</h1>
      <p class="title">{{ userObject?.role }}</p>
      <p>{{ userObject?.School.Naziv }}</p>
      <div style="margin: 24px 0">
        <a href="#"><i class="fa fa-dribbble"></i></a>
        <a href="#"><i class="fa fa-twitter"></i></a>
        <a href="#"><i class="fa fa-linkedin"></i></a>
        <a href="#"><i class="fa fa-facebook"></i></a>

        <button
          mat-stroked-button
          *ngIf="user"
          (click)="resetPassword()"
          style="margin-top: 3%"
        >
          <mat-icon>screen_lock_landscape</mat-icon> Resetuj lozinku
        </button>
      </div>
      <p>
        <button
          *ngIf="userObject?.imageUrl"
          mat-stroked-button
          (click)="deletePhoto()"
          style="margin: 0 auto"
        >
          <mat-icon>delete</mat-icon>Fotografija
        </button>
      </p>
    </div>
  </div>
  <div>
    <mat-tab-group>
      <mat-tab label="Serijske publikacije">
        <app-serial-pub-view></app-serial-pub-view>
      </mat-tab>
      <mat-tab label="Monografske publikacije">
        <app-mono-pub-view></app-mono-pub-view>
      </mat-tab>
      <mat-tab label="Muzikalije">
        <app-musical-pub-view></app-musical-pub-view>
      </mat-tab>
      <mat-tab label="Zvučna građa">
        <app-sound-pub-view></app-sound-pub-view>
      </mat-tab>
      <!-- Samo za Super admina -->

      <mat-tab label="Promeni školu" *ngIf="userObject?.role === 'SuperAdmin'">
        <form class="example-form" *ngIf="userObject?.role === 'SuperAdmin'">
          <mat-form-field
            style="width: 100% !important"
            class="example-full-width"
          >
            <input
              type="text"
              placeholder="Pretraži školu"
              aria-label="Number"
              matInput
              #Skola
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <div style="text-align: center">
          <button
            style="width: 50%"
            mat-stroked-button
            (click)="changeTheSchoolOfSuperAdmin()"
          >
            Izmeni
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
