/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-view-configuration.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "./print-view-configuration.component";
import * as i13 from "../../musical-notes.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@angular/router";
var styles_PrintViewConfigurationComponent = [i0.styles];
var RenderType_PrintViewConfigurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintViewConfigurationComponent, data: {} });
export { RenderType_PrintViewConfigurationComponent as RenderType_PrintViewConfigurationComponent };
function View_PrintViewConfigurationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Serijske publikacije\n"]))], null, null); }
function View_PrintViewConfigurationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Monografska publikacija\n"]))], null, null); }
function View_PrintViewConfigurationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkedChanged($event, _v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatCheckbox]), i1.ɵdid(3, 8568832, null, 0, i4.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i5.FocusMonitor, i1.NgZone, [8, null], [2, i4.MAT_CHECKBOX_CLICK_ACTION], [2, i6.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_7 = _v.context.$implicit.selected; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 3).indeterminate; var currVal_3 = i1.ɵnov(_v, 3).checked; var currVal_4 = i1.ɵnov(_v, 3).disabled; var currVal_5 = (i1.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_8); }); }
export function View_PrintViewConfigurationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintViewConfigurationComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintViewConfigurationComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintViewConfigurationComponent_3)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["mat-stroked-button", ""], ["style", "margin-left: 30%"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenPrintConfiguration() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i9.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["print"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedCategory === "serijska"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.selectedCategory === "monografska"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.printProperties; _ck(_v, 5, 0, currVal_2); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 10).inline; var currVal_6 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
export function View_PrintViewConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-view-configuration", [], null, null, null, View_PrintViewConfigurationComponent_0, RenderType_PrintViewConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i12.PrintViewConfigurationComponent, [i13.MusicalNotesService, i14.MatDialog, [2, i14.MAT_DIALOG_DATA], i15.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintViewConfigurationComponentNgFactory = i1.ɵccf("app-print-view-configuration", i12.PrintViewConfigurationComponent, View_PrintViewConfigurationComponent_Host_0, {}, {}, []);
export { PrintViewConfigurationComponentNgFactory as PrintViewConfigurationComponentNgFactory };
