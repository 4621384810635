<form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
  <!-- Datum -->
  <mat-form-field appearance="fill" style="width: 100% !important">
    <mat-label>Datum inventarisanja</mat-label>
    <input formControlName="Datum" matInput [matDatepicker]="picker" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div fxLayout="row" class="transcription">
    <!-- Autor -->
    <div style="width: 100% !important; margin-right: 1%">
      <app-autocomplete
        [options]="authors"
        [editValue]="monoPubElement?.Autor"
        [placeHolder]="'Autor ime i prezime'"
        #Autor
      ></app-autocomplete>
    </div>

    <!-- Transkripcija  -->
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          style="width: 400px !important"
          formControlName="AutorTranskripcija"
          type="text"
          matInput
          placeholder="Transkripcija autora"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" class="transcription">
    <!-- Ucesnici u realizaciji -->
    <div style="width: 100% !important; margin-right: 1%">
      <app-autocomplete
        [options]="participants"
        [editValue]="monoPubElement?.Ucesnici"
        [placeHolder]="'Učesnici u realizaciji'"
        #Ucesnici
      ></app-autocomplete>
    </div>

    <!-- Transkripcija  -->
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          style="width: 400px !important"
          formControlName="UcesniciTranskripcija"
          type="text"
          matInput
          placeholder="Transkripcija učesnika u realizaciji"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" class="transcription">
    <!-- Naslov -->
    <div style="width: 100% !important; margin-right: 1%">
      <app-autocomplete
        [options]="titles"
        [editValue]="monoPubElement?.Naslov"
        [placeHolder]="'Naslov'"
        #Naslov
      ></app-autocomplete>
    </div>
    <!-- Transkripcija  -->
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          style="width: 400px !important"
          formControlName="NaslovTranskripcija"
          type="text"
          matInput
          placeholder="Transkripcija naslova"
        />
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" class="transcription">
    <!-- Podnaslov -->
    <mat-form-field style="width: 100% !important">
      <input
        style="width: 400px !important"
        formControlName="Podnaslov"
        type="text"
        matInput
        placeholder="Podnaslov"
      />
    </mat-form-field>
    <!-- Transkripcija  -->
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          style="width: 400px !important"
          formControlName="PodnaslovTranskripcija"
          type="text"
          matInput
          placeholder="Transkripcija podnaslova"
        />
      </mat-form-field>
    </div>
  </div>

  <!-- Broj sveske, Toma -->
  <mat-form-field style="width: 100% !important">
    <input
      style="width: 400px !important"
      formControlName="BrojSveske"
      type="text"
      matInput
      placeholder="Broj sveske, toma"
      value=""
    />
  </mat-form-field>

  <!-- Mesto izdavanja -->
  <app-autocomplete
    [options]="places"
    [editValue]="monoPubElement?.Mesto"
    [placeHolder]="'Mesto izdavanja'"
    #MestoIzdavanja
  ></app-autocomplete>

  <!-- Izdavac -->
  <app-autocomplete
    [options]="publishers"
    [editValue]="monoPubElement?.Izdavac"
    [placeHolder]="'Izdavač'"
    #Izdavac
  ></app-autocomplete>

  <div fxLayout="row" class="transcription">
    <!-- Godište sveske -->
    <div style="width: 100% !important; margin-right: 1%">
      <app-autocomplete
        [options]="sharedClass.yearsOfProduction"
        [editValue]="monoPubElement?.GodisteSveske"
        [placeHolder]="'Godište sveske'"
        #godisteSveskeAuto
      ></app-autocomplete>
    </div>

    <!-- Godina izdavanja -->
    <div style="width: 100% !important">
      <app-autocomplete
        [options]="sharedClass.yearsOfProduction"
        [editValue]="monoPubElement?.GodinaIzdavanja"
        [placeHolder]="'Godina izdavanja'"
        #godinaIzdavanja
      ></app-autocomplete>
    </div>
  </div>

  <!-- Dimenzije -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="Dimenzije"
          type="text"
          matInput
          placeholder="Dimenzije"
          value=""
        />
      </mat-form-field>
    </div>
  </div>

  <!-- Povez -->
  <app-autocomplete
    [options]="bindings"
    [editValue]="monoPubElement?.Povez"
    [placeHolder]="'Povez'"
    #Povez
  ></app-autocomplete>

  <!-- Nacin nabavke -->
  <app-autocomplete
    [options]="purchaseTypes"
    [editValue]="monoPubElement?.NacinNabavke"
    [placeHolder]="'Način nabavke'"
    #NacinNabavke
    (keyup)="mergeWithFollowingDoc()"
  ></app-autocomplete>

  <!-- Prateci dokument -->
  <mat-form-field style="width: 100% !important">
    <input
      style="width: 400px !important"
      formControlName="PrateciDokument"
      type="text"
      matInput
      placeholder="Prateći dokument"
      #Prateci
    />
  </mat-form-field>

  <!-- Procenjena vrednost -->

  <div fxLayout="row" fxLayoutGap="2%">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="Cena"
          type="number"
          matInput
          placeholder="Cena (RSD)"
          value=""
        />
      </mat-form-field>
    </div>
  </div>

  <!-- UDK -->
  <app-autocomplete
    [options]="udks"
    [editValue]="monoPubElement?.UDK"
    [placeHolder]="'UDK'"
    #UDK
  ></app-autocomplete>

  <!-- ISBN -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="ISBN"
          type="text"
          matInput
          placeholder="ISBN"
          value=""
          #ISBN
        />
      </mat-form-field>
    </div>
  </div>

  <!-- ISMN  -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="ISMN"
          type="text"
          matInput
          placeholder="ISMN"
          value=""
          #ISMN
        />
      </mat-form-field>
    </div>
  </div>

  <!-- Signatura -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="Signatura"
          type="text"
          matInput
          placeholder="Signatura"
          value=""
          #Signatura
        />
      </mat-form-field>
    </div>
  </div>

  <!-- Polica  -->
  <div style="width: 100%">
    <mat-form-field style="width: 100% !important">
      <input
        style="width: 400px !important"
        formControlName="Polica"
        type="text"
        matInput
        placeholder="Polica"
      />
    </mat-form-field>
  </div>

  <!-- Sadržaj -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <textarea
          formControlName="Sadrzaj"
          matInput
          placeholder="Sadržaj"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- Napomena -->

  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <textarea
          formControlName="Napomena"
          matInput
          placeholder="Napomena"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <!-- Link Publikacije -->
  <div fxLayout="row">
    <div style="width: 100% !important">
      <mat-form-field style="width: 100% !important">
        <input
          formControlName="LinkPublikacije"
          type="text"
          matInput
          placeholder="Link publikacije Cobiss"
          value=""
        />
      </mat-form-field>
    </div>
  </div>

  <mat-form-field appearance="fill" style="width: 100% !important" *ngIf="data">
    <mat-label>Promeni publikaciju</mat-label>
    <mat-select
      [(value)]="selectedPub"
      (selectionChange)="changePublicationCategory($event.value)"
    >
      <mat-option *ngFor="let pub of pubs" [value]="pub.name">
        {{ pub?.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Inventarski brojevi i statusi publikacija -->
  <div formArrayName="invStatusi">
    <div
      *ngFor="let invStItem of invStatusi.controls; let i = index"
      [formGroupName]="i"
    >
      <div fxLayout="row" fxLayoutGap="5px">
        <p>{{ i + 1 }}.</p>

        <div style="width: 100% !important; pointer-events: none">
          <mat-form-field style="width: 100% !important">
            <input
              formControlName="invBrojevi"
              type="text"
              matInput
              placeholder="Inventarni broj"
            />
          </mat-form-field>
        </div>
        <div style="width: 100% !important">
          <mat-form-field style="width: 100% !important">
            <mat-label>Status</mat-label>
            <mat-select formControlName="statusi">
              <mat-option value="Slobodno"> Slobodno </mat-option>
              <mat-option value="Izdato"> Izdato </mat-option>
              <mat-option value="Nije za izdavanje">
                Nije za izdavanje
              </mat-option>
              <mat-option value="Otpisano"> Otpisano </mat-option>
              <mat-option value="Rashodovano"> Rashodovano </mat-option>
              <mat-option value="Izgubljeno"> Izgubljeno </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          mat-stroked-button
          (click)="deleteInvStatusi(i)"
          *ngIf="i == invStatusi.controls.length - 1 && i > 0"
          style="color: red"
        >
          <mat-icon>remove</mat-icon>
        </button>

        <button
          mat-stroked-button
          (click)="addNewInvStatusi()"
          *ngIf="i == invStatusi.controls.length - 1"
          style="color: green"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- Fotografije upload -->
  <div>
    <div
      *ngFor="let imageUrl of sharedClass.oneImagedownloadUrl"
      class="photo-section"
    >
      <div>
        <img style="width: 100%" src="{{ imageUrl }}" />
      </div>
      <div>
        <button mat-stroked-button (click)="onDeletePhoto(imageUrl)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div
      style="flex: 1"
      *ngIf="this.sharedClass.oneImagedownloadUrl.length == 0"
    >
      <ngx-dropzone (change)="uploadImage($event)">
        <ngx-dropzone-label>Slika naslovne strane</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
  </div>
  <br />

  <button type="submit" mat-stroked-button [disabled]="!myForm.valid">
    Dodaj
  </button>
</form>
