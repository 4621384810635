<div id="print-section-revers">
  <div style="text-align: center">
    <h3>REVERS</h3>
    <p>o izdavanju serijske publikacije</p>
  </div>
  <div>
    <p>Ovim reversom se potvrđuje da je <b>korisnik</b> nototeke:</p>
    <p>
      <b> Ime i prezime:</b> {{ selectedSerialPub?.User?.firstname }}
      {{ selectedSerialPub?.User?.lastname }}
    </p>
    <p>
      <b> Broj lične karte:</b> {{ selectedSerialPub?.User?.brojLicneKarte }}
    </p>
    <p><b>Adresa: </b>{{ selectedSerialPub?.User?.adresa }}</p>
    <mat-divider></mat-divider>
    <p>
      Iznajmio/la serijske publikacije:
      {{ selectedSerialPub?.User?.firstname }}
      {{ selectedSerialPub?.User?.lastname }} | <b>Datum iznajmljivanja: </b
      >{{ selectedSerialPub?.DateOfRenting }}
    </p>
    <table id="rentsSerial">
      <tr>
        <th>R. br.</th>
        <th>Naslov</th>
        <th>Inventarski broj</th>
        <th>Opcije</th>
      </tr>
      <tr *ngFor="let item of listOfRentedSerialPubs; let i = index">
        <td>{{ i + 1 }}</td>
        <td>
          {{ selectedSerialPub?.SerialPub?.Naslov }} -
          {{ selectedSerialPub?.SerialPub?.Autor }}
        </td>
        <td>{{ item?.data?.invBrojevi }}</td>
        <td>
          <p
            style="text-align: center; color: red"
            *ngIf="fromProfile && item?.data?.statusi === 'Slobodno'"
          >
            Kod tebe, još uvek
          </p>
          <button
            mat-stroked-button
            *ngIf="
              item?.data?.statusi === 'Slobodno' &&
              !fromProfile &&
              !printSelected
            "
            style="width: 100% !important"
            (click)="onReturnTheSelectedRent(item.key)"
          >
            Razduži
          </button>
          <p
            *ngIf="item?.data?.statusi !== 'Slobodno'"
            style="text-align: center"
          >
            Razduženo
            {{ item?.data?.DateOfReturning }}
            -
            {{ item?.data?.TimeOfReturning }}
          </p>
        </td>
      </tr>
    </table>
    <br />
    <mat-divider></mat-divider>
    <div>
      <div style="float: left">
        <p>Za školu:</p>
        <p>
          <b>{{ userObject?.firstname }} {{ userObject?.lastname }} </b>
        </p>
        <p>_____________________________</p>
      </div>
      <div style="float: right">
        <p>Za korisnika:</p>
        <p>
          <b
            >{{ selectedSerialPub?.User?.firstname }}
            {{ selectedSerialPub?.User?.lastname }}
          </b>
        </p>
        <p>_____________________________</p>
      </div>
    </div>
  </div>
  <div style="clear: both"></div>
</div>
<div style="text-align: center; margin: 2%">
  <button
    (click)="printSelected = true"
    printSectionId="print-section-revers"
    printTitle="Revers, serijska publikacija"
    [useExistingCss]="true"
    ngxPrint
    mat-raised-button
  >
    <mat-icon>print</mat-icon>
  </button>
</div>
