import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../user.service";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { RegistrationComponent } from "src/app/auth/registration/registration.component";
import { AuthService } from "src/app/auth/auth.service";
import { UserDialogComponent } from "../user-dialog/user-dialog.component";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
  user: firebase.User;
  userObject: User = {};

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          this.loadAllUsers();
        });
      }
    });
  }

  loadAllUsers() {
    this.userService.getAllUsers().subscribe((list) => {
      this.usersTable = list
        .map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        })
        .filter((item: any) => {
          if (this.userObject.role === "SuperAdmin") {
            return item;
          } else if (this.userObject.role === "AdminSkole") {
            return (
              item.School.key === this.userObject.School.key ||
              item.role === "Eksterni korisnik"
            );
          }
        });
      this.listData = new MatTableDataSource(this.usersTable);
      this.listData.sort = this.matSort;
      this.listData.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  usersTable: User[];
  listData: MatTableDataSource<any>;
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = [
    "email",
    "ImePrezime",
    "Rola",
    "Aktivan",
    "Opcije",
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  i = 0;

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  onOpenUserRegistrationDialog() {
    this.dialog.open(RegistrationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "500px",
      height: "600px",

      data: {
        // instrumentKey: key
      },
    });
  }
  onOpenUserDialog(key) {
    this.dialog.open(UserDialogComponent, {
      disableClose: false,
      autoFocus: true,
      minWidth: "",
      height: "800px",

      data: {
        uid: key,
        logedUserRole: this.userObject.role,
      },
    });
  }

  onDeleteUser(uid) {
    if (confirm("Da li ste sigurni da želite da obrišete korisnika?"))
      this.userService.deleteUserFromRealTimeDatabase(uid);
  }

  onDisableUser(uid) {
    if (confirm("Da li ste sigurni da želite da deaktivirate korisnika?"))
      this.userService.disableUser(uid);
  }

  onEnableUser(uid) {
    if (confirm("Da li ste sigurni da želite da aktivirate korisnika?"))
      this.userService.enableUser(uid);
  }
}
