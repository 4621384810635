import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "angularfire2/database";

@Injectable({
  providedIn: "root",
})
export class SerialPubInputService {
  serialPub: AngularFireList<any>;
  serialPubElement: AngularFireObject<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.serialPub = firebase.list("serijskePublikacije");
  }

  getSerialPubElement(schoolName: string, key: string) {
    this.serialPubElement = this.firebase.object(
      schoolName + "/serijskePublikacije/" + key
    );
    return this.serialPubElement.valueChanges();
  }

  getSerialPubs(schoolName) {
    this.serialPub = this.firebase.list(schoolName + "/serijskePublikacije");
    return this.serialPub.snapshotChanges();
  }

  insertSerialPub(schoolName, formData) {
    this.serialPub = this.firebase.list(schoolName + "/serijskePublikacije");
    this.serialPub.push({
      Tip: "Serijska",
      Datum: formData.Datum.toString(),
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      UDK: formData.UDK,
      GodinaIzdavanja: formData.GodinaIzdavanja,
      GodisteSveske: formData.GodisteSveske,
      Izdavac: formData.Izdavac,
      Dimenzije: formData.Dimenzije,
      NacinNabavke: formData.NacinNabavke,
      PrateciDokument: formData.PrateciDokument,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Signatura: formData.Signatura,
      Sadrzaj: formData.Sadrzaj,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Povez: formData.Povez,
    });
  }

  updateSerialPub(schoolName, key, formData) {
    this.firebase.object(schoolName + "/serijskePublikacije/" + key).update({
      Tip: "Serijska",
      Datum: formData.Datum.toString(),
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      UDK: formData.UDK,
      GodinaIzdavanja: formData.GodinaIzdavanja,
      Izdavac: formData.Izdavac,
      GodisteSveske: formData.GodisteSveske,
      Dimenzije: formData.Dimenzije,
      NacinNabavke: formData.NacinNabavke,
      PrateciDokument: formData.PrateciDokument,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Signatura: formData.Signatura,
      Sadrzaj: formData.Sadrzaj,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Povez: formData.Povez,
    });
  }

  deleteSerialPub(schoolName, key) {
    this.firebase.database
      .ref(schoolName + "/serijskePublikacije/" + key)
      .remove();
  }
}
