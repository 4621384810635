<div id="print-section">
  <p style="text-align: center">
    {{ authService.logedUser?.School?.Naziv }} |

    <ng-container *ngIf="category === 'serijska'">
      <b>Serijske publikacije</b> | Izdavanja
    </ng-container>
    <ng-container *ngIf="category === 'monografska'">
      <b>Monografske publikacije</b> | Izdavanja
    </ng-container>
    <ng-container *ngIf="category === 'muzikalije'">
      <b>Muzikalije</b> | Izdavanja
    </ng-container>
    <ng-container *ngIf="category === 'zvucnaGradja'">
      <b>Zvučna građa</b> | Izdavanja
    </ng-container>
  </p>
  <table id="customers" style="width: 90%; margin: 0 auto">
    <tr>
      <th>Red. br.</th>
      <ng-container *ngFor="let prop of props">
        <th *ngIf="prop.name === 'Korisnik' && prop.selected">Korisnik</th>
        <th *ngIf="prop.name === 'Email' && prop.selected">Email</th>
        <th *ngIf="prop.name === 'Naslov' && prop.selected">Naslov</th>
        <th *ngIf="prop.name === 'Datum izdavanja' && prop.selected">
          Datum izdavanja
        </th>
        <th *ngIf="prop.name === 'Vreme izdavanja' && prop.selected">
          Vreme izdavanja
        </th>
      </ng-container>
    </tr>

    <!-- Serijske publikacije -->
    <ng-container *ngIf="category === 'serijska'">
      <ng-container *ngFor="let item of pubs; let i = index">
        <tr style="background-color: #e3e3e3">
          <td>{{ i + 1 }}.</td>
          <ng-container *ngFor="let prop of props">
            <td *ngIf="prop.name === 'Korisnik' && prop.selected">
              {{ item?.User?.firstname }} {{ item?.User?.lastname }}
            </td>
            <td *ngIf="prop.name === 'Email' && prop.selected">
              {{ item?.User?.email }}
            </td>
            <td *ngIf="prop.name === 'Naslov' && prop.selected">
              {{ item?.SerialPub?.Naslov }}
            </td>
            <td *ngIf="prop.name === 'Datum izdavanja' && prop.selected">
              {{ item?.DateOfRenting }}
            </td>
            <td *ngIf="prop.name === 'Vreme izdavanja' && prop.selected">
              {{ item?.TimeOfRenting }}
            </td>
          </ng-container>
        </tr>

        <tr
          *ngFor="
            let inv of item.SerialPub.invStatusi | keyvalue;
            let j = index
          "
          style="font-size: smaller"
        >
          <td>{{ j + 1 }}. Ugovor</td>
          <td>Inv. broj - {{ inv?.value?.invBrojevi }}</td>
          <td *ngIf="inv.value.statusi !== 'Slobodno'">
            Status - {{ inv?.value?.statusi }}
          </td>
          <td *ngIf="inv.value.statusi === 'Slobodno'">Status - Zauzet</td>
          <td *ngIf="inv.value.DateOfReturning">
            Datum vraćanja - {{ inv?.value?.DateOfReturning }}
          </td>
          <td *ngIf="inv.value.TimeOfReturning">
            Vreme vraćanja - {{ inv?.value?.TimeOfReturning }}
          </td>
        </tr>
      </ng-container>
    </ng-container>

    <!-- Monografske publikacije -->
    <ng-container *ngIf="category === 'monografska'">
      <ng-container *ngFor="let item of pubs; let i = index">
        <tr style="background-color: #e3e3e3">
          <td>{{ i + 1 }}.</td>
          <ng-container *ngFor="let prop of props">
            <td *ngIf="prop.name === 'Korisnik' && prop.selected">
              {{ item?.User?.firstname }} {{ item?.User?.lastname }}
            </td>
            <td *ngIf="prop.name === 'Email' && prop.selected">
              {{ item?.User?.email }}
            </td>
            <td *ngIf="prop.name === 'Naslov' && prop.selected">
              {{ item?.MonoPub?.Naslov }}
            </td>
            <td *ngIf="prop.name === 'Datum izdavanja' && prop.selected">
              {{ item?.DateOfRenting }}
            </td>
            <td *ngIf="prop.name === 'Vreme izdavanja' && prop.selected">
              {{ item?.TimeOfRenting }}
            </td>
          </ng-container>
        </tr>

        <tr
          *ngFor="let inv of item.MonoPub.invStatusi | keyvalue; let j = index"
          style="font-size: smaller"
        >
          <td>{{ j + 1 }}. Ugovor</td>
          <td>Inv. broj - {{ inv?.value?.invBrojevi }}</td>
          <td *ngIf="inv.value.statusi !== 'Slobodno'">
            Status - {{ inv?.value?.statusi }}
          </td>
          <td *ngIf="inv.value.statusi === 'Slobodno'">Status - Zauzet</td>
          <td *ngIf="inv.value.DateOfReturning">
            Datum vraćanja - {{ inv?.value?.DateOfReturning }}
          </td>
          <td *ngIf="inv.value.TimeOfReturning">
            Vreme vraćanja - {{ inv?.value?.TimeOfReturning }}
          </td>
        </tr>
      </ng-container>
    </ng-container>

    <!-- Muzikalije -->

    <ng-container *ngIf="category === 'muzikalije'">
      <ng-container *ngFor="let item of pubs; let i = index">
        <tr style="background-color: #e3e3e3">
          <td>{{ i + 1 }}.</td>
          <ng-container *ngFor="let prop of props">
            <td *ngIf="prop.name === 'Korisnik' && prop.selected">
              {{ item?.User?.firstname }} {{ item?.User?.lastname }}
            </td>
            <td *ngIf="prop.name === 'Email' && prop.selected">
              {{ item?.User?.email }}
            </td>
            <td *ngIf="prop.name === 'Naslov' && prop.selected">
              {{ item?.MusicalPub?.Naslov }}
            </td>
            <td *ngIf="prop.name === 'Datum izdavanja' && prop.selected">
              {{ item?.DateOfRenting }}
            </td>
            <td *ngIf="prop.name === 'Vreme izdavanja' && prop.selected">
              {{ item?.TimeOfRenting }}
            </td>
          </ng-container>
        </tr>

        <tr
          *ngFor="
            let inv of item.MusicalPub.invStatusi | keyvalue;
            let j = index
          "
          style="font-size: smaller"
        >
          <td>{{ j + 1 }}. Ugovor</td>
          <td>Inv. broj - {{ inv?.value?.invBrojevi }}</td>
          <td *ngIf="inv.value.statusi !== 'Slobodno'">
            Status - {{ inv?.value?.statusi }}
          </td>
          <td *ngIf="inv.value.statusi === 'Slobodno'">Status - Zauzet</td>
          <td *ngIf="inv.value.DateOfReturning">
            Datum vraćanja - {{ inv?.value?.DateOfReturning }}
          </td>
          <td *ngIf="inv.value.TimeOfReturning">
            Vreme vraćanja - {{ inv?.value?.TimeOfReturning }}
          </td>
        </tr>
      </ng-container>
    </ng-container>

    <!-- Zvucna gradja -->

    <ng-container *ngIf="category === 'zvucnaGradja'">
      <ng-container *ngFor="let item of pubs; let i = index">
        <tr style="background-color: #e3e3e3">
          <td>{{ i + 1 }}.</td>
          <ng-container *ngFor="let prop of props">
            <td *ngIf="prop.name === 'Korisnik' && prop.selected">
              {{ item?.User?.firstname }} {{ item?.User?.lastname }}
            </td>
            <td *ngIf="prop.name === 'Email' && prop.selected">
              {{ item?.User?.email }}
            </td>
            <td *ngIf="prop.name === 'Naslov' && prop.selected">
              {{ item?.SoundPub?.Naslov }}
            </td>
            <td *ngIf="prop.name === 'Datum izdavanja' && prop.selected">
              {{ item?.DateOfRenting }}
            </td>
            <td *ngIf="prop.name === 'Vreme izdavanja' && prop.selected">
              {{ item?.TimeOfRenting }}
            </td>
          </ng-container>
        </tr>

        <tr
          *ngFor="let inv of item.SoundPub.invStatusi | keyvalue; let j = index"
          style="font-size: smaller"
        >
          <td>{{ j + 1 }}. Ugovor</td>
          <td>Inv. broj - {{ inv?.value?.invBrojevi }}</td>
          <td *ngIf="inv.value.statusi !== 'Slobodno'">
            Status - {{ inv?.value?.statusi }}
          </td>
          <td *ngIf="inv.value.statusi === 'Slobodno'">Status - Zauzet</td>
          <td *ngIf="inv.value.DateOfReturning">
            Datum vraćanja - {{ inv?.value?.DateOfReturning }}
          </td>
          <td *ngIf="inv.value.TimeOfReturning">
            Vreme vraćanja - {{ inv?.value?.TimeOfReturning }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>

<div style="text-align: center; margin: 2%">
  <button
    printSectionId="print-section"
    [useExistingCss]="true"
    ngxPrint
    mat-raised-button
  >
    <mat-icon>print</mat-icon>
  </button>
</div>
