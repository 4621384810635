import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "angularfire2/database";
import { SharedClass } from "../shared/shared-functions";
@Injectable({
  providedIn: "root",
})
export class MusicalNotesService {
  notesAuthorsList: AngularFireList<any>;
  placesList: AngularFireList<any>;
  purchaseTypeList: AngularFireList<any>;
  udkList: AngularFireList<any>;
  publishers: AngularFireList<any>;
  docCategories: AngularFireList<any>;
  soundMaterials: AngularFireList<any>;
  purchaseTypes: AngularFireList<any>;
  titles: AngularFireList<any>;
  bindings: AngularFireList<any>;
  participants: AngularFireList<any>;

  serialPubRents: AngularFireList<any>;
  monoPubRents: AngularFireList<any>;
  sPRents: AngularFireList<any>;
  mPRents: AngularFireList<any>;
  musicalPubRents: AngularFireList<any>;
  soundPubRents: AngularFireList<any>;

  //SINGLE OBJECTS
  monoPubElement: AngularFireObject<any>;
  musicalPubElement: AngularFireObject<any>;
  soundPubElement: AngularFireObject<any>;

  //PASSING A DATA FROM COMPONENTS VARIABLES
  fromProfile: any = false;
  dataForPrintAllPubs: any = {};

  dataForPubViewPrint: any = {};
  selectedPropsForPubView: any = {};
  categoryForPrint: any = {};
  expandedBookAccording: boolean = false;
  expandedReportAccording: boolean = false;

  constructor(
    private firebase: AngularFireDatabase,
    private sharedClass: SharedClass,
    public db: AngularFireDatabase
  ) {
    this.notesAuthorsList = firebase.list("autori");
    this.placesList = firebase.list("Mesta");
    this.udkList = firebase.list("UDK");
    this.purchaseTypeList = firebase.list("Mesta");
    this.publishers = firebase.list("izdavaci");
    this.soundMaterials = firebase.list("zvucneGradje");
    this.purchaseTypes = firebase.list("naciniNabavke");
    this.titles = firebase.list("naslovi");
    this.bindings = firebase.list("povezi");
    this.participants = firebase.list("ucesnici");
  }
  changeExpandState() {
    this.expandedBookAccording = !this.expandedBookAccording;
  }

  changeReportExpandState() {
    this.expandedReportAccording = !this.expandedReportAccording;
  }

  /* GET FROM FIREBASE */
  getNotesAuthors() {
    this.notesAuthorsList = this.firebase.list("autori");
    return this.notesAuthorsList.snapshotChanges();
  }

  getPlaces() {
    this.placesList = this.firebase.list("Mesta");
    return this.placesList.snapshotChanges();
  }

  getPurchaseTypes() {
    this.purchaseTypeList = this.firebase.list("naciniNabavke");
    return this.purchaseTypeList.snapshotChanges();
  }

  getUdks() {
    this.udkList = this.firebase.list("UDK");
    return this.udkList.snapshotChanges();
  }

  getPublishers() {
    this.publishers = this.firebase.list("izdavaci");
    return this.publishers.snapshotChanges();
  }

  getBindings() {
    this.bindings = this.firebase.list("povezi");
    return this.bindings.snapshotChanges();
  }

  getTitles() {
    this.titles = this.firebase.list("naslovi");
    return this.titles.snapshotChanges();
  }

  getParticipants() {
    this.participants = this.firebase.list("ucesnici");
    return this.participants.snapshotChanges();
  }

  getSerialPubRents(schoolName) {
    this.sPRents = this.firebase.list(
      schoolName + "/renting/serijskePublikacije"
    );
    return this.sPRents.snapshotChanges();
  }

  getMonoPubRents(schoolName) {
    this.mPRents = this.firebase.list(
      schoolName + "/renting/monografskePublikacije"
    );
    return this.mPRents.snapshotChanges();
  }

  getMusicalPubRents(schoolName) {
    this.musicalPubRents = this.firebase.list(
      schoolName + "/renting/muzikalije"
    );
    return this.musicalPubRents.snapshotChanges();
  }

  getSoundPubRents(schoolName) {
    this.soundPubRents = this.firebase.list(
      schoolName + "/renting/zvucnaGradja"
    );
    return this.soundPubRents.snapshotChanges();
  }

  getSoundPubs(schoolName) {
    this.soundMaterials = this.firebase.list(schoolName + "/zvucnaGradja");
    return this.soundMaterials.snapshotChanges();
  }

  //Single element

  getMonoPubElement(schoolName: string, key: string) {
    this.monoPubElement = this.firebase.object(
      schoolName + "/monografskePublikacije/" + key
    );
    return this.monoPubElement.valueChanges();
  }

  getMusicalPubElement(schoolName: string, key: string) {
    this.musicalPubElement = this.firebase.object(
      schoolName + "/muzikalije/" + key
    );
    return this.musicalPubElement.valueChanges();
  }

  getSoundPubElement(schoolName: string, key: string) {
    this.musicalPubElement = this.firebase.object(
      schoolName + "/zvucnaGradja/" + key
    );
    return this.musicalPubElement.valueChanges();
  }

  insertAuthor(naziv) {
    this.notesAuthorsList.push({
      Naziv: naziv,
    });
  }

  insertPlace(naziv) {
    this.placesList.push({
      Naziv: naziv,
    });
  }

  insertUDK(naziv) {
    this.udkList.push({
      Naziv: naziv,
    });
  }
  insertPublisher(naziv) {
    this.publishers.push({
      Naziv: naziv,
    });
  }

  insertPurchaseType(naziv) {
    this.purchaseTypeList.push({
      Naziv: naziv,
    });
  }

  insertTitle(naziv) {
    this.titles.push({
      Naziv: naziv,
    });
  }

  insertBinding(naziv) {
    this.bindings.push({
      Naziv: naziv,
    });
  }

  insertParticipant(naziv) {
    this.participants.push({
      Naziv: naziv,
    });
  }
  /* RENTING INSERT AND UPDATE */
  insertRentSerialPub(schoolName, data) {
    this.serialPubRents = this.firebase.list(
      schoolName + "/renting/serijskePublikacije"
    );

    this.serialPubRents.push({
      User: data.User,
      SerialPub: data.SerialPub,
      serialPubKey: data.serialPubKey,
      ListaRentovanja: data.selectedSerialPubs,
      DateOfRenting: this.sharedClass.currentDateFormatddmmyyyy(),
      TimeOfRenting: this.sharedClass.currentTime(),
    });
  }

  insertRentMonoPub(schoolName, data) {
    this.monoPubRents = this.firebase.list(
      schoolName + "/renting/monografskePublikacije"
    );

    this.monoPubRents.push({
      User: data.User,
      MonoPub: data.MonoPub,
      monoPubKey: data.monoPubKey,
      ListaRentovanja: data.selectedMonoPubs,
      DateOfRenting: this.sharedClass.currentDateFormatddmmyyyy(),
      TimeOfRenting: this.sharedClass.currentTime(),
    });
  }

  insertRentMusicalPub(schoolName, data) {
    this.musicalPubRents = this.firebase.list(
      schoolName + "/renting/muzikalije"
    );

    this.musicalPubRents.push({
      User: data.User,
      MusicalPub: data.MusicalPub,
      musicalPubKey: data.musicalPubKey,
      ListaRentovanja: data.selectedMusicalPubs,
      DateOfRenting: this.sharedClass.currentDateFormatddmmyyyy(),
      TimeOfRenting: this.sharedClass.currentTime(),
    });
  }

  insertRentSoundPub(schoolName, data) {
    this.soundPubRents = this.firebase.list(
      schoolName + "/renting/zvucnaGradja"
    );

    this.soundPubRents.push({
      User: data.User,
      SoundPub: data.SoundPub,
      soundPubKey: data.soundPubKey,
      ListaRentovanja: data.selectedSoundPubs,
      DateOfRenting: this.sharedClass.currentDateFormatddmmyyyy(),
      TimeOfRenting: this.sharedClass.currentTime(),
    });
  }

  updateStatusesAfterRenting(schoolName, key, index) {
    this.firebase
      .object(
        schoolName + "/serijskePublikacije/" + key + "/invStatusi/" + index
      )
      .update({
        statusi: "Izdato",
      });
  }

  updateMonoPubStatusesAfterRenting(schoolName, key, index) {
    this.firebase
      .object(
        schoolName + "/monografskePublikacije/" + key + "/invStatusi/" + index
      )
      .update({
        statusi: "Izdato",
      });
  }

  updateMusicalPubStatusesAfterRenting(schoolName, key, index) {
    this.firebase
      .object(schoolName + "/muzikalije/" + key + "/invStatusi/" + index)
      .update({
        statusi: "Izdato",
      });
  }

  updateSoundPubStatusesAfterRenting(schoolName, key, index) {
    this.firebase
      .object(schoolName + "/zvucnaGradja/" + key + "/invStatusi/" + index)
      .update({
        statusi: "Izdato",
      });
  }

  /* UPDATE IN FIREBASE */

  updateTheStatusOfReturnedSerialPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName + "/serijskePublikacije/" + key + "/invStatusi/" + index
      )
      .update({
        statusi: "Slobodno",
      });
  }

  updateTheStatusOnRentSerialPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName +
          "/renting/serijskePublikacije/" +
          key +
          "/SerialPub/invStatusi/" +
          index
      )
      .update({
        statusi: "Vraćeno",
        TimeOfReturning: this.sharedClass.currentTime(),
        DateOfReturning: this.sharedClass.currentDateFormatddmmyyyy(),
      });
  }

  updateTheStatusOfReturnedMonoPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName + "/monografskePublikacije/" + key + "/invStatusi/" + index
      )
      .update({
        statusi: "Slobodno",
      });
  }

  updateTheStatusOnRentMonoPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName +
          "/renting/monografskePublikacije/" +
          key +
          "/MonoPub/invStatusi/" +
          index
      )
      .update({
        statusi: "Vraćeno",
        TimeOfReturning: this.sharedClass.currentTime(),
        DateOfReturning: this.sharedClass.currentDateFormatddmmyyyy(),
      });
  }

  updateTheStatusOfReturnedMusicalPub(schoolName, key, index) {
    this.firebase
      .object(schoolName + "/muzikalije/" + key + "/invStatusi/" + index)
      .update({
        statusi: "Slobodno",
      });
  }

  updateTheStatusOnRentMusicalPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName +
          "/renting/muzikalije/" +
          key +
          "/MusicalPub/invStatusi/" +
          index
      )
      .update({
        statusi: "Vraćeno",
        TimeOfReturning: this.sharedClass.currentTime(),
        DateOfReturning: this.sharedClass.currentDateFormatddmmyyyy(),
      });
  }

  updateTheStatusOfReturnedSoundPub(schoolName, key, index) {
    this.firebase
      .object(schoolName + "/zvucnaGradja/" + key + "/invStatusi/" + index)
      .update({
        statusi: "Slobodno",
      });
  }

  updateTheStatusOnRentSoundPub(schoolName, key, index) {
    this.firebase
      .object(
        schoolName +
          "/renting/zvucnaGradja/" +
          key +
          "/SoundPub/invStatusi/" +
          index
      )
      .update({
        statusi: "Vraćeno",
        TimeOfReturning: this.sharedClass.currentTime(),
        DateOfReturning: this.sharedClass.currentDateFormatddmmyyyy(),
      });
  }

  /* OTHER FUNCTIONS */
}
