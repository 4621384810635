import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";

@Injectable({
  providedIn: "root",
})
export class GuideService {
  linksList: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.linksList = firebase.list("linkovi");
  }

  getLinks() {
    this.linksList = this.firebase.list("linkovi");
    return this.linksList.snapshotChanges();
  }

  insertLink(url, desc) {
    this.linksList = this.firebase.list("/linkovi");

    this.linksList.push({
      URL: url,
      Description: desc,
    });
  }

  deleteLink(key) {
    this.linksList.remove(key);
  }
}
