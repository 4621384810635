import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MusicalNotesService } from "../../musical-notes.service";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { UserService } from "src/app/user/user.service";
import { AuthService } from "src/app/auth/auth.service";
import { SerialPubInputService } from "../../musical-notes-inputs/input-services/serial-pub-input.service";

@Component({
  selector: "app-serial-pub-rent",
  templateUrl: "./serial-pub-rent.component.html",
  styleUrls: ["./serial-pub-rent.component.css"],
})
export class SerialPubRentComponent implements OnInit {
  serialPubElement: any;
  leftRentingCounter: number = 0;

  /* USER VARIABLES */
  user: firebase.User;
  userObject: User = {};
  usersTable: User[];
  listData: MatTableDataSource<any>;
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = ["email", "ImePrezime", "Odaberi"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  i = 0;

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  constructor(
    private musicalNotesService: MusicalNotesService,
    private serialInputService: SerialPubInputService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    /* GET SERIAL PUB ELEMENT */
    this.serialInputService
      .getSerialPubElement(this.data.SchoolName, this.data.key)
      .subscribe((item) => {
        this.serialPubElement = item;
        this.leftRentingCounter = item.invStatusi.filter(
          (el) => el.statusi === "Slobodno"
        ).length;
      });

    /* GETS ALL THE USERS IN THE SCHOOL SYSTEM */

    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          this.loadAllUsers();
        });
      }
    });
  }

  loadAllUsers() {
    this.userService.getAllUsers().subscribe((list) => {
      this.usersTable = list
        .map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        })
        .filter((item: any) => {
          if (this.userObject.role === "SuperAdmin") {
            return item;
          } else if (this.userObject.role === "AdminSkole") {
            return (
              item.School.key === this.userObject.School.key ||
              item.role === "Eksterni korisnik"
            );
          }
        });
      this.listData = new MatTableDataSource(this.usersTable);
      this.listData.sort = this.matSort;
      this.listData.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  /* TAKING THE SELECTED RENT VALUES */
  mapRenting = new Map();
  userKey: string = "";

  checkBoxSerialPubsChanged(event, i) {
    if (event.checked)
      this.mapRenting.set(i, this.serialPubElement.invStatusi[i]);
    else this.mapRenting.delete(i);
  }

  onSelectTheUser(event) {
    this.userKey = event.value;
  }
  onRentSerialPub() {
    let renting: {
      User: any;
      SerialPub: any;
      selectedSerialPubs: any[];
      serialPubKey: string;
    } = {
      User: {},
      SerialPub: this.data.SerialPub,
      selectedSerialPubs: [],
      serialPubKey: this.data.key,
    };

    let keysForUpdating = [];
    let iterator = this.mapRenting.keys();

    this.mapRenting.forEach((item) => {
      renting.selectedSerialPubs.push(item);
      keysForUpdating.push(iterator.next().value);
    });

    let temp = [];
    keysForUpdating.forEach((index) => {
      temp[index] = renting.SerialPub.invStatusi[index];
    });

    if (renting.selectedSerialPubs.length > 0 && this.userKey !== "") {
      renting.SerialPub.invStatusi = temp;

      delete renting.SerialPub.$key;

      this.showMessage = false;
      this.authService.userObject.valueChanges().subscribe((item) => {
        this.authService.getUserObject(this.userKey).subscribe((user) => {
          renting.User = user;
          this.musicalNotesService.insertRentSerialPub(
            item.School.Naziv,
            renting
          );
          // UPDATING THE STATUS OF RENTED SERIAL PUBS
          keysForUpdating.forEach((element) => {
            this.musicalNotesService.updateStatusesAfterRenting(
              item.School.Naziv,
              this.data.key,
              element
            );
          });
          this.closeDialog();
          this.ngOnInit();
        });
      });
    } else this.showMessage = true;
  }

  showMessage = false;

  /* OTHER FUNCTIONS */
  closeDialog() {
    this.dialog.closeAll();
  }
}
