import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { UserService } from "src/app/user/user.service";
import { AuthService } from "src/app/auth/auth.service";
import { SharedClass } from "src/app/shared/shared-functions";
import { FormControl } from "@angular/forms";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";

interface InvStatuses {
  key: string;
  data: any;
}
@Component({
  selector: "app-serial-pub-report",
  templateUrl: "./serial-pub-report.component.html",
  styleUrls: ["./serial-pub-report.component.css"],
})
export class SerialPubReportComponent implements OnInit {
  userObject: User;
  users: User[] = [];
  user: firebase.User;
  showReverse: boolean = false;
  fromProfile: boolean = false;

  printSelected: boolean = false;

  selectedSerialPub: any = {};

  listOfRentedSerialPubs: any = [];

  currentDate: any = this.sharedClass.currentDateFormatddmmyyyy();
  date = new FormControl(new Date());
  minDate = new Date();
  @ViewChild("cenaIznajmljivanja", { static: false }) inputCena: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private authService: AuthService,
    public sharedClass: SharedClass,
    private musicalNotesService: MusicalNotesService
  ) {}

  ngOnInit() {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService
          .getUserObject(user.uid)
          .subscribe((item) => (this.userObject = item));
      }
      this.selectedSerialPub = this.data.selectedSerialPub;
      if (this.data.fromProfile) this.fromProfile = this.data.fromProfile;
      for (let key in this.selectedSerialPub.SerialPub.invStatusi) {
        this.listOfRentedSerialPubs.push({
          key: key,
          data: this.selectedSerialPub.SerialPub.invStatusi[key],
        });
      }
    });
  }

  onReturnTheSelectedRent(index: number) {
    if (
      confirm("Da li ste sigurni da je korisnik vratio serijsku publikaciju?")
    ) {
      // UPDATING THE STATUS ON ORIGINAL SERIAL PUB ELEMENT

      this.authService.userObject.valueChanges().subscribe((item) => {
        this.musicalNotesService.updateTheStatusOfReturnedSerialPub(
          item.School.Naziv,
          this.data.serialPubKey,
          index
        );

        //UPDATING THE STATUS ON RENTING SERIAL PUB ELEMENT
        this.musicalNotesService.updateTheStatusOnRentSerialPub(
          item.School.Naziv,
          this.data.rentKey,
          index
        );
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
