import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { SoundMaterialInputComponent } from "src/app/musical-notes/musical-notes-inputs/sound-material-input/sound-material-input.component";
import { SoundPubRentComponent } from "src/app/musical-notes/musical-rents/sound-pub-rent/sound-pub-rent.component";
import { RemarkViewComponent } from "../../remark-view/remark-view.component";

@Component({
  selector: "app-sound-pub-item",
  templateUrl: "./sound-pub-item.component.html",
  styleUrls: ["./sound-pub-item.component.css"],
})
export class SoundPubItemComponent implements OnInit {
  @Input() soundPubElement: any;
  currentSchoolKey: any = "";
  loggedUser: any;
  constructor(private dialog: MatDialog, private authService: AuthService) {}

  ngOnInit() {
    this.loggedUser = this.authService.logedUser;
  }
  onOpenEditDialog(key) {
    this.dialog.open(SoundMaterialInputComponent, {
      disableClose: false,
      autoFocus: true,
      width: "700px",
      height: "700px",

      data: {
        key: key,
        SchoolName: this.loggedUser.School.Naziv,
      },
    });
  }

  onOpenSoundPubRent(key) {
    this.soundPubElement.key = this.soundPubElement.$key;

    this.dialog.open(SoundPubRentComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "90%",

      data: {
        key: key,
        SchoolName: this.loggedUser.School.Naziv,
        SoundPub: this.soundPubElement,
      },
    });
  }

  onOpenFullRemark(text) {
    this.dialog.open(RemarkViewComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      data: {
        text: text,
      },
    });
  }
}
