import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { MonoPubInputService } from "src/app/musical-notes/musical-notes-inputs/input-services/mono-pub-input.service";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";
import { PrintViewConfigurationComponent } from "src/app/musical-notes/print/print-view-configuration/print-view-configuration.component";
import { PrintBookConfigurationComponent } from "src/app/musical-notes/views/print-book-configuration/print-book-configuration.component";
import { SharedClass } from "src/app/shared/shared-functions";
import { MonoPubReportComponent } from "../../reports/mono-pub-report/mono-pub-report.component";

@Component({
  selector: "app-mono-pub-view",
  templateUrl: "./mono-pub-view.component.html",
  styleUrls: ["./mono-pub-view.component.css"],
})
export class MonoPubViewComponent implements OnInit {
  user: firebase.User;
  userObject: User;
  schoolName: string = "";
  allRentsFromDB: any[] = [];

  monoPubRents: any[] = [];
  filteredMonoPubRents: any = [];
  monoPubs: any[] = [];

  constructor(
    private auth: AuthService,
    public musicalNotesService: MusicalNotesService,
    public monoPubService: MonoPubInputService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.allRentsFromDB = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.getUsers();
  }

  loadAllRents() {
    if (this.userObject) {
      return this.musicalNotesService
        .getMonoPubRents(this.schoolName)
        .subscribe((list) => {
          this.allRentsFromDB = list.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val(),
            };
          });
          this.loadMonoPubs();
          this.getSerialPubs();
          this.loadTable();
        });
    }
  }
  /* TABLE */
  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = [
    "Korisnik",
    "Email",
    "Naziv",
    "Date",
    "Time",
    "Status",
    "Options",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  loadTable() {
    this.listData = new MatTableDataSource(this.filteredMonoPubRents);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;
  }

  /* GETS DATA FOR SPECIFIC MUSICAL NOTE */

  rentKeys: any = [];
  getSerialPubs() {
    let index = 0;

    this.rentKeys = [];
    this.monoPubRents = [];
    // console.log(this.allRentsFromDB);

    //Iteration over every object on the same level of data structure
    for (const key in this.allRentsFromDB) {
      let element: {
        mPElement: any;
        index: number;
        returnedCounter: number;
      } = {
        mPElement: this.allRentsFromDB[key],
        index: index++,
        returnedCounter: 0,
      };

      //COUNTER FOR RETURNED RENTS
      for (const invkey in element.mPElement.MonoPub.invStatusi) {
        if (element.mPElement.MonoPub.invStatusi[invkey].statusi === "Vraćeno")
          element.returnedCounter++;
      }

      this.monoPubRents.push(element);
      this.rentKeys.push(this.allRentsFromDB[key].$key);
    }

    //FILTERING DATA IF COMPONENT ACCESSED FROM PROFILE
    if (this.musicalNotesService.fromProfile) {
      this.monoPubRents = this.monoPubRents.filter(
        (el) => el.mPElement.User.email === this.userObject.email
      );

      //ADDING NEW INDEX FOR PERSONAL RENTS
      for (let i = 0; i < this.monoPubRents.length; i++)
        this.monoPubRents[i].index = i;
    }

    this.filteredMonoPubRents = this.monoPubRents;
    this.setTotalSerialPubs();
  }

  /* USERS FUNCTIONS */
  getUsers() {
    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          if (!this.schoolName) {
            this.schoolName = this.userObject.School.Naziv;
            this.musicalNotesService.fromProfile = true;
          }
          this.loadAllRents();
        });
      }
    });
  }

  onOpenMonoPubReport(index) {
    this.dialog.open(MonoPubReportComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        selectedMonoPub: this.monoPubRents[index].mPElement,
        monoPubKey: this.monoPubRents[index].mPElement.monoPubKey,
        rentKey: this.rentKeys[index],
        fromProfile: this.musicalNotesService.fromProfile,
      },
    });
  }

  /* SEARCHING ALGORITHM */

  applyFilter() {
    this.filteredMonoPubRents = this.monoPubsFiltered();
    this.listData.data = this.filteredMonoPubRents;
    this.setTotalSerialPubs();
  }

  monoPubsFiltered() {
    return this.monoPubRents.filter(
      (sP) =>
        sP.mPElement.TimeOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.DateOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.email
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.firstname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.User.lastname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.mPElement.MonoPub.Naslov.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1
    );
  }

  refresh() {
    this.searchKey = "";
    this.filteredMonoPubRents = this.monoPubRents;
    this.listData.data = this.filteredMonoPubRents;
  }

  /* STATISTICS */

  totalSerialPubs: number = 0;
  rentedSerialPubs: number = 0;

  setTotalSerialPubs() {
    this.totalSerialPubs = 0;
    this.rentedSerialPubs = 0;

    this.filteredMonoPubRents.forEach((element) => {
      this.totalSerialPubs += element.mPElement.ListaRentovanja.length;

      if (Array.isArray(element.mPElement.MonoPub.invStatusi))
        this.rentedSerialPubs += element.mPElement.MonoPub.invStatusi.filter(
          (lele) => lele.statusi === "Vraćeno"
        ).length;
      else {
        if (element.mPElement.MonoPub.invStatusi.statusi === "Vraćeno") {
          this.rentedSerialPubs++;
        }
      }
    });
  }

  onOpenSerialPubReportRouter() {
    this.router.navigate(["/izvestaji", this.schoolName]);
  }
  onOpenMusicalPubReport() {
    this.router.navigate(["/muzikalijeIzvestaji", this.schoolName]);
  }
  onOpenMonoPubReportView() {
    this.router.navigate(["/monografskePublikacijeIzvestaji", this.schoolName]);
  }
  onOpenSoundPubReport() {
    this.router.navigate(["/zvucnaGradjaIzvestaji", this.schoolName]);
  }

  onOpenPrintConfiguration() {
    this.dialog.open(PrintViewConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        category: "monografska",
        reports: this.allRentsFromDB,
      },
    });
  }

  onOpenPrintForBook() {
    this.dialog.open(PrintBookConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        columns: this.columns,
      },
    });
  }

  loadMonoPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.monoPubService.getMonoPubs(schoolName).subscribe((list) => {
      this.monoPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  checkedCounter() {
    return this.columns.filter((item) => item.checked);
  }

  //HARD-CODED DATA

  columns: any = [
    { name: "Datum", checked: true },
    {
      name: "Autor, Naslov, Mesto izdavanja, Izdavač, Godina izdavanja",
      checked: true,
    },
    { name: "Povez", checked: true },
    { name: "Dimenzije", checked: true },
    { name: "Način nabavke", checked: true },
    { name: "Cena", checked: true },
    { name: "Signatura", checked: true },
    { name: "Napomena", checked: true },
    { name: "Podnaslov", checked: false },
    { name: "Broj sveske", checked: false },
    { name: "Sadržaj", checked: false },
    { name: "Godište sveske", checked: false },
    { name: "Prateći dokument", checked: false },
    { name: "UDK", checked: false },
    { name: "ISBN", checked: false },
    { name: "ISMN", checked: false },
    { name: "Link publikacije Cobiss", checked: false },
  ];
}
