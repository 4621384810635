import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { SharedClass } from "src/app/shared/shared-functions";

@Component({
  selector: "app-table-view",
  templateUrl: "./table-view.component.html",
  styleUrls: ["./table-view.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TableViewComponent implements OnChanges {
  @Input() filteredSerialPubs: any[] = [];
  @Input() filteredMonoPubs: any[] = [];
  @Input() filteredMusicalPubs: any[] = [];
  @Input() filteredSoundPubs: any[] = [];
  @Input() acessedFrom: string = "";
  expandedElement: any | null;
  columnsToDisplay: string[] = [
    "R. br.",
    "Naslov",
    "Podnaslov",
    "Izdavac",
    "Mesto",
    "GodinaIzdavanja",
    "Signatura",
  ];
  constructor(public sharedClass: SharedClass) {}
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  allPubs: any[] = [];
  ngOnChanges() {
    this.allPubs = [];
    //prettier-ignore
    if (this.filteredSerialPubs && this.filteredMonoPubs && this.filteredMusicalPubs && this.filteredSoundPubs) 
        this.concatenatePubs()

    this.dataSource = new MatTableDataSource(this.allPubs);
    this.dataSource.sort = this.matSort;
    this.dataSource.paginator = this.paginator;
  }

  //prettier-ignore
  concatenatePubs() {
    this.allPubs = []
    let i = 1;
    if(this.acessedFrom == 'Sve'){
      this.allPubs = this.allPubs.concat(this.filteredSerialPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
      this.allPubs = this.allPubs.concat(this.filteredMonoPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
      this.allPubs = this.allPubs.concat(this.filteredMusicalPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
      this.allPubs = this.allPubs.concat(this.filteredSoundPubs.map((obj) => ({ ...obj, index: i++ })));
    }
    if(this.acessedFrom == 'Serijske publikacije')
      this.allPubs = this.allPubs.concat(this.filteredSerialPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
    if(this.acessedFrom == 'Monografske publikacije')
      this.allPubs = this.allPubs.concat(this.filteredMonoPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
    if(this.acessedFrom == 'Muzikalije')
      this.allPubs = this.allPubs.concat(this.filteredMusicalPubs.map((obj) => ({ ...obj, index: i++ }))); i = 1;
    if(this.acessedFrom == 'Zvučna građa')
      this.allPubs = this.allPubs.concat(this.filteredSoundPubs.map((obj) => ({ ...obj, index: i++ })));
  }

  @Input() selectedIndex: number = 0;
  @Output() selectedIndexChange = new EventEmitter<boolean>();
  changeAcessFrom(event) {
    //SHOWING THE TABLE COMPONENT WHICH TAB IS SELECTED IN ORDER TO SHOW THAT DATA IN TABLE
    this.acessedFrom = event.tab.textLabel;
    //SENDING THE VALUE FOR PARENT COMPONENT
    this.selectedIndexChange.emit(event.index);
  }
}
