<div
  style="display: flex; flex-direction: row"
  *ngIf="authService.logedUser?.role === 'SuperAdmin'"
>
  <mat-form-field
    style="width: 100% !important; margin: 2%"
    class="example-full-width"
  >
    <mat-label>Opis</mat-label>
    <input matInput placeholder="Opis" value="" #newDesc />
  </mat-form-field>
  <mat-form-field
    style="width: 100% !important; margin: 2%"
    class="example-full-width"
  >
    <mat-label>Link</mat-label>
    <input matInput placeholder="Link" value="" #newLink />
  </mat-form-field>
  <button mat-stroked-button style="margin: 2%" (click)="insertNewLink()">
    <mat-icon>add</mat-icon>
  </button>
</div>
<div>
  <mat-card *ngFor="let link of links" style="margin: 2%">
    <div style="text-align: center; font-size: larger; overflow: hidden">
      <b
        ><a
          href="{{ link?.URL }}"
          target="blank"
          style="text-decoration: none"
          >{{ link?.Description }}</a
        >
      </b>
      <mat-icon
        style="float: right; color: red"
        (click)="delete(link?.$key)"
        *ngIf="authService.logedUser?.role === 'SuperAdmin'"
        >delete</mat-icon
      >
    </div>
  </mat-card>
</div>
