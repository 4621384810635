import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-mono-pub-list",
  templateUrl: "./mono-pub-list.component.html",
  styleUrls: ["./mono-pub-list.component.css"],
})
export class MonoPubListComponent implements OnInit {
  @Input() monoPubs: any[] = [];
  @Input() filteredMonoPubs: any[] = [];

  constructor() {}

  ngOnInit() {}

  searchByProp(searchProps) {
    let pubsForFiltering = this.monoPubs;

    if (searchProps.inputAuthor.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Autor.toLowerCase().includes(searchProps.inputAuthor);
      });
    }

    if (searchProps.inputAuthorTrans.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.AutorTranskripcija.toLowerCase().includes(
          searchProps.inputAuthorTrans
        );
      });
    }

    if (searchProps.inputUcesnik.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Ucesnici.toLowerCase().includes(searchProps.inputUcesnik);
      });
    }

    if (searchProps.inputUcesnikTrans.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.UcesniciTranskripcija.toLowerCase().includes(
          searchProps.inputUcesnikTrans
        );
      });
    }

    if (searchProps.inputNaslov.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Naslov.toLowerCase().includes(searchProps.inputNaslov);
      });
    }

    if (searchProps.inputNaslovTrans.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.NaslovTranskripcija.toLowerCase().includes(
          searchProps.inputNaslovTrans
        );
      });
    }

    if (searchProps.inputPodnaslov.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Podnaslov.toLowerCase().includes(
          searchProps.inputPodnaslov
        );
      });
    }

    if (searchProps.inputPodnaslovTrans.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.PodnaslovTranskripcija.toLowerCase().includes(
          searchProps.inputPodnaslovTrans
        );
      });
    }

    if (searchProps.inputIzdavac.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Izdavac.toLowerCase().includes(searchProps.inputIzdavac);
      });
    }

    if (searchProps.inputSadrzaj.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.Sadrzaj.toLowerCase().includes(searchProps.inputSadrzaj);
      });
    }

    if (searchProps.inputGodinaIzdavanja.length > 0) {
      pubsForFiltering = pubsForFiltering.filter((item: any) => {
        return item.GodinaIzdavanja.toLowerCase().includes(
          searchProps.inputGodinaIzdavanja
        );
      });
    }
    this.filteredMonoPubs = pubsForFiltering;
  }

  searchMonoPubs(searchInput) {
    this.filteredMonoPubs = this.monoPubs.filter((item: any) => {
      return (
        item.Naslov.toLowerCase().includes(searchInput) ||
        item.Datum.toLowerCase().includes(searchInput) ||
        item.Autor.toLowerCase().includes(searchInput) ||
        item.Tip.toLowerCase().includes(searchInput) ||
        item.Podnaslov.toLowerCase().includes(searchInput) ||
        item.BrojSveske.toLowerCase().includes(searchInput) ||
        item.Mesto.toLowerCase().includes(searchInput) ||
        item.Izdavac.toLowerCase().includes(searchInput) ||
        item.GodisteSveske.toLowerCase().includes(searchInput) ||
        item.GodinaIzdavanja.toLowerCase().includes(searchInput) ||
        item.Dimenzije.toLowerCase().includes(searchInput) ||
        item.Povez.toLowerCase().includes(searchInput) ||
        item.NacinNabavke.toLowerCase().includes(searchInput) ||
        item.PrateciDokument.toLowerCase().includes(searchInput) ||
        item.Cena.toString().toLowerCase().includes(searchInput) ||
        item.UDK.toLowerCase().includes(searchInput) ||
        item.ISBN.toLowerCase().includes(searchInput) ||
        item.ISMN.toLowerCase().includes(searchInput) ||
        item.Signatura.toLowerCase().includes(searchInput) ||
        item.LinkPublikacije.toLowerCase().includes(searchInput) ||
        item.Napomena.toLowerCase().includes(searchInput) ||
        item.AutorTranskripcija.toLowerCase().includes(searchInput) ||
        item.PodnaslovTranskripcija.toLowerCase().includes(searchInput) ||
        item.NaslovTranskripcija.toLowerCase().includes(searchInput) ||
        item.Ucesnici.toLowerCase().includes(searchInput) ||
        item.UcesniciTranskripcija.toLowerCase().includes(searchInput) ||
        item.Sadrzaj.toString().toLowerCase().includes(searchInput)
      );
    });
  }
}
