<div id="print-section" style="font-size: small">
  <!-- Serial pub properties -->
  <div
    *ngIf="
      isPublicationSelected('Serijske publikacije') && serialPubs().length > 0
    "
  >
    <p style="text-align: center">
      {{ authService.logedUser?.School?.Naziv }} | Serijske publikacije | Broj
      publikacija: <b>{{ serialPubs().length }}</b>
      <button
        mat-stroked-button
        style="margin-left: 5%"
        (click)="exportexcel('serialPubs-table')"
      >
        <mat-icon> cloud_download</mat-icon> Export to excel
      </button>
    </p>
    <table id="serialPubs-table" style="width: 90%; margin: 0 auto">
      <tr>
        <th>Red. br.</th>
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <th *ngIf="prop.selected">{{ prop?.name }}</th>
        </ng-container>
        <th>Primeraka</th>
      </tr>
      <tr *ngFor="let el of serialPubs(); let i = index">
        <td>{{ i + 1 }}.</td>
        <!-- Interescted Properties -->
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <td *ngIf="prop.name === 'Datum' && prop.selected">
            {{ el?.Datum.substring(4, 15) }}
          </td>
          <td *ngIf="prop.name === 'Autor' && prop.selected">
            {{ el?.Autor }}
          </td>
          <td *ngIf="prop.name === 'Naslov' && prop.selected">
            {{ el?.Naslov }}
          </td>
          <td *ngIf="prop.name === 'Mesto izdavanja' && prop.selected">
            {{ el?.Mesto }}
          </td>
          <td *ngIf="prop.name === 'Izdavač' && prop.selected">
            {{ el?.Izdavac }}
          </td>
          <td *ngIf="prop.name === 'Godište sveske' && prop.selected">
            {{ el?.GodisteSveske }}
          </td>
          <td *ngIf="prop.name === 'Godina izdavanja' && prop.selected">
            {{ el?.GodinaIzdavanja }}
          </td>
          <td *ngIf="prop.name === 'Prateći dokument' && prop.selected">
            {{ el?.PrateciDokument }}
          </td>
          <td *ngIf="prop.name === 'Polica' && prop.selected">
            {{ el?.Polica }}
          </td>
          <td *ngIf="prop.name === 'Povez' && prop.selected">
            {{ el?.Povez }}
          </td>
          <td *ngIf="prop.name === 'Dimenzije' && prop.selected">
            {{ el?.Dimenzije }}
          </td>
          <td *ngIf="prop.name === 'Način nabavke' && prop.selected">
            {{ el?.NacinNabavke }}
          </td>
          <td *ngIf="prop.name === 'Cena' && prop.selected">
            {{ el?.Cena }}
          </td>
          <td *ngIf="prop.name === 'Signatura' && prop.selected">
            {{ el?.Signatura }}
          </td>
          <td *ngIf="prop.name === 'Napomena' && prop.selected">
            {{ el?.Napomena }}
          </td>
          <td *ngIf="prop.name === 'UDK' && prop.selected">
            {{ el?.UDK }}
          </td>
          <td *ngIf="prop.name === 'ISBN' && prop.selected">
            {{ el?.ISBN }}
          </td>
          <td *ngIf="prop.name === 'ISMN' && prop.selected">
            {{ el?.ISMN }}
          </td>
          <td *ngIf="prop.name === 'Link' && prop.selected">
            {{ el?.LinkPublikacije }}
          </td>

          <td *ngIf="prop.name === 'Sadržaj' && prop.selected">
            {{ el?.Sadrzaj }}
          </td>
          <td *ngIf="prop.name === 'QR kod' && prop.selected">
            <qrcode
              [qrdata]="
                'Serijska \nNaslov - ' +
                  el?.Naslov +
                  '\n' +
                  'Autor - ' +
                  el?.Autor +
                  '\n' +
                  'Podnaslov - ' +
                  el?.Podnaslov +
                  '\n' +
                  'Izdavač - ' +
                  el?.Izdavac +
                  '\n' +
                  'Godina Izdavanja - ' +
                  el?.GodinaIzdavanja +
                  '\n' +
                  'Polica - ' +
                  el?.Polica +
                  '\n' || 'Prazno'
              "
              [width]="120"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </td>
        </ng-container>

        <td>{{ el.invStatusi.length }}</td>
      </tr>
    </table>
  </div>
  <!-- Mono pub properties -->
  <div
    *ngIf="
      isPublicationSelected('Monografske publikacije') && monoPubs().length > 0
    "
  >
    <p style="text-align: center">
      {{ authService.logedUser?.School?.Naziv }} | Monografske publikacije |
      Broj publikacija:
      <b>{{ monoPubs().length }}</b>
      <button
        mat-stroked-button
        style="margin-left: 5%"
        (click)="exportexcel('monoPubs-table')"
      >
        <mat-icon> cloud_download</mat-icon> Export to excel
      </button>
    </p>
    <table id="monoPubs-table" style="width: 90%; margin: 0 auto">
      <tr>
        <th>Red. br.</th>
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <th *ngIf="prop.selected">{{ prop?.name }}</th>
        </ng-container>
        <th *ngIf="isNumberOfDocumentSelected">Broj sveske, toma</th>
        <th>Primeraka</th>
      </tr>
      <tr *ngFor="let el of monoPubs(); let i = index">
        <td>{{ i + 1 }}.</td>
        <!-- Interescted Properties -->
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <td *ngIf="prop.name === 'Datum' && prop.selected">
            {{ el?.Datum.substring(4, 15) }}
          </td>
          <td *ngIf="prop.name === 'Autor' && prop.selected">
            {{ el?.Autor }}
          </td>
          <td *ngIf="prop.name === 'Naslov' && prop.selected">
            {{ el?.Naslov }}
          </td>
          <td *ngIf="prop.name === 'Mesto izdavanja' && prop.selected">
            {{ el?.Mesto }}
          </td>
          <td *ngIf="prop.name === 'Izdavač' && prop.selected">
            {{ el?.Izdavac }}
          </td>
          <td *ngIf="prop.name === 'Godište sveske' && prop.selected">
            {{ el?.GodisteSveske }}
          </td>
          <td *ngIf="prop.name === 'Godina izdavanja' && prop.selected">
            {{ el?.GodinaIzdavanja }}
          </td>
          <td *ngIf="prop.name === 'Prateći dokument' && prop.selected">
            {{ el?.PrateciDokument }}
          </td>
          <td *ngIf="prop.name === 'Polica' && prop.selected">
            {{ el?.Polica }}
          </td>
          <td *ngIf="prop.name === 'Povez' && prop.selected">
            {{ el?.Povez }}
          </td>
          <td *ngIf="prop.name === 'Dimenzije' && prop.selected">
            {{ el?.Dimenzije }}
          </td>
          <td *ngIf="prop.name === 'Način nabavke' && prop.selected">
            {{ el?.NacinNabavke }}
          </td>
          <td *ngIf="prop.name === 'Cena' && prop.selected">
            {{ el?.Cena }}
          </td>
          <td *ngIf="prop.name === 'Signatura' && prop.selected">
            {{ el?.Signatura }}
          </td>
          <td *ngIf="prop.name === 'Napomena' && prop.selected">
            {{ el?.Napomena }}
          </td>
          <td *ngIf="prop.name === 'UDK' && prop.selected">
            {{ el?.UDK }}
          </td>
          <td *ngIf="prop.name === 'ISBN' && prop.selected">
            {{ el?.ISBN }}
          </td>
          <td *ngIf="prop.name === 'ISMN' && prop.selected">
            {{ el?.ISMN }}
          </td>
          <td *ngIf="prop.name === 'Link' && prop.selected">
            {{ el?.LinkPublikacije }}
          </td>

          <td *ngIf="prop.name === 'Sadržaj' && prop.selected">
            {{ el?.Sadrzaj }}
          </td>

          <td *ngIf="prop.name === 'QR kod' && prop.selected">
            <qrcode
              [qrdata]="
                'Monografska\nNaslov - ' +
                  el?.Naslov +
                  '\n' +
                  'Autor - ' +
                  el?.Autor +
                  '\n' +
                  'Podnaslov - ' +
                  el?.Podnaslov +
                  '\n' +
                  'Izdavač - ' +
                  el?.Izdavac +
                  '\n' +
                  'Godina Izdavanja - ' +
                  el?.GodinaIzdavanja +
                  '\n' +
                  'Polica - ' +
                  el?.Polica +
                  '\n' || 'Prazno'
              "
              [width]="120"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </td>
        </ng-container>
        <td *ngIf="isNumberOfDocumentSelected">
          {{ el?.BrojSveske }}
        </td>
        <td>{{ el.invStatusi.length }}</td>
      </tr>
    </table>
  </div>
  <!-- Musicals properties -->
  <div *ngIf="isPublicationSelected('Muzikalije') && musicalPubs().length > 0">
    <p style="text-align: center">
      {{ authService.logedUser?.School?.Naziv }} | Muzikalije | Broj
      publikacija:
      <b>{{ musicalPubs().length }}</b>
      <button
        mat-stroked-button
        style="margin-left: 5%"
        (click)="exportexcel('musicals-table')"
      >
        <mat-icon> cloud_download</mat-icon> Export to excel
      </button>
    </p>
    <table id="musicals-table" style="width: 90%; margin: 0 auto">
      <tr>
        <th>Red. br.</th>
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <th *ngIf="prop.selected">{{ prop?.name }}</th>
        </ng-container>
        <th *ngIf="isTypeOfDocumentSelected">Vrsta dokumenta</th>
        <th>Primeraka</th>
      </tr>
      <tr *ngFor="let el of musicalPubs(); let i = index">
        <td>{{ i + 1 }}.</td>
        <!-- Interescted Properties -->
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <td *ngIf="prop.name === 'Datum' && prop.selected">
            {{ el?.Datum.substring(4, 15) }}
          </td>
          <td *ngIf="prop.name === 'Autor' && prop.selected">
            {{ el?.Autor }}
          </td>
          <td *ngIf="prop.name === 'Naslov' && prop.selected">
            {{ el?.Naslov }}
          </td>
          <td *ngIf="prop.name === 'Mesto izdavanja' && prop.selected">
            {{ el?.Mesto }}
          </td>
          <td *ngIf="prop.name === 'Izdavač' && prop.selected">
            {{ el?.Izdavac }}
          </td>
          <td *ngIf="prop.name === 'Godište sveske' && prop.selected">
            {{ el?.GodisteSveske }}
          </td>
          <td *ngIf="prop.name === 'Godina izdavanja' && prop.selected">
            {{ el?.GodinaIzdavanja }}
          </td>
          <td *ngIf="prop.name === 'Prateći dokument' && prop.selected">
            {{ el?.PrateciDokument }}
          </td>
          <td *ngIf="prop.name === 'Polica' && prop.selected">
            {{ el?.Polica }}
          </td>
          <td *ngIf="prop.name === 'Povez' && prop.selected">
            {{ el?.Povez }}
          </td>
          <td *ngIf="prop.name === 'Dimenzije' && prop.selected">
            {{ el?.Dimenzije }}
          </td>
          <td *ngIf="prop.name === 'Način nabavke' && prop.selected">
            {{ el?.NacinNabavke }}
          </td>
          <td *ngIf="prop.name === 'Cena' && prop.selected">
            {{ el?.Cena }}
          </td>
          <td *ngIf="prop.name === 'Signatura' && prop.selected">
            {{ el?.Signatura }}
          </td>
          <td *ngIf="prop.name === 'Napomena' && prop.selected">
            {{ el?.Napomena }}
          </td>
          <td *ngIf="prop.name === 'UDK' && prop.selected">
            {{ el?.UDK }}
          </td>
          <td *ngIf="prop.name === 'ISBN' && prop.selected">
            {{ el?.ISBN }}
          </td>
          <td *ngIf="prop.name === 'ISMN' && prop.selected">
            {{ el?.ISMN }}
          </td>
          <td *ngIf="prop.name === 'Link' && prop.selected">
            {{ el?.LinkPublikacije }}
          </td>

          <td *ngIf="prop.name === 'Sadržaj' && prop.selected">
            {{ el?.Sadrzaj }}
          </td>

          <td *ngIf="prop.name === 'QR kod' && prop.selected">
            <qrcode
              [qrdata]="
                'Muzikalije \nNaslov - ' +
                  el?.Naslov +
                  '\n' +
                  'Autor - ' +
                  el?.Autor +
                  '\n' +
                  'Podnaslov - ' +
                  el?.Podnaslov +
                  '\n' +
                  'Izdavač - ' +
                  el?.Izdavac +
                  '\n' +
                  'Godina Izdavanja - ' +
                  el?.GodinaIzdavanja +
                  '\n' +
                  'Polica - ' +
                  el?.Polica +
                  '\n' || 'Prazno'
              "
              [width]="120"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </td>
        </ng-container>
        <td *ngIf="isTypeOfDocumentSelected">
          {{ el?.VrstaDokumenta }}
        </td>

        <td>{{ el.invStatusi.length }}</td>
      </tr>
    </table>
  </div>
  <!-- Sound material properties -->
  <div *ngIf="isPublicationSelected('Zvučna građa') && soundPubs().length > 0">
    <p style="text-align: center">
      {{ authService.logedUser?.School?.Naziv }} | Zvučna građa | Broj
      publikacija:
      <b>{{ soundPubs().length }}</b>
      <button
        mat-stroked-button
        style="margin-left: 5%"
        (click)="exportexcel('soundPubs-table')"
      >
        <mat-icon> cloud_download</mat-icon> Export to excel
      </button>
    </p>
    <table id="soundPubs-table" style="width: 90%; margin: 0 auto">
      <tr>
        <th>Red. br.</th>
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <th *ngIf="prop.selected">{{ prop?.name }}</th>
        </ng-container>

        <ng-container
          *ngFor="let prop of configuredData.selectedCategories[3].columns"
        >
          <th *ngIf="prop.selected">{{ prop?.name }}</th>
        </ng-container>
        <th>Primeraka</th>
      </tr>
      <tr *ngFor="let el of soundPubs(); let i = index">
        <td>{{ i + 1 }}.</td>
        <!-- Interescted Properties -->
        <ng-container *ngFor="let prop of configuredData.selectedProperties">
          <td *ngIf="prop.name === 'Datum' && prop.selected">
            {{ el?.Datum.substring(4, 15) }}
          </td>
          <td *ngIf="prop.name === 'Autor' && prop.selected">
            {{ el?.Autor }}
          </td>
          <td *ngIf="prop.name === 'Naslov' && prop.selected">
            {{ el?.Naslov }}
          </td>
          <td *ngIf="prop.name === 'Mesto izdavanja' && prop.selected">
            {{ el?.Mesto }}
          </td>
          <td *ngIf="prop.name === 'Izdavač' && prop.selected">
            {{ el?.Izdavac }}
          </td>
          <td *ngIf="prop.name === 'Godište sveske' && prop.selected">
            {{ el?.GodisteSveske }}
          </td>
          <td *ngIf="prop.name === 'Godina izdavanja' && prop.selected">
            {{ el?.GodinaIzdavanja }}
          </td>
          <td *ngIf="prop.name === 'Prateći dokument' && prop.selected">
            {{ el?.PrateciDokument }}
          </td>
          <td *ngIf="prop.name === 'Polica' && prop.selected">
            {{ el?.Polica }}
          </td>
          <td *ngIf="prop.name === 'Povez' && prop.selected">
            {{ el?.Povez }}
          </td>
          <td *ngIf="prop.name === 'Dimenzije' && prop.selected">
            {{ el?.Dimenzije }}
          </td>
          <td *ngIf="prop.name === 'Način nabavke' && prop.selected">
            {{ el?.NacinNabavke }}
          </td>
          <td *ngIf="prop.name === 'Cena' && prop.selected">
            {{ el?.Cena }}
          </td>
          <td *ngIf="prop.name === 'Signatura' && prop.selected">
            {{ el?.Signatura }}
          </td>
          <td *ngIf="prop.name === 'Napomena' && prop.selected">
            {{ el?.Napomena }}
          </td>
          <td *ngIf="prop.name === 'UDK' && prop.selected">
            {{ el?.UDK }}
          </td>
          <td *ngIf="prop.name === 'ISBN' && prop.selected">
            {{ el?.ISBN }}
          </td>
          <td *ngIf="prop.name === 'ISMN' && prop.selected">
            {{ el?.ISMN }}
          </td>
          <td *ngIf="prop.name === 'Link' && prop.selected">
            {{ el?.LinkPublikacije }}
          </td>

          <td *ngIf="prop.name === 'Sadržaj' && prop.selected">
            {{ el?.Sadrzaj }}
          </td>
          <td *ngIf="prop.name === 'QR kod' && prop.selected">
            <qrcode
              [qrdata]="
                'Zvučna građa \nNaslov - ' +
                  el?.Naslov +
                  '\n' +
                  'Autor - ' +
                  el?.Autor +
                  '\n' +
                  'Podnaslov - ' +
                  el?.Podnaslov +
                  '\n' +
                  'Izdavač - ' +
                  el?.Izdavac +
                  '\n' +
                  'Godina Izdavanja - ' +
                  el?.GodinaIzdavanja +
                  '\n' +
                  'Polica - ' +
                  el?.Polica +
                  '\n' || 'Prazno'
              "
              [width]="120"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </td>
        </ng-container>
        <!-- Additional columns for sound pubs -->
        <ng-container
          *ngFor="let column of configuredData.selectedCategories[3].columns"
        >
          <td *ngIf="column.name === 'Vrsta dokumenta' && column.selected">
            {{ el?.VrstaDokumenta }}
          </td>
          <td *ngIf="column.name === 'Broj dokumenta' && column.selected">
            {{ el?.BrojDokumenta }}
          </td>
          <td *ngIf="column.name === 'Propratna građa' && column.selected">
            {{ el?.PropratnaGradja }}
          </td>
          <td
            *ngIf="column.name === 'Tehnika zvučnog snimka' && column.selected"
          >
            {{ el?.TehnikaZvSnimka }}
          </td>
        </ng-container>
        <td>{{ el.invStatusi.length }}</td>
      </tr>
    </table>
  </div>
</div>
<div style="text-align: center; margin: 2%">
  <button
    printSectionId="print-section"
    printTitle="Publikacije"
    [useExistingCss]="true"
    ngxPrint
    mat-raised-button
  >
    <mat-icon>print</mat-icon>
  </button>
</div>
