import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { SoundPubInputService } from "src/app/musical-notes/musical-notes-inputs/input-services/sound-pub-input.service";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";
import { PrintViewConfigurationComponent } from "src/app/musical-notes/print/print-view-configuration/print-view-configuration.component";
import { PrintBookConfigurationComponent } from "src/app/musical-notes/views/print-book-configuration/print-book-configuration.component";
import { SharedClass } from "src/app/shared/shared-functions";
import { MonoPubReportComponent } from "../../reports/mono-pub-report/mono-pub-report.component";
import { SoundPubReportComponent } from "../../reports/sound-pub-report/sound-pub-report.component";

@Component({
  selector: "app-sound-pub-view",
  templateUrl: "./sound-pub-view.component.html",
  styleUrls: ["./sound-pub-view.component.css"],
})
export class SoundPubViewComponent implements OnInit {
  user: firebase.User;
  userObject: User;
  schoolName: string = "";
  allRentsFromDB: any[] = [];

  soundPubRents: any[] = [];
  filteredSoundPubRents: any = [];
  soundPubs: any[] = [];

  constructor(
    private auth: AuthService,
    public musicalNotesService: MusicalNotesService,
    public soundInputService: SoundPubInputService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.allRentsFromDB = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.getUsers();
  }

  loadAllRents() {
    if (this.userObject) {
      return this.musicalNotesService
        .getSoundPubRents(this.schoolName)
        .subscribe((list) => {
          this.allRentsFromDB = list.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val(),
            };
          });
          this.loadSoundPubs();
          this.getSoundPubs();
          this.loadTable();
        });
    }
  }
  /* TABLE */
  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = [
    "Korisnik",
    "Email",
    "Naziv",
    "Date",
    "Time",
    "Status",
    "Options",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  loadTable() {
    this.listData = new MatTableDataSource(this.filteredSoundPubRents);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;
  }

  /* GETS DATA FOR SPECIFIC MUSICAL NOTE */

  rentKeys: any = [];
  getSoundPubs() {
    let index = 0;

    this.rentKeys = [];
    this.soundPubRents = [];

    //Iteration over every object on the same level of data structure
    for (const key in this.allRentsFromDB) {
      let element: {
        sPElement: any;
        index: number;
        returnedCounter: number;
      } = {
        sPElement: this.allRentsFromDB[key],
        index: index++,
        returnedCounter: 0,
      };

      //COUNTER FOR RETURNED RENTS
      for (const invkey in element.sPElement.SoundPub.invStatusi) {
        if (element.sPElement.SoundPub.invStatusi[invkey].statusi === "Vraćeno")
          element.returnedCounter++;
      }

      this.soundPubRents.push(element);
      this.rentKeys.push(this.allRentsFromDB[key].$key);
    }

    //FILTERING DATA IF COMPONENT ACCESSED FROM PROFILE
    if (this.musicalNotesService.fromProfile) {
      this.soundPubRents = this.soundPubRents.filter(
        (el) => el.sPElement.User.email === this.userObject.email
      );

      //ADDING NEW INDEX FOR PERSONAL RENTS
      for (let i = 0; i < this.soundPubRents.length; i++)
        this.soundPubRents[i].index = i;
    }

    this.filteredSoundPubRents = this.soundPubRents;
    this.setTotalSerialPubs();
  }

  /* USERS FUNCTIONS */
  getUsers() {
    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          if (!this.schoolName) {
            this.schoolName = this.userObject.School.Naziv;
            this.musicalNotesService.fromProfile = true;
          }
          this.loadAllRents();
        });
      }
    });
  }

  onOpenSoundPubReport(index) {
    this.dialog.open(SoundPubReportComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        selectedSoundPub: this.soundPubRents[index].sPElement,
        soundPubKey: this.soundPubRents[index].sPElement.soundPubKey,
        rentKey: this.rentKeys[index],
        fromProfile: this.musicalNotesService.fromProfile,
      },
    });
  }

  /* SEARCHING ALGORITHM */

  applyFilter() {
    this.filteredSoundPubRents = this.soundPubsFiltered();
    this.listData.data = this.filteredSoundPubRents;
    this.setTotalSerialPubs();
  }

  soundPubsFiltered() {
    return this.soundPubRents.filter(
      (sP) =>
        sP.sPElement.TimeOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.DateOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.email
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.firstname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.lastname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.SoundPub.Naslov.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1
    );
  }

  refresh() {
    this.searchKey = "";
    this.filteredSoundPubRents = this.soundPubRents;
    this.listData.data = this.filteredSoundPubRents;
  }

  /* STATISTICS */

  totalSerialPubs: number = 0;
  rentedSerialPubs: number = 0;

  setTotalSerialPubs() {
    this.totalSerialPubs = 0;
    this.rentedSerialPubs = 0;

    this.filteredSoundPubRents.forEach((element) => {
      this.totalSerialPubs += element.sPElement.ListaRentovanja.length;

      if (Array.isArray(element.sPElement.SoundPub.invStatusi))
        this.rentedSerialPubs += element.sPElement.SoundPub.invStatusi.filter(
          (lele) => lele.statusi === "Vraćeno"
        ).length;
      else {
        if (element.sPElement.SoundPub.invStatusi.statusi === "Vraćeno") {
          this.rentedSerialPubs++;
        }
      }
    });
  }

  onOpenSerialPubReportRouter() {
    this.router.navigate(["/izvestaji", this.schoolName]);
  }
  onOpenMusicalPubReport() {
    this.router.navigate(["/muzikalijeIzvestaji", this.schoolName]);
  }
  onOpenMonoPubReportView() {
    this.router.navigate(["/monografskePublikacijeIzvestaji", this.schoolName]);
  }

  onOpenPrintConfiguration() {
    this.dialog.open(PrintViewConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        category: "zvucnaGradja",
        reports: this.allRentsFromDB,
      },
    });
  }

  onOpenPrintForBook() {
    this.dialog.open(PrintBookConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        columns: this.columns,
      },
    });
  }

  loadSoundPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.musicalNotesService.getSoundPubs(schoolName).subscribe((list) => {
      this.soundPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  checkedCounter() {
    return this.columns.filter((item) => item.checked);
  }

  //HARD-CODED DATA

  columns: any = [
    { name: "Datum", checked: true },
    {
      name: "Autor, Naslov, Mesto izdavanja, Izdavač, Godina izdavanja",
      checked: true,
    },
    { name: "Povez", checked: true },
    { name: "Dimenzije", checked: true },
    { name: "Način nabavke", checked: true },
    { name: "Cena", checked: true },
    { name: "Signatura", checked: true },
    { name: "Napomena", checked: true },
    { name: "Podnaslov", checked: false },
    { name: "Vrsta dokumenta", checked: false },
    { name: "Broj dokumenta", checked: false },
    { name: "Propratna građa", checked: false },
    { name: "Tehnika zvučnog snimka", checked: false },
    { name: "Sadržaj", checked: false },
    { name: "Godište sveske", checked: false },
    { name: "Prateći dokument", checked: false },
    { name: "UDK", checked: false },
    { name: "ISBN", checked: false },
    { name: "ISMN", checked: false },
    { name: "Signatura", checked: false },
    { name: "Link publikacije Cobiss", checked: false },
  ];
}
