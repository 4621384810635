<mat-card class="mat-elevation-z6" class="box" fxFlexAlign="stretch">
  <mat-card-header>
    <mat-card-title title="{{ soundPubElement?.Naslov }}">{{
      soundPubElement?.Naslov
    }}</mat-card-title>
  </mat-card-header>
  <ul class="image-qr">
    <li class="crop" (click)="onOpenEditDialog(soundPubElement.$key)">
      <div style="text-align: center; display: grid">
        <div>
          <img
            *ngIf="soundPubElement.imagesURLs"
            src="{{ (soundPubElement?.imagesURLs)[0] }}"
            alt="Nota"
          />
        </div>
      </div>
    </li>
    <li class="cropQR">
      <div>
        <!-- prettier-ignore -->
        <qrcode
          [qrdata]="'Zvučna građa \nNaslov - ' + soundPubElement?.Naslov + '\n'
          +'Autor - ' + soundPubElement?.Autor + '\n'
          +'Podnaslov - ' + soundPubElement?.Podnaslov + '\n'
          +'Izdavač - ' + soundPubElement?.Izdavac + '\n'
          +'Godina Izdavanja - ' + soundPubElement?.GodinaIzdavanja + '\n'
          +'Polica - ' + soundPubElement?.Polica + '\n'
          || 'Prazno'"
          [width]="120"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </li>
  </ul>
  <mat-card-content>
    <p>
      Datum inventarisanja:
      <b>
        {{ soundPubElement?.Datum.toString().split(" ")[2] }}.
        {{ soundPubElement?.Datum.split(" ")[1] }}.
        {{ soundPubElement?.Datum.split(" ")[3] }}.
      </b>
    </p>
    <p>
      Autor: <b>{{ soundPubElement?.Autor }}</b>
    </p>
    <p>
      Podnaslov: <b>{{ soundPubElement?.Podnaslov }}</b>
    </p>
    <p>
      Mesto izdavanja: <b> {{ soundPubElement?.Mesto }}</b>
    </p>
    <p>
      Izdavač: <b> {{ soundPubElement?.Izdavac }}</b>
    </p>
    <p>
      Godište sveske: <b>{{ soundPubElement?.GodisteSveske }}</b>
    </p>
    <p>
      Godina izdavanja: <b> {{ soundPubElement?.GodinaIzdavanja }}</b>
    </p>
    <p>
      Dimenzije: <b>{{ soundPubElement?.Dimenzije }}</b>
    </p>
    <p>
      Povez: <b>{{ soundPubElement?.Povez }}</b>
    </p>
    <p>
      Način nabavke: <b>{{ soundPubElement?.NacinNabavke }}</b>
    </p>
    <p>
      Vrsta dokumenta: <b>{{ soundPubElement?.VrstaDokumenta }}</b>
    </p>
    <p>
      Propratna građa: <b>{{ soundPubElement?.PropratnaGradja }}</b>
    </p>
    <p>
      Tehnika zvučnog snimka <b>{{ soundPubElement?.TehnikaZvSnimka }}</b>
    </p>
    <p>
      Broj dokumenta: <b>{{ soundPubElement?.BrojDokumenta }}</b>
    </p>
    <p>
      Prateći dokument: <b>{{ soundPubElement?.PrateciDokument }}</b>
    </p>
    <p>
      Cena: <b>{{ soundPubElement?.Cena }}</b> RSD
    </p>
    <p>
      UDK: <b> {{ soundPubElement?.UDK }}</b>
    </p>
    <p>
      ISBN: <b>{{ soundPubElement?.ISBN }}</b>
    </p>
    <p>
      ISMN: <b>{{ soundPubElement?.ISMN }}</b>
    </p>
    <p>
      Signatura: <b>{{ soundPubElement?.Signatura }}</b>
    </p>
    <p>
      Polica: <b>{{ soundPubElement?.Polica }}</b>
    </p>
    <p>
      Link Cobiss:
      <a href="{{ soundPubElement?.LinkPublikacije }}" target="_blank"
        >Otvori link</a
      >
    </p>
    <p>
      Sadržaj: {{ soundPubElement?.Sadrzaj.toString().substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(soundPubElement?.Sadrzaj)"
        *ngIf="soundPubElement.Sadrzaj.toString().length > 20"
        >... [više]
      </b>
    </p>
    <p>
      {{ soundPubElement?.Napomena?.substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(soundPubElement?.Napomena)"
        *ngIf="soundPubElement.Napomena.length > 20"
        >... [više]
      </b>
    </p>
    <p style="font-size: larger">
      Broj svezaka: <b>{{ soundPubElement?.invStatusi.length }}</b>
    </p>
  </mat-card-content>
  <mat-card-actions style="text-align: center">
    <button
      *ngIf="
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
      mat-button
      (click)="onOpenEditDialog(soundPubElement.$key)"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-button (click)="onOpenSoundPubRent(soundPubElement.$key)">
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
