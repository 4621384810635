<img
  src="assets/images/notes.png"
  alt="logo"
  style="min-height: 20%; min-width: 20%; width: auto"
/>
<a href="https://galis.rs/" target="_blank" style="margin: 0 auto">
  <img
    src="assets/images/galis_logo.png"
    alt="logo"
    style="
      max-height: 40%;
      max-width: 40%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    "
  />
</a>
