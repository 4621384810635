import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/database";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/router";
export class AuthService {
    constructor(afAuth, firebase, dialog, router) {
        this.afAuth = afAuth;
        this.firebase = firebase;
        this.dialog = dialog;
        this.router = router;
        this.eventAuthError = new BehaviorSubject("");
        this.eventAuthError$ = this.eventAuthError.asObservable();
        this.newUser = {};
        this.logedUser = {};
        this.usersList = firebase.list("users");
        this.roleList = firebase.list("Role");
        this.schoolList = firebase.list("Skole");
    }
    getRoles() {
        this.roleList = this.firebase.list("Role");
        return this.roleList.snapshotChanges();
    }
    getMusicalSchools() {
        this.schoolList = this.firebase.list("Skole");
        return this.schoolList.snapshotChanges();
    }
    getUserState() {
        return this.afAuth.authState;
    }
    login(email, password) {
        this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .catch((error) => {
            this.eventAuthError.next(error);
        })
            .then((userCredential) => {
            if (userCredential) {
                this.router.navigate(["/userProfile"]);
            }
        });
    }
    createUser(user, Role, logedUserUID, selectedSchool) {
        this.afAuth.auth
            .createUserWithEmailAndPassword(user.email, user.password)
            .then((userCredential) => {
            this.newUser = user;
            userCredential.user.updateProfile({
                displayName: user.firstName + " " + user.lastName,
            });
            this.insertUserData(userCredential, Role, logedUserUID, selectedSchool, user).then(() => {
                this.dialog.closeAll();
                this.router.navigate([""]);
            });
        })
            .catch((error) => {
            this.eventAuthError.next(error);
        });
    }
    insertUserData(userCredential, Role, logedUserUID, selectedSchool, user) {
        if (selectedSchool != null)
            return this.firebase.object("users/" + userCredential.user.uid).set({
                email: this.newUser.email,
                firstname: this.newUser.firstName,
                lastname: this.newUser.lastName,
                role: Role,
                CreatedByUID: logedUserUID,
                School: {
                    key: selectedSchool.$key,
                    Naziv: selectedSchool.Naziv,
                },
                AccountEnabled: true,
                PhoneNumber1: user.phoneNumber1,
            });
        else
            return this.firebase.object("users/" + userCredential.user.uid).set({
                email: this.newUser.email,
                firstname: this.newUser.firstName,
                lastname: this.newUser.lastName,
                role: Role,
                CreatedByUID: logedUserUID,
                AccountEnabled: true,
            });
    }
    changeTheSchoolForSuperAdmin(uid, school) {
        return this.firebase.object("users/" + uid + "/School").update({
            Naziv: school.Naziv,
            key: school.$key,
        });
    }
    logout() {
        this.router.navigate([""]);
        return this.afAuth.auth.signOut();
    }
    getUserObject($key) {
        this.userObject = this.firebase.object("users/" + $key);
        return this.userObject.valueChanges();
    }
    logedUserUID() {
        return this.afAuth.auth.currentUser.uid;
    }
    updateUserProfile(uid, imageUrl) {
        return this.firebase.object("users/" + uid).update({
            imageUrl: imageUrl,
        });
    }
    resetPassword(email) {
        return this.afAuth.auth
            .sendPasswordResetEmail(email)
            .then(() => console.log("email sent"))
            .catch((error) => console.log(error));
    }
    isAuthenticated() {
        return this.afAuth.auth.currentUser ? true : false;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFireDatabase), i0.ɵɵinject(i3.MatDialog), i0.ɵɵinject(i4.Router)); }, token: AuthService, providedIn: "root" });
