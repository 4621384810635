import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth/auth-guard.service";
import { LoginComponent } from "../auth/login/login.component";
import { RegistrationComponent } from "../auth/registration/registration.component";
import { UserProfileComponent } from "../auth/user-profile/user-profile.component";
import { GuidePageComponent } from "../guide-page/guide-page.component";
import { HomePageComponent } from "../home-page/home-page.component";
import { MusicalNotesInputsComponent } from "../musical-notes/musical-notes-inputs/musical-notes-inputs.component";
import { MusicalNotesListComponent } from "../musical-notes/musical-notes-list/musical-notes-list.component";
import { MonoPubViewComponent } from "../musical-notes/musical-rents/views/mono-pub-view/mono-pub-view.component";
import { MusicalPubViewComponent } from "../musical-notes/musical-rents/views/musical-pub-view/musical-pub-view.component";
import { SerialPubViewComponent } from "../musical-notes/musical-rents/views/serial-pub-view/serial-pub-view.component";
import { SoundPubViewComponent } from "../musical-notes/musical-rents/views/sound-pub-view/sound-pub-view.component";
import { PrintPubsComponent } from "../musical-notes/print/print-pubs/print-pubs.component";
import { PrintViewsComponent } from "../musical-notes/print/print-views/print-views.component";
import { UserListComponent } from "../user/user-list/user-list.component";
import { TableViewComponent } from "../musical-notes/musical-notes-list/table-view/table-view.component";

const appRoutes: Routes = [
  {
    path: "korisnici",
    component: UserListComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "unos/:NazivSkole",
    component: MusicalNotesInputsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "pregled/:NazivSkole",
    component: MusicalNotesListComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "izvestaji/:NazivSkole",
    component: SerialPubViewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "muzikalijeIzvestaji/:NazivSkole",
    component: MusicalPubViewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "stampaPublikacija",
    component: PrintPubsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "stampaIzvestaja",
    component: PrintViewsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "upustva",
    component: GuidePageComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },

  // { path: 'rezervacije', component: ReservationComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  // { path: 'pošta', component: PersonalMessagesComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "",
    component: HomePageComponent,
  },
  {
    path: "register",
    component: RegistrationComponent,
  },
  {
    path: "userProfile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "monografskePublikacijeIzvestaji/:NazivSkole",
    component: MonoPubViewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "zvucnaGradjaIzvestaji/:NazivSkole",
    component: SoundPubViewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "tabelarniPrikaz",
    component: TableViewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes, { useHash: true }), CommonModule],
})
export class RoutesModule {}
