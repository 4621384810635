import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalNotesService } from "src/app/musical-notes/musical-notes.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
} from "@angular/material";
import { SerialPubReportComponent } from "../../reports/serial-pub-report/serial-pub-report.component";
import { PrintViewConfigurationComponent } from "src/app/musical-notes/print/print-view-configuration/print-view-configuration.component";
import { SerialPubInputService } from "src/app/musical-notes/musical-notes-inputs/input-services/serial-pub-input.service";
import { PrintBookConfigurationComponent } from "src/app/musical-notes/views/print-book-configuration/print-book-configuration.component";
import { SharedClass } from "src/app/shared/shared-functions";
@Component({
  selector: "app-serial-pub-view",
  templateUrl: "./serial-pub-view.component.html",
  styleUrls: ["./serial-pub-view.component.css"],
})
export class SerialPubViewComponent implements OnInit {
  user: firebase.User;
  userObject: User;
  schoolName: string = "";
  allRentsFromDB: any[] = [];

  serialPubRents: any[] = [];
  filteredSerialPubRents: any = [];
  serialPubs: any[] = [];
  constructor(
    private auth: AuthService,
    public musicalNotesService: MusicalNotesService,
    public serialPubService: SerialPubInputService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.allRentsFromDB = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.getUsers();
    this.loadSerialPubs();
  }

  loadAllRents() {
    if (this.userObject) {
      return this.musicalNotesService
        .getSerialPubRents(this.schoolName)
        .subscribe((list) => {
          this.allRentsFromDB = list.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val(),
            };
          });

          this.getSerialPubs();
          this.loadTable();
        });
    }
  }

  loadSerialPubs() {
    const schoolName = this.route.snapshot.paramMap.get("NazivSkole");

    this.serialPubService.getSerialPubs(schoolName).subscribe((list) => {
      this.serialPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  /* TABLE */
  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = [
    "Korisnik",
    "Email",
    "Naziv",
    "Date",
    "Time",
    "Status",
    "Options",
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  loadTable() {
    this.listData = new MatTableDataSource(this.filteredSerialPubRents);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;
  }

  /* GETS DATA FOR SPECIFIC MUSICAL NOTE */

  rentKeys: any = [];
  getSerialPubs() {
    let index = 0;

    this.rentKeys = [];
    this.serialPubRents = [];

    //Iteration over every object on the same level of data structure
    for (const key in this.allRentsFromDB) {
      let element: {
        sPElement: any;
        index: number;
        returnedCounter: number;
      } = {
        sPElement: this.allRentsFromDB[key],
        index: index++,
        returnedCounter: 0,
      };

      //COUNTER FOR RETURNED RENTS
      for (const invkey in element.sPElement.SerialPub.invStatusi) {
        if (
          element.sPElement.SerialPub.invStatusi[invkey].statusi === "Vraćeno"
        )
          element.returnedCounter++;
      }

      this.serialPubRents.push(element);
      this.rentKeys.push(this.allRentsFromDB[key].$key);
    }

    //FILTERING DATA IF COMPONENT ACCESSED FROM PROFILE
    if (this.musicalNotesService.fromProfile) {
      this.serialPubRents = this.serialPubRents.filter(
        (el) => el.sPElement.User.email === this.userObject.email
      );

      //ADDING NEW INDEX FOR PERSONAL RENTS
      for (let i = 0; i < this.serialPubRents.length; i++)
        this.serialPubRents[i].index = i;
    }

    this.filteredSerialPubRents = this.serialPubRents;
    this.setTotalSerialPubs();
  }

  /* USERS FUNCTIONS */
  getUsers() {
    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          if (!this.schoolName) {
            this.schoolName = this.userObject.School.Naziv;
            this.musicalNotesService.fromProfile = true;
          }
          this.loadAllRents();
        });
      }
    });
  }

  onOpenSerialPubReport(index) {
    this.dialog.open(SerialPubReportComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        selectedSerialPub: this.serialPubRents[index].sPElement,
        serialPubKey: this.serialPubRents[index].sPElement.serialPubKey,
        rentKey: this.rentKeys[index],
        fromProfile: this.musicalNotesService.fromProfile,
      },
    });
  }

  /* SEARCHING ALGORITHM */

  applyFilter() {
    this.filteredSerialPubRents = this.serialPubsFiltered();
    this.listData.data = this.filteredSerialPubRents;
    this.setTotalSerialPubs();
  }

  serialPubsFiltered() {
    return this.serialPubRents.filter(
      (sP) =>
        sP.sPElement.TimeOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.DateOfRenting.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.email
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.firstname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.User.lastname
          .toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1 ||
        sP.sPElement.SerialPub.Naslov.toString()
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) !== -1
    );
  }

  refresh() {
    this.searchKey = "";
    this.filteredSerialPubRents = this.serialPubRents;
    this.listData.data = this.filteredSerialPubRents;
  }

  /* STATISTICS */

  totalSerialPubs: number = 0;
  rentedSerialPubs: number = 0;

  setTotalSerialPubs() {
    this.totalSerialPubs = 0;
    this.rentedSerialPubs = 0;

    this.filteredSerialPubRents.forEach((element) => {
      this.totalSerialPubs += element.sPElement.ListaRentovanja.length;

      if (Array.isArray(element.sPElement.SerialPub.invStatusi))
        this.rentedSerialPubs += element.sPElement.SerialPub.invStatusi.filter(
          (lele) => lele.statusi === "Vraćeno"
        ).length;
      else {
        if (element.sPElement.SerialPub.invStatusi.statusi === "Vraćeno") {
          this.rentedSerialPubs++;
        }
      }
    });
  }

  onOpenMonoPubReport() {
    this.router.navigate(["/monografskePublikacijeIzvestaji", this.schoolName]);
  }

  onOpenSerialPubReportRouter() {
    this.router.navigate(["/izvestaji", this.schoolName]);
  }

  onOpenMusicalPubReport() {
    this.router.navigate(["/muzikalijeIzvestaji", this.schoolName]);
  }
  onOpenSoundPubReport() {
    this.router.navigate(["/zvucnaGradjaIzvestaji", this.schoolName]);
  }

  onOpenPrintConfiguration() {
    this.dialog.open(PrintViewConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        category: "serijska",
        reports: this.allRentsFromDB,
      },
    });
  }

  onOpenPrintForBook() {
    this.dialog.open(PrintBookConfigurationComponent, {
      // disableClose: false,
      // autoFocus: true,
      width: "",
      height: "",

      data: {
        columns: this.columns,
      },
    });
  }

  checkedCounter() {
    return this.columns.filter((item) => item.checked);
  }

  //HARD-CODED DATA

  columns: any = [
    { name: "Datum", checked: true },
    {
      name: "Autor, Naslov, Mesto izdavanja, Izdavač, Godina izdavanja",
      checked: true,
    },
    { name: "Povez", checked: true },
    { name: "Dimenzije", checked: true },
    { name: "Način nabavke", checked: true },
    { name: "Cena", checked: true },
    { name: "Signatura", checked: true },
    { name: "Napomena", checked: true },
    { name: "Podnaslov", checked: false },
    { name: "Sadržaj", checked: false },
    { name: "Godište sveske", checked: false },
    { name: "Prateći dokument", checked: false },
    { name: "UDK", checked: false },
    { name: "ISBN", checked: false },
    { name: "ISMN", checked: false },
    { name: "Link publikacije Cobiss", checked: false },
  ];
}
