import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalNotesService } from "../../musical-notes.service";

@Component({
  selector: "app-print-views",
  templateUrl: "./print-views.component.html",
  styleUrls: ["./print-views.component.css"],
})
export class PrintViewsComponent implements OnInit {
  category: string = "";
  props: any[] = [];
  pubs: any[] = [];

  invStatuses: any[] = [];
  constructor(
    private musicalNotesService: MusicalNotesService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.category = this.musicalNotesService.categoryForPrint;
    this.props = this.musicalNotesService.selectedPropsForPubView;
    this.pubs = this.musicalNotesService.dataForPubViewPrint;
    // console.log(this.category);
    // console.log(this.props);
    // console.log(this.pubs);
  }
}
