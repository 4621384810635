import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/user/user.service";
import {
  MatDialog,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from "@angular/material";
import { ThrowStmt } from "@angular/compiler";
import { SharedClass } from "src/app/shared/shared-functions";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  user: firebase.User;
  userObject: User;
  files: any;

  schools;

  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  constructor(
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.auth.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.auth.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
        });
        this.dialog.closeAll();
        this.sharedClass.oneImagedownloadUrl = [];
        this.getMusicalSchools();
      }
    });
  }

  getMusicalSchools() {
    this.auth.getMusicalSchools().subscribe((list) => {
      this.schools = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.schools.forEach((element) => {
        this.options.push(element.Naziv);
      });
    });
  }

  @ViewChild("Skola", { static: false }) selectedSchool: any;

  changeTheSchoolOfSuperAdmin() {
    let nameOfSchool = this.selectedSchool.nativeElement.value;
    let school = this.schools.filter((item) => item.Naziv === nameOfSchool);

    this.auth.changeTheSchoolForSuperAdmin(this.user.uid, school[0]);
  }

  login() {
    this.router.navigate(["/login"]);
  }

  logout() {
    this.auth.logedUser = {};
    this.auth.logout();
  }

  register() {
    this.router.navigate(["/register"]);
  }

  uploadImage(event) {
    this.sharedClass.uploadImage(event, "users");
  }

  deletePhoto() {
    this.auth.updateUserProfile(this.user.uid, "");
    this.sharedClass.deletePhotoFromFirebaseStorage(this.userObject.imageUrl);
  }

  resetPassword() {
    if (this.auth.resetPassword(this.userObject.email))
      this.sharedClass.openSnackBar(
        "Poruka",
        "Link za reset lozinke vam je poslat na email adresu: " +
          this.userObject.email
      );
  }
}
