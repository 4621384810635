import { Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, FormArray } from "@angular/forms";
import { MusicalNotesService } from "../../musical-notes.service";
import { SharedClass } from "src/app/shared/shared-functions";
import { ActivatedRoute } from "@angular/router";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { SoundPubInputService } from "../input-services/sound-pub-input.service";
import { MonoPubInputService } from "../input-services/mono-pub-input.service";
import { MusicalPubInputService } from "../input-services/musical-pub-input.service";
import { SerialPubInputService } from "../input-services/serial-pub-input.service";

@Component({
  selector: "app-sound-material-input",
  templateUrl: "./sound-material-input.component.html",
  styleUrls: ["./sound-material-input.component.css"],
})
export class SoundMaterialInputComponent implements OnInit {
  authors: any = [];
  places: any = [];
  purchaseTypes: any = [];
  udks: any = [];
  titles: any = [];
  publishers: any = [];
  docCategories: any = [];
  bindings: any = [];
  participants: any = [];

  selectedStatus = "Izdato";
  schoolName: string = "";
  myForm: FormGroup;
  soundPubElement: any = {};
  files: any;
  formStructure() {
    this.myForm = new FormGroup({
      Naslov: new FormControl(""),
      NaslovTranskripcija: new FormControl(""),
      Podnaslov: new FormControl(""),
      PodnaslovTranskripcija: new FormControl(""),
      Autor: new FormControl(""),
      AutorTranskripcija: new FormControl(""),
      Ucesnici: new FormControl(""),
      UcesniciTranskripcija: new FormControl(""),
      Datum: new FormControl(new Date()),
      Mesto: new FormControl(""),
      Polica: new FormControl(""),
      UDK: new FormControl(""),
      GodinaIzdavanja: new FormControl(""),
      GodisteSveske: new FormControl(""),
      Dimenzije: new FormControl(""),
      Povez: new FormControl("Povezano"),
      NacinNabavke: new FormControl("Kupovina"),
      PrateciDokument: new FormControl(""),
      Cena: new FormControl(""),
      ISBN: new FormControl(""),
      ISMN: new FormControl(""),
      Signatura: new FormControl(""),
      Sadrzaj: new FormControl(""),
      Napomena: new FormControl(""),
      LinkPublikacije: new FormControl(""),
      invStatusi: this.fb.array([]),
      imagesURLs: new FormControl(""),
      Izdavac: new FormControl(""),
      VrstaDokumenta: new FormControl(""),
      BrojDokumenta: new FormControl(""),
      PropratnaGradja: new FormControl(""),
      TehnikaZvSnimka: new FormControl(""),
    });
  }
  constructor(
    private musicalNotesService: MusicalNotesService,
    private soundInputService: SoundPubInputService,
    private monoPubService: MonoPubInputService,
    private musicalPubService: MusicalPubInputService,
    private serialPubService: SerialPubInputService,
    public sharedClass: SharedClass,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {}
  ngOnInit() {
    this.sharedClass.oneImagedownloadUrl = [];
    this.schoolName = this.route.snapshot.paramMap.get("NazivSkole");
    this.formStructure();
    this.loadSoundPubForEdit();
    this.loadSoundMaterialPubs();
    this.loadAuthors();
    this.loadPlaces();
    this.loadPublishers();
    this.loadPurchaseTypes();
    this.loadUdks();
    this.loadTitles();
    this.loadDocCategories();
    this.loadBindings();
    this.loadParticipants();

    //adding first input
    this.invStatusi.clear();
  }
  patchValues() {
    this.myForm.patchValue({
      Naslov: this.soundPubElement.Naslov,
      NaslovTranskripcija: this.soundPubElement.NaslovTranskripcija,
      Podnaslov: this.soundPubElement.Podnaslov,
      PodnaslovTranskripcija: this.soundPubElement.PodnaslovTranskripcija,
      Autor: this.soundPubElement.Autor,
      AutorTranskripcija: this.soundPubElement.AutorTranskripcija,
      Ucesnici: this.soundPubElement.Ucesnici,
      UcesniciTranskripcija: this.soundPubElement.UcesniciTranskripcija,
      Mesto: this.soundPubElement.Mesto,
      Polica: this.soundPubElement.Polica,
      Datum: new Date(this.soundPubElement.Datum),
      UDK: this.soundPubElement.UDK,
      GodinaIzdavanja: this.soundPubElement.GodinaIzdavanja,
      GodisteSveske: this.soundPubElement.GodisteSveske,
      Dimenzije: this.soundPubElement.Dimenzije,
      NacinNabavke: this.soundPubElement.NacinNabavke,
      PrateciDokument: this.soundPubElement.PrateciDokument,
      Cena: this.soundPubElement.Cena,
      ISBN: this.soundPubElement.ISBN,
      ISMN: this.soundPubElement.ISMN,
      Signatura: this.soundPubElement.Signatura,
      Sadrzaj: this.soundPubElement.Sadrzaj,
      Napomena: this.soundPubElement.Napomena,
      LinkPublikacije: this.soundPubElement.LinkPublikacije,
      invStatusi: this.soundPubElement.invStatusi,
      imagesURLs: this.soundPubElement.imagesURLs,
      BrojSveske: this.soundPubElement.BrojSveske,
      Izdavac: this.soundPubElement.Izdavac,
      VrstaDokumenta: this.soundPubElement.VrstaDokumenta,
      BrojDokumenta: this.soundPubElement.BrojDokumenta,
      PropratnaGradja: this.soundPubElement.PropratnaGradja,
      TehnikaZvSnimka: this.soundPubElement.TehnikaZvSnimka,
    });
    this.soundPubElement.invStatusi.forEach((element) => {
      const invStatusi = this.fb.group({
        statusi: element.statusi,
        invBrojevi: element.invBrojevi,
      });
      this.invStatusi.push(invStatusi);
    });
    if (this.soundPubElement.imagesURLs)
      this.sharedClass.oneImagedownloadUrl = this.soundPubElement.imagesURLs;
  }
  onSubmit(form: FormGroup) {
    try {
      if (form.valid) {
        form = this.makeAModel(form);
        form.value.imagesURLs = this.sharedClass.oneImagedownloadUrl;
        //functions which checks if autocomplete values already exists, and if don't it will insert it
        if (!this.data) {
          this.soundInputService.insertSoundMaterial(
            this.schoolName,
            form.value
          );
        } else {
          this.soundInputService.updateSoundPub(
            this.schoolName,
            this.data.key,
            form.value
          );
          this.dialog.closeAll();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      //RESETING VALUES FROM INPUT
      this.resetForm(this.myForm);
      this.resetAutoCompletes();
      this.once = true;
      this.counter = 0;
      this.sharedClass.progressValue = 0;
      this.sharedClass.imageFiles = [];
      this.sharedClass.downloadURLs = [];
      this.sharedClass.fileCounter = 0;
      this.sharedClass.oneImagedownloadUrl = [];
      this.ngOnInit();
    }
  }
  /* LOADING DATA FROM FIREBASE */
  loadAuthors() {
    this.musicalNotesService.getNotesAuthors().subscribe((list) => {
      this.authors = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.authors = this.authors.map((item) => item.Naziv);
    });
  }
  loadPlaces() {
    this.musicalNotesService.getPlaces().subscribe((list) => {
      this.places = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.places = this.places.map((item) => item.Naziv);
    });
  }
  loadPurchaseTypes() {
    this.musicalNotesService.getPurchaseTypes().subscribe((list) => {
      this.purchaseTypes = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.purchaseTypes = this.purchaseTypes.map((item) => item.Naziv);
    });
  }
  loadUdks() {
    this.musicalNotesService.getUdks().subscribe((list) => {
      this.udks = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.udks = this.udks.map((item) => item.Naziv);
    });
  }
  loadTitles() {
    this.musicalNotesService.getTitles().subscribe((list) => {
      this.titles = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.titles = this.titles.map((item) => item.Naziv);
    });
  }

  loadPublishers() {
    this.musicalNotesService.getPublishers().subscribe((list) => {
      this.publishers = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.publishers = this.publishers.map((item) => item.Naziv);
    });
  }

  loadDocCategories() {
    this.soundInputService.getDocCategories().subscribe((list) => {
      this.docCategories = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.docCategories = this.docCategories.map((item) => item.Naziv);
    });
  }
  loadBindings() {
    this.musicalNotesService.getBindings().subscribe((list) => {
      this.bindings = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.bindings = this.bindings.map((item) => item.Naziv);
    });
  }

  loadParticipants() {
    this.musicalNotesService.getParticipants().subscribe((list) => {
      this.participants = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.participants = this.participants.map((item) => item.Naziv);
    });
  }

  loadSoundPubForEdit() {
    //CHECKING IF THE KEY FOR EDITING WAS PROVIDED OR NOT
    if (this.data) {
      this.musicalNotesService
        .getSoundPubElement(this.data.SchoolName, this.data.key)
        .subscribe((item) => {
          this.soundPubElement = item;
          this.patchValues();
        });
      this.authService.userObject
        .valueChanges()
        .subscribe((item) => (this.schoolName = item.School.Naziv));
    }
  }
  /* UPLOADING IMAGE TO FIREBASE STORAGE */
  uploadImage(event) {
    this.sharedClass.uploadImage(event, "soundMaterial");
  }
  onDeletePhoto(url) {
    this.sharedClass.deletePhotoFromFirebaseStorage(url);
  }
  /* OTHER FUNCTIONS */

  mergeWithFollowingDoc() {
    this.inputPrateci.nativeElement.value =
      this.inputNacinNabavke.input.nativeElement.value + ", ";
  }
  soundPubs: any[] = [];
  once = true;
  /* LOADING DATA FROM FIREBASE */
  loadSoundMaterialPubs() {
    if (!this.schoolName) this.schoolName = this.data.SchoolName;
    this.musicalNotesService.getSoundPubs(this.schoolName).subscribe((list) => {
      this.soundPubs = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      this.invPubsCounter();
      if (this.once && !this.data) {
        this.addNewInvStatusi();
        this.once = false;
      }
    });
  }
  get invStatusi() {
    return this.myForm.get("invStatusi") as FormArray;
  }
  counter: number = 0;
  invPubsCounter() {
    this.soundPubs.forEach((element) => {
      this.counter += element.invStatusi.length;
    });
  }
  addNewInvStatusi() {
    const invStatusi = this.fb.group({
      statusi: ["Slobodno"],
      invBrojevi: [++this.counter],
    });
    this.invStatusi.push(invStatusi);
  }
  deleteInvStatusi(i) {
    this.invStatusi.removeAt(i);
    --this.counter;
  }
  //NEW INPUT WAY FOR AUTOCOMPLETES
  @ViewChild("Autor", { static: false }) inputAutor: any;
  @ViewChild("MestoIzdavanja", { static: false }) inputMesto: any;
  @ViewChild("NacinNabavke", { static: false }) inputNacinNabavke: any;
  @ViewChild("UDK", { static: false }) inputUdk: any;
  @ViewChild("Naslov", { static: true }) inputNaslov: any;
  @ViewChild("Izdavac", { static: true }) inputIzdavac: any;
  @ViewChild("Signatura", { static: false }) inputSignatura: any;
  @ViewChild("ISBN", { static: false }) inputISBN: any;
  @ViewChild("ISMN", { static: false }) inputISMN: any;
  @ViewChild("godinaIzdavanja", { static: false }) godinaIzdavanja: any;
  @ViewChild("Prateci", { static: false }) inputPrateci: any;
  @ViewChild("godisteSveskeAuto", { static: false }) godisteSveskeAuto: any;
  @ViewChild("Povez", { static: true }) inputPovez: any;
  @ViewChild("VrstaDokumenta", { static: false }) inputVrstaDok: any;
  @ViewChild("Ucesnici", { static: false }) inputUcesnici: any;

  makeAModel(form: FormGroup) {
    form.value.Autor = this.inputAutor.input.nativeElement.value;
    form.value.Mesto = this.inputMesto.input.nativeElement.value;
    form.value.NacinNabavke = this.inputNacinNabavke.input.nativeElement.value;
    form.value.UDK = this.inputUdk.input.nativeElement.value;
    form.value.Naslov = this.inputNaslov.input.nativeElement.value;
    form.value.Izdavac = this.inputIzdavac.input.nativeElement.value;
    form.value.Signatura = this.inputSignatura.nativeElement.value;
    form.value.GodinaIzdavanja = this.godinaIzdavanja.input.nativeElement.value;
    form.value.GodisteSveske = this.godisteSveskeAuto.input.nativeElement.value;
    form.value.Povez = this.inputPovez.input.nativeElement.value;
    form.value.VrstaDokumenta = this.inputVrstaDok.input.nativeElement.value;
    form.value.Ucesnici = this.inputUcesnici.input.nativeElement.value;

    this.authorAutoCompleteCheck(form.value.Autor);
    this.placeAutoCompleteCheck(form.value.Mesto);
    this.purchaseTypeCompleteCheck(form.value.NacinNabavke);
    this.UDKAutoCompleteCheck(form.value.UDK);
    this.titleCompleteCheck(form.value.Naslov);
    this.publisherCompleteCheck(form.value.Izdavac);
    this.docCategoryCompleteCheck(form.value.VrstaDokumenta);
    this.bindingCompleteCheck(form.value.Povez);
    this.participantCompleteCheck(form.value.Ucesnici);

    return form;
  }
  resetAutoCompletes() {
    this.inputAutor.input.nativeElement.value = "";
    this.inputMesto.input.nativeElement.value = "";
    this.inputNacinNabavke.input.nativeElement.value = "";
    this.inputUdk.input.nativeElement.value = "";
    this.inputNaslov.input.nativeElement.value = "";
    this.godinaIzdavanja.input.nativeElement.value = "";
    this.godisteSveskeAuto.input.nativeElement.value = "";
    this.inputPovez.input.nativeElement.value = "";
    this.inputUcesnici.input.nativeElement.value = "";
  }
  /* CHECKING IF AUTOCOMPLETE VALUES ALREADY EXISTS */
  authorAutoCompleteCheck(author) {
    if (
      !(this.authors.some((item) => item === author) ? true : false) &&
      author.trim() !== ""
    )
      this.musicalNotesService.insertAuthor(author);
  }
  placeAutoCompleteCheck(place) {
    if (
      !(this.places.some((item) => item === place) ? true : false) &&
      place.trim() !== ""
    )
      this.musicalNotesService.insertPlace(place);
  }
  purchaseTypeCompleteCheck(type) {
    if (
      !(this.purchaseTypes.some((item) => item === type) ? true : false) &&
      type.trim() !== ""
    )
      this.musicalNotesService.insertPurchaseType(type);
  }
  UDKAutoCompleteCheck(UDK) {
    if (
      !(this.udks.some((item) => item === UDK) ? true : false) &&
      UDK.trim() !== ""
    )
      this.musicalNotesService.insertUDK(UDK);
  }
  titleCompleteCheck(titleName) {
    if (
      !(this.titles.some((item) => item === titleName) ? true : false) &&
      titleName.trim() !== ""
    )
      this.musicalNotesService.insertTitle(titleName);
  }

  publisherCompleteCheck(name) {
    if (
      !(this.publishers.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertPublisher(name);
  }
  docCategoryCompleteCheck(name) {
    if (
      !(this.docCategories.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.soundInputService.insertDocCategory(name);
  }

  bindingCompleteCheck(name) {
    if (
      !(this.bindings.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertBinding(name);
  }

  participantCompleteCheck(name) {
    if (
      !(this.participants.some((item) => item === name) ? true : false) &&
      name.trim() !== ""
    )
      this.musicalNotesService.insertParticipant(name);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  //CHANGING THE CATEGORY OF THE CURRENT PUBLICATION
  pubs: any = [
    { name: "Serijska publikacija" },
    { name: "Monografska publikacija" },
    { name: "Muzikalije" },
    { name: "Zvučna građa" },
  ];
  selectedPub = "Zvučna građa";
  changePublicationCategory(selectedPub) {
    try {
      if (selectedPub === "Serijska publikacija") {
        // FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.serialPubService.insertSerialPub(
          this.schoolName,
          this.myForm.value
        );
      }
      if (selectedPub === "Monografska publikacija") {
        //FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.monoPubService.insertNewMonoPub(
          this.schoolName,
          this.myForm.value
        );
      }
      if (selectedPub === "Muzikalije") {
        //FIRSTLY, INSERT THIS DATA TO MONO PUB PUBS NODE
        this.musicalPubService.insertMusicalElement(
          this.schoolName,
          this.myForm.value
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      // DELETING THIS NODE FROM CURRENT PUB NODE
      this.soundInputService.deleteSoundPub(this.schoolName, this.data.key);
      this.closeDialog();
    }
  }

  resetForm(form: FormGroup) {
    form.reset(this.formStructure());
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }
}
