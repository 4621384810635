import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { MusicalNotesService } from "../../musical-notes.service";

@Component({
  selector: "app-print-configuration",
  templateUrl: "./print-configuration.component.html",
  styleUrls: ["./print-configuration.component.css"],
})
export class PrintConfigurationComponent implements OnInit {
  dataForPrinting: any;

  constructor(
    private musicalNotesService: MusicalNotesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}
  ngOnInit() {
    //data for printing
    this.dataForPrinting = this.data.filteredPubs;
  }

  showLoading: boolean = false;

  sendDataForPrint() {
    this.showLoading = true;

    setTimeout(() => {
      let finalObject: {
        selectedProperties: any;
        dataForPrint: any;
        selectedCategories: any;
      } = {
        selectedProperties: this.intersectedColumns,
        dataForPrint: this.dataForPrinting,
        selectedCategories: this.pubCategories,
      };
      this.musicalNotesService.dataForPrintAllPubs = finalObject;
      this.dialog.closeAll();
      this.router.navigate(["/stampaPublikacija"]);
    }, 200);
  }

  checkedChanged(event, name, array) {
    for (let i = 0; i < array.length; i++) {
      if (event.checked) {
        if (array[i].name === name) {
          array[i].selected = true;
        }
      } else {
        if (array[i].name === name) {
          array[i].selected = false;
        }
      }
    }
  }

  intersectedColumns: any[] = [
    { name: "Datum", selected: true },
    { name: "Autor", selected: true },
    { name: "Naslov", selected: true },
    { name: "Mesto izdavanja", selected: true },
    { name: "Izdavač", selected: true },
    { name: "Godina izdavanja", selected: true },
    { name: "Povez", selected: true },
    { name: "Dimenzije", selected: true },
    { name: "Način nabavke", selected: true },
    { name: "Cena", selected: true },
    { name: "Signatura", selected: true },
    { name: "Napomena", selected: true },
    { name: "Godište sveske", selected: false },
    { name: "Prateći dokument", selected: false },
    { name: "Polica", selected: false },
    { name: "UDK", selected: false },
    { name: "ISBN", selected: false },
    { name: "ISMN", selected: false },
    { name: "Link", selected: false },
    { name: "Sadržaj", selected: false },
    { name: "QR kod", selected: false },
  ];

  pubCategories: any[] = [
    { name: "Serijske publikacije", selected: true },
    {
      name: "Monografske publikacije",
      selected: true,
      columns: [{ name: "Broj sveske, toma", selected: false }],
    },
    { name: "Muzikalije", selected: true },
    {
      name: "Zvučna građa",
      selected: true,
      columns: [
        { name: "Vrsta dokumenta", selected: false },
        { name: "Broj dokumenta", selected: false },
        { name: "Propratna građa", selected: false },
        { name: "Tehnika zvučnog snimka", selected: false },
      ],
    },
  ];
}
