<div>
  <mat-card>
    <div style="display: flex; flex-direction: row">
      <mat-form-field style="width: 100% !important" class="example-full-width">
        <mat-label>Pretraga</mat-label>
        <input
          matInput
          placeholder="Pretraga"
          value=""
          #allSearchInput
          (keyup)="searchSerialPubs()"
          (keyup)="searchMonoPubs()"
          (keyup)="searchMusicalPubs()"
          (keyup)="searchSoundPubs()"
        />
      </mat-form-field>

      <button
        mat-stroked-button
        style="margin: 1%"
        (click)="onOpenPrintConfiguration()"
      >
        <mat-icon>print</mat-icon>
      </button>

      <button
        *ngIf="!tableViewShow"
        mat-stroked-button
        style="margin: 1%"
        (click)="onChangeView()"
      >
        <mat-icon>list_view</mat-icon>
      </button>

      <button
        *ngIf="tableViewShow"
        mat-stroked-button
        style="margin: 1%"
        (click)="onChangeView()"
      >
        <mat-icon>table_view</mat-icon>
      </button>
    </div>
    <mat-accordion mat-list-item>
      <mat-expansion-panel>
        <mat-expansion-panel-header
          style="background-color: #673ab7"
          (click)="loadDataForAdvancedSearch()"
        >
          <mat-panel-title style="color: white">
            <p>Napredna pretraga</p>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="advanced-search-container">
          <!-- Autor -->
          <div style="margin-left: 2%">
            <app-autocomplete-without-tab
              [options]="authors"
              [placeHolder]="'Autor ime i prezime'"
              #Autor
              (keyup)="searchByProperties()"
            ></app-autocomplete-without-tab>
          </div>
          <!-- Transkripcija autora -->
          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Transkripcija autora</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Transkripcija autora"
              value=""
              #AuthorTranscription
            />
          </mat-form-field>
          <!-- Učesnici u realizaciji -->
          <div style="margin-left: 2%">
            <app-autocomplete-without-tab
              [options]="participants"
              [placeHolder]="'Učesnici u realizaciji'"
              #Ucesnici
              (keyup)="searchByProperties()"
            ></app-autocomplete-without-tab>
          </div>

          <!-- Transkripcija učesnika u realizaciji -->
          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Transkripcija učesnika u realizaciji</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Transkripcija učesnika u realizaciji"
              value=""
              #UcesniciTranscription
            />
          </mat-form-field>

          <!-- Naslov -->
          <div style="margin-left: 2%">
            <app-autocomplete-without-tab
              [options]="titles"
              [placeHolder]="'Naslov'"
              (keyup)="searchByProperties()"
              #Naslov
            ></app-autocomplete-without-tab>
          </div>
          <!-- Transkripcija naslova -->
          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Transkripcija naslova</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Transkripcija naslova"
              value=""
              #TitleTranscription
            />
          </mat-form-field>
          <!-- Podnaslov -->
          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Podnaslov</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Podnaslov"
              value=""
              #SubTitle
            />
          </mat-form-field>

          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Transkripcija podnaslova</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Transkripcija podnaslova"
              value=""
              #SubTitleTrans
            />
          </mat-form-field>
          <!-- Izdavac -->
          <div style="margin-left: 2%">
            <app-autocomplete-without-tab
              [options]="publishers"
              [placeHolder]="'Izdavač'"
              (keyup)="searchByProperties()"
              #Izdavac
            ></app-autocomplete-without-tab>
          </div>
          <!-- Izdavac -->

          <mat-form-field style="margin-left: 2%" class="example-full-width">
            <mat-label>Sadržaj</mat-label>
            <input
              (keyup)="searchByProperties()"
              matInput
              placeholder="Sadržaj"
              value=""
              #Sadrzaj
            />
          </mat-form-field>

          <!-- Godina izdavanja -->
          <div style="margin-left: 2%">
            <app-autocomplete-without-tab
              [options]="sharedClass.yearsOfProduction"
              [placeHolder]="'Godina izdavanja'"
              (keyup)="searchByProperties()"
              #godinaIzdavanja
            ></app-autocomplete-without-tab>
          </div>
          <button mat-stroked-button style="margin: 1%" (click)="resetSearch()">
            <mat-icon>redo</mat-icon>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  <div [hidden]="!tableViewShow" *ngIf="tableViewShow">
    <app-table-view
      [filteredSerialPubs]="serialPubListView?.filteredSerialPubs || undefined"
      [filteredMonoPubs]="monoPubListView?.filteredMonoPubs || undefined"
      [filteredMusicalPubs]="musicalListView?.filteredMusicalPubs || undefined"
      [filteredSoundPubs]="soundListView?.filteredSoundPubs || undefined"
      [acessedFrom]="acessedFrom"
      [selectedIndex]="selectedIndex"
      (selectedIndexChange)="changeTheTab($event)"
    ></app-table-view>
  </div>

  <div [hidden]="tableViewShow" *ngIf="!tableViewShow">
    <mat-tab-group
      (selectedTabChange)="changeAcessFrom($event)"
      style="position: sticky"
      [selectedIndex]="selectedIndex"
    >
      <!-- Sve -->
      <mat-tab label="Sve">
        <ng-template mat-tab-label>
          <p>
            <span
              matBadge="{{
                serialPubListView?.filteredSerialPubs?.length +
                  monoPubListView?.filteredMonoPubs?.length +
                  musicalListView?.filteredMusicalPubs?.length +
                  soundListView?.filteredSoundPubs?.length
              }}"
              matBadgeOverlap="false"
            >
              Sve
            </span>
          </p>
        </ng-template>
        <!-- Serijske publikacije -->
        <h5 *ngIf="serialPubListView?.filteredSerialPubs?.length > 0">
          Serijske publikacije
        </h5>
        <app-serial-pub-list
          [filteredSerialPubs]="filteredSerialPubs"
          [serialPubs]="serialPubs"
          #SerialPubList
        ></app-serial-pub-list>
        <!-- Monografske publikacije -->
        <h5 *ngIf="monoPubListView?.filteredMonoPubs?.length > 0">
          Monografske publikacije
        </h5>
        <app-mono-pub-list
          [filteredMonoPubs]="filteredMonoPubs"
          [monoPubs]="monoPubs"
          #MonoPubList
        ></app-mono-pub-list>
        <!-- Muzikalije -->
        <h5 *ngIf="musicalListView?.filteredMusicalPubs?.length > 0">
          Muzikalije
        </h5>
        <app-musical-pub-list
          [filteredMusicalPubs]="filteredMusicalPubs"
          [musicalPubs]="musicalPubs"
          #MusicalPubList
        ></app-musical-pub-list>
        <!-- Zvučna građa -->
        <h5 *ngIf="soundListView?.filteredSoundPubs?.length > 0">
          Zvučna građa
        </h5>
        <app-sound-pub-list
          [filteredSoundPubs]="filteredSoundPubs"
          [soundPubs]="soundPubs"
          #SoundPubList
        ></app-sound-pub-list>
      </mat-tab>
      <!-- Serijske publikacije -->
      <mat-tab label="Serijske publikacije">
        <ng-template mat-tab-label>
          <p>
            <span
              matBadge="{{ serialPubListView?.filteredSerialPubs?.length }}"
              matBadgeOverlap="false"
            >
              Serijske publikacije
            </span>
          </p>
        </ng-template>
        <!-- Serijske publikacije -->
        <app-serial-pub-list
          [filteredSerialPubs]="serialPubListView?.filteredSerialPubs"
          [serialPubs]="serialPubListView?.serialPubs"
        ></app-serial-pub-list>
      </mat-tab>
      <!-- Monografske publikacije -->
      <mat-tab label="Monografske publikacije">
        <ng-template mat-tab-label>
          <p>
            <span
              matBadge="{{ monoPubListView?.filteredMonoPubs?.length }}"
              matBadgeOverlap="false"
            >
              Monografske publikacije
            </span>
          </p>
        </ng-template>
        <app-mono-pub-list
          [filteredMonoPubs]="monoPubListView?.filteredMonoPubs"
          [monoPubs]="monoPubListView?.monoPubs"
        ></app-mono-pub-list>
      </mat-tab>
      <!-- Muzikalije -->
      <mat-tab label="Muzikalije">
        <ng-template mat-tab-label>
          <p>
            <span
              matBadge="{{ musicalListView?.filteredMusicalPubs?.length }}"
              matBadgeOverlap="false"
            >
              Muzikalije
            </span>
          </p>
        </ng-template>
        <app-musical-pub-list
          [filteredMusicalPubs]="musicalListView?.filteredMusicalPubs"
          [musicalPubs]="musicalListView?.musicalPubs"
        ></app-musical-pub-list>
      </mat-tab>
      <!-- Zvučna građa -->
      <mat-tab label="Zvučna građa">
        <ng-template mat-tab-label>
          <p>
            <span
              matBadge="{{ soundListView?.filteredSoundPubs?.length }}"
              matBadgeOverlap="false"
            >
              Zvučna građa
            </span>
          </p>
        </ng-template>
        <app-sound-pub-list
          [filteredSoundPubs]="soundListView?.filteredSoundPubs"
          [soundPubs]="soundListView?.soundPubs"
        ></app-sound-pub-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
