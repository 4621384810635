import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { MusicalNotesService } from "../../musical-notes.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-print-pubs",
  templateUrl: "./print-pubs.component.html",
  styleUrls: ["./print-pubs.component.css"],
})
export class PrintPubsComponent implements OnInit {
  configuredData: any;
  isTypeOfDocumentSelected = false;
  isNumberOfDocumentSelected = false;
  constructor(
    private musicalNotesService: MusicalNotesService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.configuredData = this.musicalNotesService.dataForPrintAllPubs;
    this.isTypeOfDocumentSelected = this.configuredData.selectedCategories[3].columns[0].selected;
    this.isNumberOfDocumentSelected = this.configuredData.selectedCategories[1].columns[0].selected;
  }

  serialPubs() {
    return this.configuredData.dataForPrint.filter(
      (item) => item.Tip === "Serijska"
    );
  }

  monoPubs() {
    return this.configuredData.dataForPrint.filter(
      (item) => item.Tip === "Monografska"
    );
  }

  musicalPubs() {
    return this.configuredData.dataForPrint.filter(
      (item) => item.Tip === "Muzikalije"
    );
  }
  soundPubs() {
    return this.configuredData.dataForPrint.filter(
      (item) => item.Tip === "ZvucnaGradja"
    );
  }

  isPublicationSelected(name: string) {
    let result = this.configuredData.selectedCategories.filter(
      (item) => item.name === name
    );
    return result[0].selected;
  }

  exportexcel(nameOfTable: string): void {
    let element = document.getElementById(nameOfTable);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName(nameOfTable));
  }

  fileName(nameOfTable): string {
    if (nameOfTable == "serialPubs-table") return "Serijske publikacije.xlsx";
    if (nameOfTable == "monoPubs-table") return "Monografske publikacije.xlsx";
    if (nameOfTable == "musicals-table") return "Muzikalije.xlsx";
    if (nameOfTable == "soundPubs-table") return "Zvučna građa.xlsx";
  }
}
