import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-musical-notes-inputs",
  templateUrl: "./musical-notes-inputs.component.html",
  styleUrls: ["./musical-notes-inputs.component.css"],
})
export class MusicalNotesInputsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
