import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class GuideService {
    constructor(firebase) {
        this.firebase = firebase;
        this.linksList = firebase.list("linkovi");
    }
    getLinks() {
        this.linksList = this.firebase.list("linkovi");
        return this.linksList.snapshotChanges();
    }
    insertLink(url, desc) {
        this.linksList = this.firebase.list("/linkovi");
        this.linksList.push({
            URL: url,
            Description: desc,
        });
    }
    deleteLink(key) {
        this.linksList.remove(key);
    }
}
GuideService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuideService_Factory() { return new GuideService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: GuideService, providedIn: "root" });
