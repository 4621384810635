<mat-tab-group
  (selectedTabChange)="changeAcessFrom($event)"
  style="position: sticky"
  [selectedIndex]="selectedIndex"
>
  <!-- Sve -->
  <mat-tab label="Sve">
    <ng-template mat-tab-label>
      <p>
        <span
          matBadge="{{
            filteredSerialPubs?.length +
              filteredMonoPubs?.length +
              filteredMusicalPubs?.length +
              filteredSoundPubs?.length
          }}"
          matBadgeOverlap="false"
        >
          Sve
        </span>
      </p>
    </ng-template>
  </mat-tab>
  <!-- Serijske publikacije -->
  <mat-tab label="Serijske publikacije">
    <ng-template mat-tab-label>
      <p>
        <span
          matBadge="{{ filteredSerialPubs?.length }}"
          matBadgeOverlap="false"
        >
          Serijske publikacije
        </span>
      </p>
    </ng-template>
    <!-- Serijske publikacije -->
  </mat-tab>
  <!-- Monografske publikacije -->
  <mat-tab label="Monografske publikacije">
    <ng-template mat-tab-label>
      <p>
        <span matBadge="{{ filteredMonoPubs?.length }}" matBadgeOverlap="false">
          Monografske publikacije
        </span>
      </p>
    </ng-template>
  </mat-tab>
  <!-- Muzikalije -->
  <mat-tab label="Muzikalije">
    <ng-template mat-tab-label>
      <p>
        <span
          matBadge="{{ filteredMusicalPubs?.length }}"
          matBadgeOverlap="false"
        >
          Muzikalije
        </span>
      </p>
    </ng-template>
  </mat-tab>
  <!-- Zvučna građa -->
  <mat-tab label="Zvučna građa">
    <ng-template mat-tab-label>
      <p>
        <span
          matBadge="{{ filteredSoundPubs?.length }}"
          matBadgeOverlap="false"
        >
          Zvučna građa
        </span>
      </p>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<div style="margin: 5%">
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
    matSort
  >
    <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <th mat-sort-header mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        style="cursor: pointer"
      >
        <ng-container *ngIf="column == 'R. br.' && element?.index">
          <b> {{ element?.index }}. </b>
        </ng-container>
        <!-- <ng-container *ngIf="column == 'Mesto izdavanja'">
          {{ element?.Mesto }}
        </ng-container>
        <ng-container *ngIf="column == 'Izdavač'">
          {{ element?.Izdavac }}
        </ng-container>
        <ng-container *ngIf="column == 'Godina izdavanja'">
          {{ element?.GodinaIzdavanja }}
        </ng-container> -->
        <ng-container>
          {{ element[column] }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <!-- prettier-ignore -->
          <div class="example-element-diagram">
            <div style="text-align: center;">
                <ng-container *ngIf="element?.Tip =='ZvucnaGradja'">
                    Zvučna građa
                </ng-container>
                <ng-container *ngIf="element?.Tip !='ZvucnaGradja'">
                    {{element?.Tip}}
                </ng-container>
            </div> 
            <qrcode
            [qrdata]="'Naslov - ' + element?.Naslov + '\n'
            +'Autor - ' + element?.Autor + '\n'
            +'Podnaslov - ' + element?.Podnaslov + '\n'
            +'Izdavač - ' + element?.Izdavac + '\n'
            +'Godina Izdavanja - ' + element?.GodinaIzdavanja + '\n'
            +'Polica - ' + element?.Polica + '\n'
            || 'Prazno'"
            [width]="120"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
          </div>

          <!-- prettier-ignore -->
          <div class="sub-row-data">
            <p *ngIf="element?.Podnaslov"><b> Datum</b>:   {{ element?.Datum.toString().split(" ")[2] }}.{{
                sharedClass.monthInNumber(element?.Datum.split(" ")[1])
              }}.{{ element?.Datum.split(" ")[3] }}.
            </p>
            <p *ngIf="element?.Povez"><b> Povez</b>: {{ element?.Podnaslov }}</p>
            <p *ngIf="element?.Dimenzije"><b> Dimenzije</b>: {{ element?.Dimenzije }}</p>
            <p *ngIf="element?.NacinNabavke"><b> Način nabavke</b>: {{ element?.NacinNabavke }}</p>
            <p *ngIf="element?.Cena"><b> Cena</b>: {{ element?.Cena }} RSD</p>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
