import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";

@Injectable({
  providedIn: "root",
})
export class SoundPubInputService {
  soundMaterials: AngularFireList<any>;
  docCategories: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.soundMaterials = firebase.list("serijskePublikacije");
  }

  getSoundMaterials() {
    this.soundMaterials = this.firebase.list("zvucneGradje");
    return this.soundMaterials.snapshotChanges();
  }
  getDocCategories() {
    this.docCategories = this.firebase.list("vrsteDokumenata");
    return this.docCategories.snapshotChanges();
  }

  insertDocCategory(naziv) {
    this.docCategories.push({
      Naziv: naziv,
    });
  }

  insertSoundMaterial(schoolName, formData) {
    this.soundMaterials = this.firebase.list(schoolName + "/zvucnaGradja");
    if (!formData.VrstaDokumenta) formData.VrstaDokumenta = "";
    if (!formData.BrojDokumenta) formData.BrojDokumenta = "";
    if (!formData.PropratnaGradja) formData.PropratnaGradja = "";
    if (!formData.TehnikaZvSnimka) formData.TehnikaZvSnimka = "";
    if (!formData.GodinaIzdavanja) formData.GodinaIzdavanja = "";
    if (!formData.GodisteSveske) formData.GodisteSveske = "";
    this.soundMaterials.push({
      Tip: "ZvucnaGradja",
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      Datum: formData.Datum.toString(),
      UDK: formData.UDK,
      GodinaIzdavanja: formData.GodinaIzdavanja,
      GodisteSveske: formData.GodisteSveske,
      Dimenzije: formData.Dimenzije,
      Povez: formData.Povez,
      NacinNabavke: formData.NacinNabavke,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Sadrzaj: formData.Sadrzaj,
      Signatura: formData.Signatura,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Izdavac: formData.Izdavac,
      VrstaDokumenta: formData.VrstaDokumenta,
      BrojDokumenta: formData.BrojDokumenta,
      PropratnaGradja: formData.PropratnaGradja,
      PrateciDokument: formData.PrateciDokument,
      TehnikaZvSnimka: formData.TehnikaZvSnimka,
    });
  }

  updateSoundPub(schoolName, key, formData) {
    this.firebase.object(schoolName + "/zvucnaGradja/" + key).update({
      Tip: "ZvucnaGradja",
      Naslov: formData.Naslov,
      NaslovTranskripcija: formData.NaslovTranskripcija,
      Podnaslov: formData.Podnaslov,
      PodnaslovTranskripcija: formData.PodnaslovTranskripcija,
      Autor: formData.Autor,
      AutorTranskripcija: formData.AutorTranskripcija,
      Ucesnici: formData.Ucesnici,
      UcesniciTranskripcija: formData.UcesniciTranskripcija,
      Mesto: formData.Mesto,
      Polica: formData.Polica,
      UDK: formData.UDK,
      Datum: formData.Datum.toString(),
      GodinaIzdavanja: formData.GodinaIzdavanja,
      GodisteSveske: formData.GodisteSveske,
      Dimenzije: formData.Dimenzije,
      Povez: formData.Povez,
      NacinNabavke: formData.NacinNabavke,
      Cena: formData.Cena,
      ISBN: formData.ISBN,
      ISMN: formData.ISMN,
      Signatura: formData.Signatura,
      Sadrzaj: formData.Sadrzaj,
      Napomena: formData.Napomena,
      LinkPublikacije: formData.LinkPublikacije,
      invStatusi: formData.invStatusi,
      imagesURLs: formData.imagesURLs,
      Izdavac: formData.Izdavac,
      VrstaDokumenta: formData.VrstaDokumenta,
      BrojDokumenta: formData.BrojDokumenta,
      PropratnaGradja: formData.PropratnaGradja,
      PrateciDokument: formData.PrateciDokument,
      TehnikaZvSnimka: formData.TehnikaZvSnimka,
    });
  }

  deleteSoundPub(schoolName, key) {
    this.firebase.database.ref(schoolName + "/zvucnaGradja/" + key).remove();
  }
}
