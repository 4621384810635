<ng-container *ngFor="let prop of columns">
  <mat-checkbox
    [checked]="prop?.checked"
    (change)="checkedChanged($event, prop.name)"
  >
    {{ prop?.name }}
  </mat-checkbox>
  <br />
</ng-container>
<br />
