import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
import { UserService } from "src/app/user/user.service";
import { MusicalNotesService } from "../../musical-notes.service";

@Component({
  selector: "app-musical-pub-rent",
  templateUrl: "./musical-pub-rent.component.html",
  styleUrls: ["./musical-pub-rent.component.css"],
})
export class MusicalPubRentComponent implements OnInit {
  musicalPubElement: any;
  leftRentingCounter: number = 0;

  /* USER VARIABLES */
  user: firebase.User;
  userObject: User = {};
  usersTable: User[];
  listData: MatTableDataSource<any>;
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  searchKey = "";
  clickedClear = "clicked";
  displayedColumns: string[] = ["email", "ImePrezime", "Odaberi"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  i = 0;

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  constructor(
    private musicalNotesService: MusicalNotesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    /* GET MUSICAL PUB ELEMENT */
    this.musicalNotesService
      .getMusicalPubElement(this.data.SchoolName, this.data.key)
      .subscribe((item) => {
        this.musicalPubElement = item;
        this.leftRentingCounter = item.invStatusi.filter(
          (el) => el.statusi === "Slobodno"
        ).length;
      });

    /* GETS ALL THE USERS IN THE SCHOOL SYSTEM */

    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService.getUserObject(user.uid).subscribe((item) => {
          this.userObject = item;
          this.loadAllUsers();
        });
      }
    });
  }

  loadAllUsers() {
    this.userService.getAllUsers().subscribe((list) => {
      this.usersTable = list
        .map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        })
        .filter((item: any) => {
          if (this.userObject.role === "SuperAdmin") {
            return item;
          } else if (this.userObject.role === "AdminSkole") {
            return (
              item.School.key === this.userObject.School.key ||
              item.role === "Eksterni korisnik"
            );
          }
        });
      this.listData = new MatTableDataSource(this.usersTable);
      this.listData.sort = this.matSort;
      this.listData.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  /* TAKING THE SELECTED RENT VALUES */
  mapRenting = new Map();
  userKey: string = "";

  checkBoxSerialPubsChanged(event, i) {
    if (event.checked)
      this.mapRenting.set(i, this.musicalPubElement.invStatusi[i]);
    else this.mapRenting.delete(i);
  }

  onSelectTheUser(event) {
    this.userKey = event.value;
  }
  onRentMusicalPub() {
    let renting: {
      User: any;
      MusicalPub: any;
      selectedMusicalPubs: any[];
      musicalPubKey: string;
    } = {
      User: {},
      MusicalPub: this.data.MusicalPub,
      selectedMusicalPubs: [],
      musicalPubKey: this.data.key,
    };

    let keysForUpdating = [];
    let iterator = this.mapRenting.keys();

    this.mapRenting.forEach((item) => {
      renting.selectedMusicalPubs.push(item);
      keysForUpdating.push(iterator.next().value);
    });

    let temp = [];

    keysForUpdating.forEach((index) => {
      temp[index] = renting.MusicalPub.invStatusi[index];
    });

    renting.MusicalPub.invStatusi = temp;

    delete renting.MusicalPub.$key;

    this.authService.userObject.valueChanges().subscribe((item) => {
      this.authService.getUserObject(this.userKey).subscribe((user) => {
        renting.User = user;

        this.musicalNotesService.insertRentMusicalPub(
          item.School.Naziv,
          renting
        );

        // UPDATING THE STATUS OF RENTED MUSICAL PUBS
        keysForUpdating.forEach((element) => {
          this.musicalNotesService.updateMusicalPubStatusesAfterRenting(
            item.School.Naziv,
            this.data.key,
            element
          );
        });

        this.closeDialog();
        this.ngOnInit();
      });
    });
  }

  /* OTHER FUNCTIONS */
  closeDialog() {
    this.dialog.closeAll();
  }
}
