<div flex style="margin: 5% 10%">
  <div style="text-align: center">
    <mat-accordion mat-list-item>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header style="background-color: #673ab7">
          <mat-panel-title style="color: white">
            <p>Unos</p>
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <mat-tab-group style="position: sticky">
            <mat-tab label="Serijske publikacije">
              <app-serial-pub-input></app-serial-pub-input>
            </mat-tab>
            <mat-tab label="Monografske publikacije">
              <app-mono-pub-input></app-mono-pub-input>
            </mat-tab>
            <mat-tab label="Muzikalije">
              <app-musicals-input></app-musicals-input>
            </mat-tab>
            <mat-tab label="Zvučna građa">
              <app-sound-material-input></app-sound-material-input>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
