import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-print-book-configuration",
  templateUrl: "./print-book-configuration.component.html",
  styleUrls: ["./print-book-configuration.component.css"],
})
export class PrintBookConfigurationComponent implements OnInit {
  columns: any = [];
  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.columns = this.data.columns;
  }
  checkedChanged(event, name) {
    for (let i = 0; i < this.columns.length; i++) {
      if (event.checked) {
        if (this.columns[i].name === name) {
          this.columns[i].checked = true;
        }
      } else {
        if (this.columns[i].name === name) {
          this.columns[i].checked = false;
        }
      }
    }
  }
}
