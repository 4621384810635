import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../auth.service";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  authError: any;
  @ViewChild("email", { static: true }) inputEmail: any;
  @ViewChild("password", { static: true }) inputPassword: any;
  constructor(private auth: AuthService, private dialog: MatDialog) {}

  ngOnInit() {
    this.auth.eventAuthError$.subscribe((data) => {
      this.authError = data;
    });
  }

  login() {
    this.auth.login(
      this.inputEmail.nativeElement.value,
      this.inputPassword.nativeElement.value
    );
  }
}
