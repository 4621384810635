<mat-card class="mat-elevation-z6" class="box" fxFlexAlign="stretch">
  <mat-card-header>
    <mat-card-title title="{{ monoPubElement?.Naslov }}">{{
      monoPubElement?.Naslov
    }}</mat-card-title>
  </mat-card-header>
  <ul class="image-qr">
    <li class="crop" (click)="onOpenEditDialog(monoPubElement.$key)">
      <div style="text-align: center; display: grid">
        <div>
          <img
            *ngIf="monoPubElement.imagesURLs"
            src="{{ (monoPubElement?.imagesURLs)[0] }}"
            alt="Nota"
          />
        </div>
      </div>
    </li>
    <li class="cropQR">
      <div>
        <!-- prettier-ignore -->
        <qrcode
          [qrdata]="'Monografska\nNaslov - ' + monoPubElement?.Naslov + '\n'
          +'Autor - ' + monoPubElement?.Autor + '\n'
          +'Podnaslov - ' + monoPubElement?.Podnaslov + '\n'
          +'Izdavač - ' + monoPubElement?.Izdavac + '\n'
          +'Godina Izdavanja - ' + monoPubElement?.GodinaIzdavanja + '\n'
          +'Polica - ' + monoPubElement?.Polica + '\n'
          || 'Prazno'"
          [width]="120"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </li>
  </ul>
  <mat-card-content>
    <p>
      Datum inventarisanja:
      <b>
        {{ monoPubElement?.Datum.toString().split(" ")[2] }}.
        {{ monoPubElement?.Datum.split(" ")[1] }}.
        {{ monoPubElement?.Datum.split(" ")[3] }}.
      </b>
    </p>
    <p>
      Autor: <b>{{ monoPubElement?.Autor }}</b>
    </p>
    <p>
      Podnaslov: <b>{{ monoPubElement?.Podnaslov }}</b>
    </p>
    <p>
      Broj sveske: <b>{{ monoPubElement?.BrojSveske }}</b>
    </p>
    <p>
      Mesto izdavanja: <b> {{ monoPubElement?.Mesto }}</b>
    </p>
    <p>
      Izdavač: <b> {{ monoPubElement?.Izdavac }}</b>
    </p>
    <p>
      Godište sveske: <b>{{ monoPubElement?.GodisteSveske }}</b>
    </p>
    <p>
      Godina izdavanja: <b> {{ monoPubElement?.GodinaIzdavanja }}</b>
    </p>
    <p>
      Dimenzije: <b>{{ monoPubElement?.Dimenzije }}</b>
    </p>
    <p>
      Povez: <b>{{ monoPubElement?.Povez }}</b>
    </p>
    <p>
      Način nabavke: <b>{{ monoPubElement?.NacinNabavke }}</b>
    </p>
    <p>
      Prateći dokument: <b>{{ monoPubElement?.BrojSveske }}</b>
    </p>
    <p>
      Cena: <b>{{ monoPubElement?.Cena }}</b> RSD
    </p>

    <p>
      UDK: <b> {{ monoPubElement?.UDK }}</b>
    </p>
    <p>
      ISBN: <b>{{ monoPubElement?.ISBN }}</b>
    </p>
    <p>
      ISMN: <b>{{ monoPubElement?.ISMN }}</b>
    </p>
    <p>
      Signatura: <b>{{ monoPubElement?.Signatura }}</b>
    </p>
    <p>
      Polica: <b>{{ monoPubElement?.Polica }}</b>
    </p>
    <p>
      Link Cobiss:
      <a href="{{ monoPubElement?.LinkPublikacije }}" target="_blank"
        >Otvori link</a
      >
    </p>
    <p>
      Sadržaj: {{ monoPubElement?.Sadrzaj.toString().substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(monoPubElement?.Sadrzaj)"
        *ngIf="monoPubElement.Sadrzaj.toString().length > 20"
        >... [više]
      </b>
    </p>
    <p>
      {{ monoPubElement?.Napomena?.substring(0, 100) }}
      <b
        (click)="onOpenFullRemark(monoPubElement?.Napomena)"
        *ngIf="monoPubElement.Napomena.length > 20"
        >... [više]
      </b>
    </p>
    <p style="font-size: larger">
      Broj svezaka: <b>{{ monoPubElement?.invStatusi.length }}</b>
    </p>
  </mat-card-content>
  <mat-card-actions style="text-align: center">
    <button
      *ngIf="
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
      mat-button
      (click)="onOpenEditDialog(monoPubElement.$key)"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-button (click)="onOpenMonoPubRent(monoPubElement.$key)">
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
