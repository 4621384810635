/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./home-page.component";
var styles_HomePageComponent = [i0.styles];
var RenderType_HomePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePageComponent, data: {} });
export { RenderType_HomePageComponent as RenderType_HomePageComponent };
export function View_HomePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["src", "assets/images/notes.png"], ["style", "min-height: 20%; min-width: 20%; width: auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://galis.rs/"], ["style", "margin: 0 auto"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "logo"], ["src", "assets/images/galis_logo.png"], ["style", "\n      max-height: 40%;\n      max-width: 40%;\n      width: auto;\n      height: auto;\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      left: 0;\n      right: 0;\n      margin: auto;\n    "]], null, null, null, null, null))], null, null); }
export function View_HomePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-page", [], null, null, null, View_HomePageComponent_0, RenderType_HomePageComponent)), i1.ɵdid(1, 114688, null, 0, i2.HomePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomePageComponentNgFactory = i1.ɵccf("app-home-page", i2.HomePageComponent, View_HomePageComponent_Host_0, {}, {}, []);
export { HomePageComponentNgFactory as HomePageComponentNgFactory };
