/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sound-pub-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pub-items/sound-pub-item/sound-pub-item.component.ngfactory";
import * as i3 from "../../pub-items/sound-pub-item/sound-pub-item.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../auth/auth.service";
import * as i6 from "@angular/common";
import * as i7 from "./sound-pub-list.component";
var styles_SoundPubListComponent = [i0.styles];
var RenderType_SoundPubListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SoundPubListComponent, data: {} });
export { RenderType_SoundPubListComponent as RenderType_SoundPubListComponent };
function View_SoundPubListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "padding: 1%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-sound-pub-item", [], null, null, null, i2.View_SoundPubItemComponent_0, i2.RenderType_SoundPubItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.SoundPubItemComponent, [i4.MatDialog, i5.AuthService], { soundPubElement: [0, "soundPubElement"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SoundPubListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: flex; flex-flow: row wrap; justify-content: center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SoundPubListComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredSoundPubs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SoundPubListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sound-pub-list", [], null, null, null, View_SoundPubListComponent_0, RenderType_SoundPubListComponent)), i1.ɵdid(1, 114688, null, 0, i7.SoundPubListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SoundPubListComponentNgFactory = i1.ɵccf("app-sound-pub-list", i7.SoundPubListComponent, View_SoundPubListComponent_Host_0, { soundPubs: "soundPubs", filteredSoundPubs: "filteredSoundPubs" }, {}, []);
export { SoundPubListComponentNgFactory as SoundPubListComponentNgFactory };
