<div *ngIf="!showLoading">
  <h4>Kategorije publikacija</h4>
  <ng-container *ngFor="let prop of pubCategories">
    <mat-checkbox
      [checked]="prop.selected"
      (change)="checkedChanged($event, prop.name, pubCategories)"
    >
      {{ prop.name }}
    </mat-checkbox>
    <br />
  </ng-container>
  <br />
  <mat-divider></mat-divider>
  <br />
  <ng-container *ngFor="let prop of intersectedColumns">
    <mat-checkbox
      [checked]="prop.selected"
      (change)="checkedChanged($event, prop.name, intersectedColumns)"
    >
      {{ prop.name }}
    </mat-checkbox>
    <br />
  </ng-container>
  <ng-container *ngFor="let prop of pubCategories[1].columns">
    <mat-checkbox
      [checked]="prop.selected"
      (change)="checkedChanged($event, prop.name, pubCategories[1].columns)"
    >
      {{ prop.name }}
    </mat-checkbox>
    <br />
  </ng-container>
  <ng-container *ngFor="let prop of pubCategories[3].columns">
    <mat-checkbox
      [checked]="prop.selected"
      (change)="checkedChanged($event, prop.name, pubCategories[3].columns)"
    >
      {{ prop.name }}
    </mat-checkbox>
    <br />
  </ng-container>
</div>

<div *ngIf="showLoading">
  <mat-spinner style="margin-left: 30%"></mat-spinner>
  <p style="margin-left: 20%">Kreiranje dokumenata...</p>
</div>
<div style="display: flex; gap: 5%">
  <button
    mat-stroked-button
    style="margin-left: 30%"
    (click)="sendDataForPrint()"
    *ngIf="!showLoading"
  >
    <mat-icon>print</mat-icon>
  </button>
</div>
